import PaymentHistoryActionsCreator from "actions/paymentHistoryActionsCreator";
import { useDispatch, useSelector } from "react-redux";
import { getRecentPayments, getUpcomingPayment } from "selectors";
import { IPaymentRecord } from "types/Api/Payment/paymentRecord";

export interface IUsePaymentHistory {
    loadRecentPayments: (count: number, clientId: string, realm: string) => Promise<void>;
    getRecentPayments: () => IPaymentRecord[];
    loadUpcomingPayment: (clientId: string, realm: string) => Promise<void>;
    getUpcomingPayment: () => IPaymentRecord;
}

export default (): IUsePaymentHistory => {
    const dispatch = useDispatch();
    const getRecentPaymentsAction = PaymentHistoryActionsCreator.getRecentPayments;
    const getUpcomingPaymentAction = PaymentHistoryActionsCreator.getUpcomingPayment;
    const recentPayments = useSelector(getRecentPayments);
    const upcomingPayment = useSelector(getUpcomingPayment);

    const loadRecentPayments = (count: number, clientId: string, realm: string) =>
        new Promise<void>((resolve, reject) =>
            dispatch(getRecentPaymentsAction(count, clientId, realm, resolve, reject))
        );

    const loadUpcomingPayment = (clientId: string, realm: string) =>
        new Promise<void>((resolve, reject) =>
            dispatch(getUpcomingPaymentAction(clientId, realm, resolve, reject))
        );

    return {
        loadRecentPayments,
        getRecentPayments: () => recentPayments,
        loadUpcomingPayment,
        getUpcomingPayment: () => upcomingPayment
    };
};
