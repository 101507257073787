import React, { useState, useEffect, useMemo } from "react";
import { ValidationView } from "components/ezpay/validate/validation-view";
import { Route, Routes } from "react-router-dom";
import { ForgeThemeProvider } from "kubra-ux-forge";
import routing from "routing";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { AnalyticsProvider, createAnalyticsInstance, useKubraTracking } from "kubra-ui-lib-mfe";
import { googleAnalyticsCode } from "config";
import { CsrDirectLanding } from "components/ezpay/validate/csr-direct-landing";
import { SessionProvider } from "components/session-provider";
import { PublicRoute } from "components/public-route";
import { ProtectedRoute } from "components/protected-route";
import { UnsubscribeAlerts } from "components/ezpay/common/unsubscribe-alerts";
import { PaySlip } from "components/ezpay/retail-cash/pay-slip";
import { RetailerLocation } from "components/ezpay/retail-cash/retailer-location";
import { BalanceFrame } from "components/ezpay/secure/balance/balance-frame";
import { Payment } from "components/ezpay/secure/payment";
import { PaymentReceipt } from "components/ezpay/secure/common/payment-receipt";
import { RecentPayments } from "components/ezpay/secure/common/recent-payments";
import { Menu } from "components/ezpay/common/menu";
import { LinkExpired } from "components/ezpay/common/link-expired";
import { DirectLanding } from "components/ezpay/validate/direct-landing";
import { PageNotFound } from "components/ezpay/common/404-page-not-found";

export const App = (): JSX.Element => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [fingerPrint, setFingerPrint] = useState('');

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {

    const setFp = async () => {
      const fp = await FingerprintJS.load();
      const { visitorId } = await fp.get();
      setFingerPrint(visitorId);
    };

    setFp();

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;

  const analyticsInstance = useMemo(
    () => createAnalyticsInstance("EZ-PAY", googleAnalyticsCode), []
  );

  const { Track } = useKubraTracking({
    commonTrackingData: { env: process.env.NODE_ENV, mfe: "EZ-PAY" },
    analytics: analyticsInstance
  });

  return (
    // <IntlProvider locale={navigator.language} messages={messages[language]}>
    <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/ezpay_bg_public.jpg'}) ` }} className="ez-pay-background">
      <AnalyticsProvider analytics={analyticsInstance}>
        <ForgeThemeProvider>
          <Track>
            <Routes>
              <Route element={<SessionProvider />}>
                <Route
                  path={routing.payslip.index()}
                  element={<PublicRoute element={<PaySlip />} />}
                />
                <Route
                  path={routing.retailerLocation.index()}
                  element={<RetailerLocation />}
                />
                <Route
                  path={routing.csrDirectLanding.index() + "/*"}
                  element={<CsrDirectLanding fingerprint={fingerPrint} />}
                />
                <Route
                  path={routing.directLanding.index() + "/*"}
                  element={<DirectLanding fingerprint={fingerPrint} />}
                />
                <Route element={<Menu isMobile={isMobile} />}>
                  <Route
                    index
                    element={
                      <PublicRoute navToPath={routing.balance.index()}
                        element={<ValidationView isMobile={isMobile} fingerprint={fingerPrint} />}
                      />
                    }
                  />
                  <Route
                    index
                    path={routing.terms.index()}
                    element={
                      <PublicRoute navToPath={routing.balance.index()}
                        element={<ValidationView isMobile={isMobile} fingerprint={fingerPrint} />}
                      />
                    }
                  />
                  <Route
                    index
                    path={routing.privacy.index()}
                    element={
                      <PublicRoute navToPath={routing.balance.index()}
                        element={<ValidationView isMobile={isMobile} fingerprint={fingerPrint} />}
                      />
                    }
                  />
                  <Route
                    path={routing.payment.index() + "/*"}
                    element={
                      <PublicRoute
                        element={<Payment fingerprint={fingerPrint} />}
                      />
                    }
                  />
                  <Route
                    path={routing.balance.index()}
                    element={
                      <ProtectedRoute
                        element={<BalanceFrame isMobile={isMobile} />}
                      />
                    }
                  />
                  <Route
                    path={routing.paymentReceipt.index()}
                    element={
                      <ProtectedRoute
                        element={<PaymentReceipt />}
                      />
                    }
                  />
                  <Route
                    path={routing.unsubscribeAlerts.index() + "/*"}
                    element={
                      <PublicRoute
                        element={<UnsubscribeAlerts />}
                      />
                    }
                  />
                  <Route
                    path={routing.recentPayments.index()}
                    element={
                      <ProtectedRoute
                        element={<RecentPayments isMobile={isMobile} />}
                      />
                    }
                  />
                  <Route path={routing.linkExpired.index()}
                    element={<LinkExpired></LinkExpired>}
                  />
                </Route>
              </Route>
              {/* 404 page */}
              <Route
                path={routing.notFound.index()}
                element={
                  <PageNotFound />
                }
              />
              <Route
                path="/*"
                element={
                  <PageNotFound />
                }
              />
            </Routes>
          </Track>
        </ForgeThemeProvider>
      </AnalyticsProvider>
    </div>
  );
};