import {
  GET_RECENT_PAYMENTS_REQUEST,
  GET_RECENT_PAYMENTS_SUCCESS,
  GET_RECENT_PAYMENTS_FAILURE,
  GET_UPCOMING_PAYMENT_SUCCESS,
  GET_UPCOMING_PAYMENT_REQUEST,
  GET_UPCOMING_PAYMENT_FAILURE,
} from "actions/paymentHistoryActions";
import { DONE, EMPTY, ERROR, IN_PROGRESS } from "constants/Status";
import _ from "lodash";
import { IRecentPaymentsGetResult, IUpcomingPaymentGetResult } from "types/Api/Payment/paymentRecord";

export const recentPaymentsReducer = (
  state = { status: EMPTY, recentPayments: [] },
  action: any
): IRecentPaymentsGetResult => {
  const newState = reduceRecentPaymentsRequest(state, action);
  return newState;
};

const reduceRecentPaymentsRequest = (state: any, action: any): IRecentPaymentsGetResult => {
  switch (action.type) {
    case GET_RECENT_PAYMENTS_SUCCESS:
      return {
        ...state,
        status: DONE,
        recentPayments: _.isEmpty(action.response) ? [] : action.response
      };
    case GET_RECENT_PAYMENTS_REQUEST:
      return {
        ...state,
        status: IN_PROGRESS,
      };
    case GET_RECENT_PAYMENTS_FAILURE:
      return {
        status: ERROR,
        recentPayments: []
      };
    default:
      return state;
  }
};

export const upcomingPaymentReducer = (
  state = { status: EMPTY, upcomingPayment: undefined },
  action: any
): IUpcomingPaymentGetResult => {
  const newState = reduceUpcomingPaymentRequest(state, action);
  return newState;
};

const reduceUpcomingPaymentRequest = (state: any, action: any): IUpcomingPaymentGetResult => {
  switch (action.type) {
    case GET_UPCOMING_PAYMENT_SUCCESS:
      return {
        ...state,
        status: DONE,
        upcomingPayment: action.response
      };
    case GET_UPCOMING_PAYMENT_REQUEST:
      return {
        ...state,
        status: IN_PROGRESS,
      };
    case GET_UPCOMING_PAYMENT_FAILURE:
      return {
        ...state,
        status: ERROR,
        upcomingPayment: undefined
      };
    default:
      return state;
  }
};

export default {
  recentPaymentsReducer,
  upcomingPaymentReducer
}