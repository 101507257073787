import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export function useDateFormatter() {
  const { i18n } = useTranslation();

  const shortDateFormatter = useCallback(
    (date: Date | string, locale?: string) => {
      let dateToFormat: Date;

      if (
        !date ||
        (typeof date === "string" && isNaN(Date.parse(date)))
      ) {
        return "";
      }

      if (date instanceof Date) {
        dateToFormat = date;
      } else {
        const dateTokens = (date as string).split("/");
        const month = +dateTokens[0] - 1;
        const day = +dateTokens[1];
        const year = +dateTokens[2] + 2000;

        dateToFormat = new Date(year, month, day);
      }

      const formattedDate = dateToFormat.toLocaleDateString(
        locale ?? i18n.language,
        {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        }
      );

      return formattedDate;
    },
    [i18n.language]
  );

  return {
    shortFormat: shortDateFormatter,
  };
}
