/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import {
  accountInitialState,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_REQUEST,
  GET_ACCOUNT_FAILURE,
  SET_ACCOUNT_NUMBER,
  RESET_INITIAL_STATE
} from "actions/accountActions";
import { DONE, EMPTY, ERROR, IN_PROGRESS } from "constants/Status";
import { IAccountGetResult } from "types/account";

export const accountReducer = (
  state = { status: EMPTY, ...accountInitialState },
  action: any
): IAccountGetResult => {
  const newState = reduceAccountRequest(state, action);
  return newState;
};

const reduceAccountRequest = (state: any, action: any): IAccountGetResult => {
  switch (action.type) {
    case SET_ACCOUNT_NUMBER:
      return {
        ...state,
        accountNumber: action.accountNumber
      };
    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        status: DONE,
        ...action.response.data
      };
    case GET_ACCOUNT_REQUEST:
      return {
        ...state,
        status: IN_PROGRESS,
      };
    case GET_ACCOUNT_FAILURE:
      return {
        status: ERROR,
      };
    case RESET_INITIAL_STATE:
      return {
        ...accountInitialState,
        status: EMPTY
      }
    default:
      return state;
  }
};

export default {
  accountReducer,
}