import { CALL_API } from "../middleware/api";
import iamApi from "../api/IamApi";
import {
    GET_WALLET_FAILURE,
    GET_WALLET_REQUEST,
    GET_WALLET_SUCCESS,
} from './walletActions';
import { IActionsCreator } from "../types/action";

export const WalletActionsCreator = {
    getWalletItems: (
        clientId: string,
        successHandler?: any,
        failureHandler?: any
    ): IActionsCreator => ({
        [CALL_API]: {
            types: [GET_WALLET_REQUEST, GET_WALLET_SUCCESS, GET_WALLET_FAILURE],
            request: () => iamApi.ezpay.getWalletItems(clientId),
            successHandler,
            failureHandler,
        },
    }),
};

export default WalletActionsCreator;