import { CALL_API } from "../middleware/api";
import iamApi from "../api/IamApi";

import {
    GET_BILLER_FAILURE,
    GET_BILLER_REQUEST,
    GET_BILLER_SUCCESS
} from './billerActions';
import { IActionsCreator } from "../types/action";
export const BillerActionsCreator = {
    getBiller: (
        subDomain: string,
        successHandler?: any,
        failureHandler?: any
    ): IActionsCreator => ({
        [CALL_API]: {
            types: [GET_BILLER_REQUEST, GET_BILLER_SUCCESS, GET_BILLER_FAILURE],
            request: () => iamApi.ezpay.getConfiguration(subDomain),
            successHandler,
            failureHandler,
        },
    })
};

export default BillerActionsCreator;