import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export function useCurrencyFormatter(currency = "USD") {
  const { i18n } = useTranslation();
  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat(i18n.language, {
        style: "currency",
        currency,
        currencyDisplay: "narrowSymbol",
        minimumFractionDigits: 2,
      }),
    [i18n.language, currency]
  );
  return currencyFormatter;
}
