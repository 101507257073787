import React, { useCallback, useRef, useMemo, useState } from "react";
import { MenuItem, PopoverList, SelectRow, TextButton } from "kubra-ux-forge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faMoneyBill } from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBank } from "@fortawesome/pro-solid-svg-icons";
import { MethodComponentsOptions } from "../../balance/balance-frame";
import { ACTION } from "actions/paymentIntentActions";
import { getPaymentIntent } from "selectors/paymentIntent";
import { useDispatch, useSelector } from "react-redux";
import { getBiller } from "selectors";
import { useOutletContext } from "react-router";
import { ISession } from "types/session";
import FundingOption, {
  isPaymentMethodAllowed,
  BankAccount,
  PayPal,
  GooglePay,
  ApplePay,
  Card,
  getCardNetworkByShortCode,
  translateBankAccountType,
} from "constants/FundingOption";
import { useTranslation } from "react-i18next";
import { PAYMENT_METHOD_MODAL_NAMESPACE, BALANCE_NAMESPACE } from "constants/i18n-namespaces";
import { DigitalPaymentData } from "types/digitalPaymentData";
import { IPaymentOptionsProperties } from "types/biller";
import { ErrorBoundary } from "react-error-boundary";
import { ApplePayButtonWrapper } from "components/ezpay/common/apple-pay-button-wrapper";
import useWallet from "hooks/useWallet";
import { CardType } from "kubra-ux-forge/dist/foundation/CreditCardLogos/CreditCardLogo";
import { GooglePayButtonWrapper } from "components/ezpay/common/google-pay-button-wrapper";
import { PaymentButtonErrorFallback } from "components/ezpay/common/payment-button-error-fallback";
import PayPalButtonWrapper from "components/ezpay/common/pay-pal-button-wrapper/pay-pal-button-wrapper";
import noFundingSourceImage from "assets/no-funding-source-available.svg";
import _ from "lodash";
import { phoneFormat } from "utilities/helper";
import { PaymentIntentPrepareRequest } from "types/Api/Payment/paymentIntentPrepareRequest";

export interface IPaymentMethodModalProps {
  flowCallback: (arg0: MethodComponentsOptions) => any;
  closeModalCallBack: () => void;
  reviewDigitalPayment: (paymentData: DigitalPaymentData) => void;
  showManagePaymentMethodsModal: () => void;
  verifyPaymentAmount:() => { isValid: boolean };
  onPrepareIntentError: (err: any, prepareRequest: PaymentIntentPrepareRequest) => void;
}

export const PaymentMethodModal = (props: IPaymentMethodModalProps) => {
  const { t } = useTranslation([PAYMENT_METHOD_MODAL_NAMESPACE, BALANCE_NAMESPACE]);
  const {
    flowCallback,
    closeModalCallBack,
    reviewDigitalPayment,
    verifyPaymentAmount,
    onPrepareIntentError,
    showManagePaymentMethodsModal,
  } = props;
  const dispatch = useDispatch();
  const { account } = useOutletContext<ISession>();
  const paymentIntentState = useSelector(getPaymentIntent);
  const biller = useSelector(getBiller);
  const { getWalletItems } = useWallet();
  const walletItems = getWalletItems();
  const bankAccounts = walletItems.filter((_) => _.fundingOption === BankAccount);
  const cards = walletItems.filter(
    (_) => _.fundingOption === Card
  );

  const [showBankPopoverList, setShowBankPopoverList] = useState(false);
  const [showCardPopoverList, setShowCardPopoverList] = useState(false);

  const refBankSelectRow = useRef<HTMLDivElement>(null);
  const refCardSelectRow = useRef<HTMLDivElement>(null);

  const getBankAccountOptions = () => {
    const options = bankAccounts.map((bankAccount) => (
      <MenuItem
        onClick={() =>
          handleWalletAccountChange(
            bankAccount.fundingSourceId,
            bankAccount.presentableMOPCode
          )
        }
        key={bankAccount.fundingSourceId}
        data-cy={`option-${bankAccount.presentableMOPCode} ${bankAccount.maskedNumber}`}
      >
        {`${translateBankAccountType(bankAccount.presentableMOPCode, t)} ${bankAccount.maskedNumber}`}
      </MenuItem>
    ));
    options.push(
      <MenuItem
        className="wallet-option new"
        onClick={() =>
          handleWalletAccountChange("", FundingOption.BankAccount)
        }
        key={""}
        data-cy="option-new-bank-account"
      >
        {t("use-new-bank-account")}
      </MenuItem>
    );
    return options;
  };

  const getCardOptions = () => {
    const options = cards.map((card) => (
      <MenuItem
        onClick={() =>
          handleWalletAccountChange(card.fundingSourceId, card.fundingOption)
        }
        key={card.fundingSourceId}
        creditCardType={getCardNetworkByShortCode(card.presentableMOPCode).toLowerCase() as CardType}
        data-cy={`option-${card.presentableMOPCode} ${card.maskedNumber}`}
      >
        {`${getCardNetworkByShortCode(card.presentableMOPCode)} ${card.maskedNumber}`}
      </MenuItem>
    ));
    options.push(
      <MenuItem
        className="wallet-option new"
        onClick={() => handleWalletAccountChange("", FundingOption.Card)}
        key={""}
        data-cy="option-new-card"
      >
        {t("use-new-card")}
      </MenuItem>
    );
    return options;
  };

  const bankIcon = faBank as IconProp;
  const cardIcon = faCreditCard as IconProp;
  const cashIcon = faMoneyBill as IconProp;

  const PayPalErrorFallback = useCallback(
    ({ resetErrorBoundary }) => (
      <PaymentButtonErrorFallback
        resetErrorBoundary={resetErrorBoundary}
        displayErrorMessage={`${t("error-loading-paypal-button")}`} 
        error={`${t("error-loading-paypal-button")}`}      />
    ),
    [t]
  );

  const payPalWrapper = useMemo(() => {
    return (
      <ErrorBoundary FallbackComponent={PayPalErrorFallback}>
        <PayPalButtonWrapper
          currency="USD"
          amount={paymentIntentState.paymentAmount}
          paymentIntentId={paymentIntentState.intentId}
          clientId={biller.clientId}
          reviewDigitalPayment={reviewDigitalPayment}
          config={account.paypalConfiguration}
          verifyPaymentAmount={verifyPaymentAmount}
          onPrepareIntentError={onPrepareIntentError}
        ></PayPalButtonWrapper>
      </ErrorBoundary>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentIntentState.paymentAmount, biller.clientId, paymentIntentState.intentId]);

  const handleWalletAccountChange = (
    fundingSourceId: string,
    fundingSourceType: string
  ) => {
    if (fundingSourceId === "") {
      if (fundingSourceType === FundingOption.BankAccount) {
        flowCallback("BankAccountModal");
      } else if (fundingSourceType === FundingOption.Card) {
        flowCallback("CreditDebitModal");
      }
    } else {
      addWalletToPaymentIntent(fundingSourceId);
      closeModalCallBack();
    }
  };

  const addWalletToPaymentIntent = (id: string) => {
    const selectedWalletItem = walletItems.find(
      (_) => _.fundingSourceId === id
    );
    dispatch({ type: ACTION.ADD_CREDIT_DEBIT_CARD, payload: null });
    dispatch({ type: ACTION.ADD_BANK_ACCOUNT, payload: null });
    dispatch({ type: ACTION.ADD_WALLET, payload: selectedWalletItem });
    dispatch({
      type: ACTION.ADD_PAYMENT_METHOD,
      payload: FundingOption.Wallet,
    });
  };

  //Get FSTypes from account
  const fsOptions = account.fundingOptions;
  const paymentSettings = biller?.properties?.find(
    (element) => element.name === "PaymentOptions"
  )?.properties as IPaymentOptionsProperties;

  const contactUs: any = biller?.properties?.find((element: any) => {
    return element.name === "InstanceConfiguration";
  });

// No funding source available
  if (fsOptions.length === 0) {
    return (<div data-testid = "no-funding-source-available-div" className="payment-method-frame">
      <img
        data-testid="no-funding-source-image"
        src={noFundingSourceImage}
        alt="no funding source available"
      ></img>
      <div className="no-funding-source-available">
        {t("no-funding-source-available", {
          biller: biller.billerName,
          phone: phoneFormat(contactUs.properties.phone)
        })}

      </div>
    </div>
    )
  }
  return (
    <div className="payment-method-frame">
      {isPaymentMethodAllowed(BankAccount, fsOptions) && (
        <div className="payment-method-button-container">
          <div ref={refBankSelectRow}>
            <SelectRow
              icon={<FontAwesomeIcon icon={bankIcon} />}
              variant={bankAccounts.length === 0 ? "static" : "dropdown"}
              onClick={() => {
                if (bankAccounts.length == 0) {
                  flowCallback("BankAccountModal");
                } else {
                  setShowBankPopoverList(!showBankPopoverList);
                }
              }}
              label={`${t("bank")}`}
            />
          </div>
          <PopoverList
            style= {{ zIndex: '9999' }}
            anchorEl={refBankSelectRow.current}
            animationVariant="drawer"
            positionVariant="left"
            open={showBankPopoverList}
            popoverMatchAnchorElWidth
          >
            {getBankAccountOptions()}
          </PopoverList>
        </div>
      )}
      {(isPaymentMethodAllowed(Card, fsOptions)) && (
          <div className="payment-method-button-container">
            <div ref={refCardSelectRow}>
              <SelectRow
                icon={<FontAwesomeIcon icon={cardIcon} />}
                variant={cards.length === 0 ? "static" : "dropdown"}
                onClick={() => {
                  if (cards.length === 0) {
                    flowCallback("CreditDebitModal");
                  } else {
                    setShowCardPopoverList(!showCardPopoverList);
                  }
                }}
                label={`${t("card")}`}
              />
            </div>
            <PopoverList 
              style= {{ zIndex: '9999' }}
              anchorEl={refCardSelectRow.current}
              animationVariant="drawer"
              positionVariant="left"
              open={showCardPopoverList}
              popoverMatchAnchorElWidth
            >
              {getCardOptions()}
            </PopoverList>
          </div>
        )}
      {paymentSettings?.allowCashPayment === "true" && (
        <div className="payment-method-button-container">
          <SelectRow
            icon={<FontAwesomeIcon icon={cashIcon} />}
            variant="static"
            onClick={() => flowCallback("CashModal")}
            label={`${t("cash")}`}
          />
        </div>
      )}

      {bankAccounts.length !== 0 || cards.length !== 0 ? (
        <TextButton
          onClick={() => {
            closeModalCallBack();
            showManagePaymentMethodsModal();
          }}
        >
          {t("manage-payment-methods-button")}
        </TextButton>
      ) : null}

      {(isPaymentMethodAllowed(ApplePay, fsOptions) && isPaymentMethodAllowed(Card, fsOptions))&& (
        <ApplePayButtonWrapper
          country="US"
          currencyCode="USD"
          clientId={biller.clientId}
          paymentIntentId={paymentIntentState.intentId}
          amount={paymentIntentState.paymentAmount}
          reviewDigitalPayment={reviewDigitalPayment}
          verifyPaymentAmount={verifyPaymentAmount}
          onPrepareIntentError={onPrepareIntentError}
        ></ApplePayButtonWrapper>
      )}

      {(isPaymentMethodAllowed(GooglePay, fsOptions) && isPaymentMethodAllowed(Card, fsOptions)) && (
        <GooglePayButtonWrapper
          country="US"
          currencyCode="USD"
          clientId={biller.clientId}
          paymentIntentId={paymentIntentState.intentId}
          amount={paymentIntentState.paymentAmount}
          reviewDigitalPayment={reviewDigitalPayment}
          verifyPaymentAmount={verifyPaymentAmount}
          onPrepareIntentError={onPrepareIntentError}
          config={account.googlePayConfiguration}
          realm={account.realm}
        ></GooglePayButtonWrapper>
      )}

      {isPaymentMethodAllowed(PayPal, fsOptions) && payPalWrapper}
    </div>
  );
};
