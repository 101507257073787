/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { paymentSettingsInitialState, GET_PAYMENTSETTINGS_SUCCESS, GET_PAYMENTSETTINGS_REQUEST, GET_PAYMENTSETTINGS_FAILURE } from "actions/paymentSettingsActions";
import { DONE, EMPTY, ERROR, IN_PROGRESS } from "constants/Status";
import { IPaymentSettingsGetResult } from "types/paymentSettings";

export const paymentSettingsReducer = (
    state = { status: EMPTY, ...paymentSettingsInitialState},
    action: any
): IPaymentSettingsGetResult => {
    const newState = reducePaymentSettingsRequest(state, action);
    return newState;
};

const reducePaymentSettingsRequest = (state: any, action: any): IPaymentSettingsGetResult => {
    switch(action.type) {
        case GET_PAYMENTSETTINGS_SUCCESS:
        return {
            ...state,
            status: DONE,
            paymentSetting: action.response,
        };
    case GET_PAYMENTSETTINGS_REQUEST:
        return {
            status: IN_PROGRESS,
        };
        case GET_PAYMENTSETTINGS_FAILURE:
            return {
                status: ERROR,
            };
            default:
                return state;
    }
    
};