import { CALL_API } from "../middleware/api";
import iamApi from "../api/IamApi";
import { IActionsCreator } from "../types/action";
import {
  GET_RECENT_PAYMENTS_FAILURE,
  GET_RECENT_PAYMENTS_REQUEST,
  GET_RECENT_PAYMENTS_SUCCESS,
  GET_UPCOMING_PAYMENT_FAILURE,
  GET_UPCOMING_PAYMENT_REQUEST,
  GET_UPCOMING_PAYMENT_SUCCESS,
} from "./paymentHistoryActions";

export const PaymentHistoryActionsCreator = {
  getRecentPayments: (
    count: number,
    clientId: string,
    realm: string,
    successHandler?: any,
    failureHandler?: any
  ): IActionsCreator => ({
    [CALL_API]: {
      types: [
        GET_RECENT_PAYMENTS_REQUEST,
        GET_RECENT_PAYMENTS_SUCCESS,
        GET_RECENT_PAYMENTS_FAILURE,
      ],
      request: () =>
        iamApi.ezpay.getRecentPayments(count, clientId, realm),
      successHandler,
      failureHandler,
    },
  }),
  getUpcomingPayment: (
    clientId: string,
    realm: string,
    successHandler?: any,
    failureHandler?: any
  ): IActionsCreator => ({
    [CALL_API]: {
      types: [
        GET_UPCOMING_PAYMENT_REQUEST,
        GET_UPCOMING_PAYMENT_SUCCESS,
        GET_UPCOMING_PAYMENT_FAILURE,
      ],
      request: () => iamApi.ezpay.getUpcomingPayment(clientId, realm),
      successHandler,
      failureHandler,
    },
  }),
};

export default PaymentHistoryActionsCreator;
