import { BILLER_LOGO_NAMESPACE } from "constants/i18n-namespaces";
import React from "react";
import { useTranslation } from "react-i18next";

export interface IBillerLogoProps {
  billerUrl: string;
  billerLogo: string;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const BillerLogo = (props: IBillerLogoProps) => {
  const { billerUrl, billerLogo } = props;
  const { t } = useTranslation();

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  }

  return (
    <div
      className="biller-logo-container"
      title={`${t("biller-logo", { ns: BILLER_LOGO_NAMESPACE })}`}
    >
      <button onClick={() => openInNewTab(billerUrl)} className="biller-logo-link" data-testid="logo-button">
        <img
          src={billerLogo}
          alt={`${t("logo-click-action", { ns: BILLER_LOGO_NAMESPACE })}`}
          className="biller-logo"
          data-cy="biller-logo"
        />
      </button>
    </div>
  );
};
