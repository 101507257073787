import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import {
  enCommon,
  enMenu,
  enLanguageModal,
  enAboutModal,
  enBillerLogo,
  enValidate,
  enBillModal,
  enHelpModal,
  enPrivacyPolicyModal,
  enTermsConditionsModal,
  enBalanceFrame,
  enContactTocFooter,
  enDonation,
  enBankAccountModal,
  enCreditDebitModal,
  enCashModal,
  enBarcodeView,
  enEmailView,
  enSmsView,
  enRetailerView,
  enPaymentAmountModal,
  enPaymentMethodModal,
  enReviewChargesModal,
  enReceiptInfoModal,
  enPaymentReceipt,
  enPayslipView,
  enManagePaymentMethodsModal,
  enPaymentDateModal,
  enUnsubscribeAlerts,
  enProcessedPayemntReceipt,
  enLinkExpired,
  enRecentPayments,
  enSessionTimeout
} from "./en-US";
import {
  esCommon,
  esMenu,
  esLanguageModal,
  esAboutModal,
  esBillerLogo,
  esValidate,
  esBillModal,
  esHelpModal,
  esPrivacyPolicyModal,
  esTermsConditionsModal,
  esBalanceFrame,
  esContactTocFooter,
  esDonation,
  esBankAccountModal,
  esCreditDebitModal,
  esCashModal,
  esBarcodeView,
  esEmailView,
  esSmsView,
  esRetailerView,
  esPaymentAmountModal,
  esPaymentMethodModal,
  esReviewChargesModal,
  esReceiptInfoModal,
  esPaymentReceipt,
  esPayslipView,
  esManagePaymentMethodsModal,
  esPaymentDateModal,
  esUnsubscribeAlerts,
  esProcessedPayemntReceipt,
  esLinkExpired,
  esRecentPayments,
  esSessionTimeout
} from "./es-US";
import {
  frCommon,
  frMenu,
  frLanguageModal,
  frAboutModal,
  frBillerLogo,
  frValidate,
  frBillModal,
  frHelpModal,
  frPrivacyPolicyModal,
  frTermsConditionsModal,
  frBalanceFrame,
  frContactTocFooter,
  frDonation,
  frBankAccountModal,
  frCreditDebitModal,
  frCashModal,
  frBarcodeView,
  frEmailView,
  frSmsView,
  frRetailerView,
  frPaymentAmountModal,
  frPaymentMethodModal,
  frReviewChargesModal,
  frReceiptInfoModal,
  frPaymentReceipt,
  frPayslipView,
  frManagePaymentMethodsModal,
  frPaymentDateModal,
  frUnsubscribeAlerts,
  frProcessedPayemntReceipt,
  frLinkExpired,
  frRecentPayments,
  frSessionTimeout
} from "./fr-CA";
import {
  COMMON_NAMESPACE,
  MENU_NAMESPACE,
  LANGUAGE_MODAL_NAMESPACE,
  ABOUT_MODAL_NAMESPACE,
  BILLER_LOGO_NAMESPACE,
  VALIDATE_NAMESPACE,
  BILL_MODAL_NAMESPACE,
  HELP_MODAL_NAMESPACE,
  PRIVACY_MODAL_NAMESPACE,
  TERMS_MODAL_NAMESPACE,
  BALANCE_NAMESPACE,
  CONTACT_TOC_FOOTER,
  DONATION,
  BANK_ACCCOUNT_MODAL,
  CREDIT_DEBIT_MODAL,
  CASH_MODAL,
  BARCODE_VIEW,
  EMAIL_VIEW,
  SMS_VIEW,
  RETAILER_VIEW,
  PAYMENT_AMOUNT_MODAL_NAMESPACE,
  PAYMENT_METHOD_MODAL_NAMESPACE,
  REVIEW_CHARGES_MODAL_NAMESPACE,
  RECEIPT_INFO_MODAL_NAMESPACE,
  PAYMENT_RECEIPT_NAMESPACE,
  PAYSLIP_NAMESPACE,
  MANAGE_PAYMENT_METHODS_MODAL,
  PAYMENT_DATE_MODAL,
  UNSUBSCRIBE_ALERTS,
  PROCESSED_PAYMENT_RECEIPT_NAMESPACE,
  LINK_EXPIRED_NAMESPACE,
  RECENT_PAYMENTS_NAMESPACE,
  SESSION_TIMEOUT
} from "../constants/i18n-namespaces";
import {
  PLATFORM_NAMESPACE,
  platformNamespaces,
  initResources,
  ENGLISH,
  FRENCH,
  SPANISH,
  // SupportedLanguages,
} from "kubra-labels";

const localResources /*: SupportedLanguages*/ = {
  [ENGLISH]: {
    [COMMON_NAMESPACE]: enCommon,
    [MENU_NAMESPACE]: enMenu,
    [LANGUAGE_MODAL_NAMESPACE]: enLanguageModal,
    [ABOUT_MODAL_NAMESPACE]: enAboutModal,
    [BILLER_LOGO_NAMESPACE]: enBillerLogo,
    [VALIDATE_NAMESPACE]: enValidate,
    [BILL_MODAL_NAMESPACE]: enBillModal,
    [HELP_MODAL_NAMESPACE]: enHelpModal,
    [PRIVACY_MODAL_NAMESPACE]: enPrivacyPolicyModal,
    [TERMS_MODAL_NAMESPACE]: enTermsConditionsModal,
    [BALANCE_NAMESPACE]: enBalanceFrame,
    [CONTACT_TOC_FOOTER]: enContactTocFooter,
    [DONATION]: enDonation,
    [BANK_ACCCOUNT_MODAL]: enBankAccountModal,
    [CREDIT_DEBIT_MODAL]: enCreditDebitModal,
    [CASH_MODAL]: enCashModal,
    [BARCODE_VIEW]: enBarcodeView,
    [EMAIL_VIEW]: enEmailView,
    [SMS_VIEW]: enSmsView,
    [RETAILER_VIEW]: enRetailerView,
    [PAYMENT_AMOUNT_MODAL_NAMESPACE]: enPaymentAmountModal,
    [PAYMENT_METHOD_MODAL_NAMESPACE]: enPaymentMethodModal,
    [REVIEW_CHARGES_MODAL_NAMESPACE]: enReviewChargesModal,
    [RECEIPT_INFO_MODAL_NAMESPACE]: enReceiptInfoModal,
    [PAYMENT_RECEIPT_NAMESPACE]: enPaymentReceipt,
    [PAYSLIP_NAMESPACE]: enPayslipView,
    [MANAGE_PAYMENT_METHODS_MODAL]: enManagePaymentMethodsModal,
    [PAYMENT_DATE_MODAL]: enPaymentDateModal,
    [UNSUBSCRIBE_ALERTS]: enUnsubscribeAlerts,
    [PROCESSED_PAYMENT_RECEIPT_NAMESPACE]: enProcessedPayemntReceipt,
    [LINK_EXPIRED_NAMESPACE]: enLinkExpired,
    [RECENT_PAYMENTS_NAMESPACE]: enRecentPayments,
    [SESSION_TIMEOUT]: enSessionTimeout
  },
  [SPANISH]: {
    [COMMON_NAMESPACE]: esCommon,
    [MENU_NAMESPACE]: esMenu,
    [LANGUAGE_MODAL_NAMESPACE]: esLanguageModal,
    [ABOUT_MODAL_NAMESPACE]: esAboutModal,
    [BILLER_LOGO_NAMESPACE]: esBillerLogo,
    [VALIDATE_NAMESPACE]: esValidate,
    [BILL_MODAL_NAMESPACE]: esBillModal,
    [HELP_MODAL_NAMESPACE]: esHelpModal,
    [PRIVACY_MODAL_NAMESPACE]: esPrivacyPolicyModal,
    [TERMS_MODAL_NAMESPACE]: esTermsConditionsModal,
    [BALANCE_NAMESPACE]: esBalanceFrame,
    [CONTACT_TOC_FOOTER]: esContactTocFooter,
    [DONATION]: esDonation,
    [BANK_ACCCOUNT_MODAL]: esBankAccountModal,
    [CREDIT_DEBIT_MODAL]: esCreditDebitModal,
    [CASH_MODAL]: esCashModal,
    [BARCODE_VIEW]: esBarcodeView,
    [EMAIL_VIEW]: esEmailView,
    [SMS_VIEW]: esSmsView,
    [RETAILER_VIEW]: esRetailerView,
    [PAYMENT_AMOUNT_MODAL_NAMESPACE]: esPaymentAmountModal,
    [PAYMENT_METHOD_MODAL_NAMESPACE]: esPaymentMethodModal,
    [REVIEW_CHARGES_MODAL_NAMESPACE]: esReviewChargesModal,
    [RECEIPT_INFO_MODAL_NAMESPACE]: esReceiptInfoModal,
    [PAYMENT_RECEIPT_NAMESPACE]: esPaymentReceipt,
    [PAYSLIP_NAMESPACE]: esPayslipView,
    [MANAGE_PAYMENT_METHODS_MODAL]: esManagePaymentMethodsModal,
    [PAYMENT_DATE_MODAL]: esPaymentDateModal,
    [UNSUBSCRIBE_ALERTS]: esUnsubscribeAlerts,
    [PROCESSED_PAYMENT_RECEIPT_NAMESPACE]: esProcessedPayemntReceipt,
    [LINK_EXPIRED_NAMESPACE]: esLinkExpired,
    [RECENT_PAYMENTS_NAMESPACE]: esRecentPayments,
    [SESSION_TIMEOUT]: esSessionTimeout
  },
  [FRENCH]: {
    [COMMON_NAMESPACE]: frCommon,
    [MENU_NAMESPACE]: frMenu,
    [LANGUAGE_MODAL_NAMESPACE]: frLanguageModal,
    [ABOUT_MODAL_NAMESPACE]: frAboutModal,
    [BILLER_LOGO_NAMESPACE]: frBillerLogo,
    [VALIDATE_NAMESPACE]: frValidate,
    [BILL_MODAL_NAMESPACE]: frBillModal,
    [HELP_MODAL_NAMESPACE]: frHelpModal,
    [PRIVACY_MODAL_NAMESPACE]: frPrivacyPolicyModal,
    [TERMS_MODAL_NAMESPACE]: frTermsConditionsModal,
    [BALANCE_NAMESPACE]: frBalanceFrame,
    [CONTACT_TOC_FOOTER]: frContactTocFooter,
    [DONATION]: frDonation,
    [BANK_ACCCOUNT_MODAL]: frBankAccountModal,
    [CREDIT_DEBIT_MODAL]: frCreditDebitModal,
    [CASH_MODAL]: frCashModal,
    [BARCODE_VIEW]: frBarcodeView,
    [EMAIL_VIEW]: frEmailView,
    [SMS_VIEW]: frSmsView,
    [RETAILER_VIEW]: frRetailerView,
    [PAYMENT_AMOUNT_MODAL_NAMESPACE]: frPaymentAmountModal,
    [PAYMENT_METHOD_MODAL_NAMESPACE]: frPaymentMethodModal,
    [REVIEW_CHARGES_MODAL_NAMESPACE]: frReviewChargesModal,
    [RECEIPT_INFO_MODAL_NAMESPACE]: frReceiptInfoModal,
    [PAYMENT_RECEIPT_NAMESPACE]: frPaymentReceipt,
    [PAYSLIP_NAMESPACE]: frPayslipView,
    [MANAGE_PAYMENT_METHODS_MODAL]: frManagePaymentMethodsModal,
    [PAYMENT_DATE_MODAL]: frPaymentDateModal,
    [UNSUBSCRIBE_ALERTS]: frUnsubscribeAlerts,
    [PROCESSED_PAYMENT_RECEIPT_NAMESPACE]: frProcessedPayemntReceipt,
    [LINK_EXPIRED_NAMESPACE]: frLinkExpired,
    [RECENT_PAYMENTS_NAMESPACE]: frRecentPayments,
    [SESSION_TIMEOUT]: frSessionTimeout
  },
};

export const resources = initResources(localResources);

const namespaces = [
  ...platformNamespaces,
  COMMON_NAMESPACE,
  MENU_NAMESPACE,
  LANGUAGE_MODAL_NAMESPACE,
  ABOUT_MODAL_NAMESPACE,
  BILLER_LOGO_NAMESPACE,
  VALIDATE_NAMESPACE,
  BILL_MODAL_NAMESPACE,
  HELP_MODAL_NAMESPACE,
  PRIVACY_MODAL_NAMESPACE,
  TERMS_MODAL_NAMESPACE,
  BALANCE_NAMESPACE,
  CONTACT_TOC_FOOTER,
  DONATION,
  BANK_ACCCOUNT_MODAL,
  CREDIT_DEBIT_MODAL,
  CASH_MODAL,
  BARCODE_VIEW,
  EMAIL_VIEW,
  SMS_VIEW,
  RETAILER_VIEW,
  PAYMENT_AMOUNT_MODAL_NAMESPACE,
  PAYMENT_METHOD_MODAL_NAMESPACE,
  REVIEW_CHARGES_MODAL_NAMESPACE,
  RECEIPT_INFO_MODAL_NAMESPACE,
  PAYMENT_RECEIPT_NAMESPACE,
  PAYSLIP_NAMESPACE,
  MANAGE_PAYMENT_METHODS_MODAL,
  PAYMENT_DATE_MODAL,
  UNSUBSCRIBE_ALERTS,
  RECENT_PAYMENTS_NAMESPACE,
  SESSION_TIMEOUT
];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    detection: { order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'] },
    fallbackLng: "en-US",
    ns: namespaces,
    defaultNS: PLATFORM_NAMESPACE,
    resources,
    react: {
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
    },
  })
  .catch((error: Error) => {
    console.error(
      "Unexpected error initializing internationalization",
      error.message
    );
  });

export default i18n;
