import {
  Button,
  CardInput,
  InputField,
  TextButton,
  Toggle,
  Tooltip,
  TooltipEnums,
} from "kubra-ux-forge";
import React, { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { MethodComponentsOptions } from "../../balance/balance-frame";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ACTION } from "actions/paymentIntentActions";
import { useTranslation } from "react-i18next";
import {
  COMMON_NAMESPACE,
  CREDIT_DEBIT_MODAL,
} from "constants/i18n-namespaces";
import { PLATFORM_NAMESPACE } from "kubra-labels";
import FundingOption from "constants/FundingOption";
import { TermsModal } from "components/ezpay/common/terms-modal";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { AnalyticsEventType, useTracking } from "kubra-ui-lib-mfe";
import type { AnalyticsEvent } from "kubra-ui-lib-mfe";

export interface ICreditDebitCard {
  firstName: string;
  lastName: string;
  cardNo: string;
  cardType: string;
  cardExpiry: string;
  securityCode: string;
  zipCode: string;
  saveToWallet: boolean;
}

export const CreditDebitCardInitialState: ICreditDebitCard = {
  firstName: "",
  lastName: "",
  cardNo: "",
  cardExpiry: "",
  securityCode: "",
  zipCode: "",
  saveToWallet: false,
  cardType: "",
};

export function isExpiryCardDateValid(cardExpiry: string | null | undefined) {
  const today = moment().startOf("day").toDate();
  if (cardExpiry === null || cardExpiry === undefined || cardExpiry === "") {
    return false;
  }

  const cardExpiryTokens = cardExpiry.split("/");

  const cardExpMonthIndex = +cardExpiryTokens[0] - 1;
  const cardExpYear = 2000 + +cardExpiryTokens[1];

  if (cardExpMonthIndex < 0 || cardExpMonthIndex > 11) {
    return false;
  }

  const expDate = new Date(cardExpYear, cardExpMonthIndex, 1, 0, 0, 0, 0);
  const currDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    1,
    0,
    0,
    0,
    0
  );

  return expDate >= currDate;
}

export interface ICreditDebitModalProps {
  closeModalCallBack: () => void;
  flowCallback: (method: MethodComponentsOptions) => void;
}

export type CreditCardType = "Visa" | "Mastercard" | "Discover" | "Amex" | "Diners";

export const CreditDebitModal = ({
  closeModalCallBack,
  flowCallback,
}: ICreditDebitModalProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(CREDIT_DEBIT_MODAL);  
  const [termsModalDisplay, setTermsModalDisplay] = useState(false);
  const handleButtonCancel = () => {
    flowCallback("PaymentMethodModal");
  };  

  const { trackEvent } = useTracking<Partial<AnalyticsEvent>>();

  const formik = useFormik({
    initialValues: { ...CreditDebitCardInitialState },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required(t("first-name-required"))
        .matches(/^[a-zA-Z\s]+$/, t("account-owner-name-required")),
      lastName: Yup.string()
        .required(t("last-name-required"))
        .matches(/^[a-zA-Z\s]+$/, t("account-owner-name-required")),
      cardNo: Yup.string()
        .required(t("card-number-invalid"))
        .max(23, t("card-number-invalid"))
        .min(13, t("card-number-invalid"))
        .test("cardNo", t("card-number-invalid"), function (value) {
          if (validateCreditCard(value) !== null) {
            return true;
          } else {
            return false;
          }
        }),
      cardExpiry: Yup.string()
        .required(t("expiration-date-required"))
        .max(5, t("expiration-date-invalid"))
        .min(5, t("expiration-date-invalid"))
        .test("cardExpiry", t("expiration-date-invalid"), function (value) {
          return isExpiryCardDateValid(value);
        }),
      securityCode: Yup.string()
        .required(t("cvc-invalid"))
        .matches(/^[0-9\b]+$/, t("cvc-invalid"))
        .max(4, t("cvc-invalid"))
        .min(3, t("cvc-invalid")),
      zipCode: Yup.string()
        .required(t("zip-code-invalid"))
        .matches(/^[0-9\b]+$/, t("zip-code-invalid"))
        .max(5, t("zip-code-invalid"))
        .min(5, t("zip-code-invalid")),
      saveToWallet: Yup.bool(),

      cardType: Yup.string(),
    }),

    onSubmit: (values: ICreditDebitCard, actions: any) => {
      actions.setSubmitting(true);

      const cardType = validateCreditCard(values.cardNo);

      if (cardType === null) {
        setFieldError("cardNo", t("card-number-invalid"));
        setSubmitting(false);
        return;
      }

      if (!isExpiryCardDateValid(values.cardExpiry)) {
        setFieldError("cardExpiry", t("expiration-date-invalid"));
        setSubmitting(false);
        return;
      }

      if (cardType === "Amex" && values.securityCode.length !== 4) {
        setFieldError("securityCode", t("cvc-invalid"));
        setSubmitting(false);
        return;
      }
      if (cardType !== "Amex" && values.securityCode.length !== 3) {
        setFieldError("securityCode", t("cvc-invalid"));
        setSubmitting(false);
        return;
      }
      values.cardType = cardType;
      dispatch({ type: ACTION.ADD_BANK_ACCOUNT, payload: null });
      dispatch({ type: ACTION.ADD_WALLET, payload: null });
      dispatch({ type: ACTION.ADD_CREDIT_DEBIT_CARD, payload: values });
      dispatch({
        type: ACTION.ADD_PAYMENT_METHOD,
        payload: FundingOption.Card,
      });
      actions.resetForm();
      setSubmitting(false);
      trackEvent({
        type: AnalyticsEventType.customEvent,
        event: "card_created"
      });
      closeModalCallBack();
    },
  });
  const {
    values,
    touched,
    errors,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    handleBlur,
    handleSubmit,
    setFieldError,
  } = formik;
  const handleCvcChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b]+$/;
    if (
      event.currentTarget.value === "" ||
      regex.test(event.currentTarget.value)
    ) {
      await setFieldValue("securityCode", event.currentTarget.value);
    }
  };

  const handleCardZipChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b]+$/;
    if (
      event.currentTarget.value === "" ||
      regex.test(event.currentTarget.value)
    ) {
      await setFieldValue("zipCode", event.currentTarget.value);
    }
  };

  const handleCardExpiryChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const inputDate = event.currentTarget.value;
    let formattedInput = inputDate.replace(/\D/g, "").slice(0, 4);
    if (formattedInput.length > 2) {
      const month = formattedInput.slice(0, 2);
      const year = formattedInput.slice(2);
      if (parseInt(month) > 12) {
        formattedInput = "12";
      } else {
        formattedInput = `${month}/${year}`;
      }
    }

    await setFieldValue("cardExpiry", formattedInput);
  };

  const handleToggleChange = () => {
    console.log("handleToggleChange");
    setFieldValue("saveToWallet", !values.saveToWallet);
  };

  const handleCardInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    void (async () => {
      const newcardNumber = event.currentTarget.value;
      await setFieldValue("cardNo", newcardNumber);
    })();
  };

  const handleFirstNameChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const regex = /^[a-zA-Z\s]+$/;
    if (event.currentTarget.value === "" || regex.test(event.currentTarget.value)) {
      await setFieldValue("firstName", event.currentTarget.value);
    }
  }

  const handleLastNameChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const regex = /^[a-zA-Z\s]+$/;
    if (event.currentTarget.value === "" || regex.test(event.currentTarget.value)) {
      await setFieldValue("lastName", event.currentTarget.value);
    }
  }

  const validateCreditCard = (cardNumber: string): CreditCardType | null => {
    const cleanedNumber = cardNumber.replace(/\D/g, "");

    const regexVisa = /^((4\d{12})|(4\d{15}))$/;
    const regexMastercard =
      /^((5[1-5]\d{2})|(2((22[1-9])|(2[3-9]\d)|([3-6]\d{2})|(7[01]\d)|(720))))\d{12}$/;
    const regexDiscover =
      /^((6011\d{12})|(65\d{14})|(62\d{14})|(63\d{14})|(64\d{14})|(30\d{14})|(35\d{14})|(36\d{12})|(38\d{14})|(39\d{14}))$/;
    const regexAmex = /^(3[47]\d{13})$/;

    const regexDiners = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;

    // Check if the cleaned number matches the expected format for each card type
    if (regexVisa.test(cleanedNumber) && mod10Check(cleanedNumber)) {
      return "Visa";
    } else if (
      regexMastercard.test(cleanedNumber) &&
      mod10Check(cleanedNumber)
    ) {
      return "Mastercard";
    } else if (regexDiners.test(cleanedNumber) && mod10Check(cleanedNumber)) {
      return "Diners";
    }else if (regexDiscover.test(cleanedNumber) && mod10Check(cleanedNumber)) {
      return "Discover";
    } else if (regexAmex.test(cleanedNumber) && mod10Check(cleanedNumber)) {
      return "Amex";
    }

    return null; // Invalid card number
  };

  const mod10Check = (cardNumber: string): boolean => {
    let sum = 0;
    let isDouble = false;

    // Iterate over each digit from right to left
    for (let i = cardNumber.length - 1; i >= 0; i--) {
      let digit = parseInt(cardNumber.charAt(i), 10);

      if (isDouble) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }

      sum += digit;
      isDouble = !isDouble;
    }
    return sum % 10 === 0;
  };
  
 
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <div className="credit-debit-container">
        <div className="credit-debit-subtitle">
          {t("credit-debit-subtitle")}
        </div>
        <div className="first-name-last-name-container">
          <div className="first-name-last-name-textbox">
            <InputField
              name="firstName"
              id="firstName"
              errorText={touched.firstName ? errors.firstName : undefined}
              hasError={touched.firstName && errors.firstName != undefined}
              value={values.firstName}
              onChange={handleFirstNameChange}
              onBlur={handleBlur}
              helperText=""
              label={`${t("first-name-label")}`}
              dataCy="input-first-name"
            />
          </div>
          <div className="first-name-last-name-textbox">
            <InputField
              name="lastName"
              id="lastName"
              errorText={touched.lastName ? errors.lastName : undefined}
              hasError={touched.lastName && errors.lastName != undefined}
              value={values.lastName}
              onChange={handleLastNameChange}
              onBlur={handleBlur}
              helperText=""
              label={`${t("last-name-label")}`}
              dataCy="input-last-name"
            />
          </div>
        </div>
        <div className="credit-debit-number-container">
          <CardInput
            name="cardNo"
            defaultValue={values.cardNo}
            errorText={touched.cardNo ? errors.cardNo : undefined}
            hasError={touched.cardNo && errors.cardNo != undefined}
            helperText=""
            label={`${t("card-number-label")}`}
            onChange={handleCardInputChange}
            onBlur={handleBlur}
            /* @ts-ignore */
            dataCy="input-card"
          />
        </div>

        <div className="expiry-cvc-zip-container">
          <div className="expiry-cvc-zip-textbox">
          <div className="label-container">
          <label htmlFor="cardExpiry" className="label-text">
                {t("expiration-date-label")}
              </label>
          </div>
            <InputField
              name="cardExpiry"
              errorText={touched.cardExpiry ? errors.cardExpiry : undefined}
              hasError={touched.cardExpiry && errors.cardExpiry != undefined}
              helperText=""
              label={""}
              placeholder={`${t("expiration-date-placeholder")}`}
              maxLength="5"
              value={values.cardExpiry}
              onChange={handleCardExpiryChange}
              onBlur={handleBlur}
              dataCy="input-card-expiry"
            />
          </div>

          <div className="expiry-cvc-zip-textbox">
            <div className="label-container">
              <label htmlFor="securityCode" className="label-text">
                {t("security-code-label")}
              </label>
              <Tooltip
                content={`${t("security-code-tooltip")}`}
                /* @ts-ignore */
                dataCy="tooltip-security-code"
              />
            </div>
            <InputField
              id="securityCode"
              name="securityCode"
              hasShowHideButton
              errorText={touched.securityCode ? errors.securityCode : undefined}
              hasError={
                touched.securityCode && errors.securityCode != undefined
              }
              helperText=""
              label=""
              maxLength="4"
              value={values.securityCode}
              onChange={handleCvcChange}
              onBlur={handleBlur}
              dataCy="input-security-code"
            />
          </div>
          <div className="expiry-cvc-zip-textbox">
            <div className="label-container">
              <label htmlFor="zipCode" className="label-text">
                {t("zip-code-label")}
              </label>
              <Tooltip
                content={`${t("zip-code-tooltip")}`}
                direction={
                  isMobile ? TooltipEnums.TopRight : TooltipEnums.TopLeft
                }
                /* @ts-ignore */
                dataCy="tooltip-zip-code"
              />
            </div>
            <InputField
              id="zipCode"
              name="zipCode"
              errorText={touched.zipCode ? errors.zipCode : undefined}
              helperText=""
              label=""
              hasError={touched.zipCode && errors.zipCode != undefined}
              maxLength="5"
              value={values.zipCode}
              onChange={handleCardZipChange}
              onBlur={handleBlur}
              dataCy="input-zip-code"
            />
          </div>
        </div>
        <div className="save-card-inputs-container">
          <div
            className={
              values.saveToWallet
                ? "save-card-container-enabled"
                : "save-card-container"
            }
          >
            <div className="save-card-title-toggle-container">
              <span className="save-card-title">
                {t("save-card-toggle-label")}
              </span>
              <Toggle
                label=""
                onChange={handleToggleChange}
                isCheckedDefault={values.saveToWallet}
                /* @ts-ignore */
                dataCy="toggle-save-to-wallet"
                data-testid="toggle-save-to-wallet"
              />
            </div>
            <div
              className={
                values.saveToWallet
                  ? "save-card-line-separator-enabled"
                  : "save-card-line-separator"
              }
            ></div>
            <div className="save-card-text">
              {t("save-card-credential", {
                lastFourDigits:
                  values.cardNo && !errors.cardNo
                    ? values.cardNo.substring(values.cardNo.length - 4)
                    : "_____",
              })}
              <button
                className="link-button"
                onClick={(e) =>{ e.preventDefault(); setTermsModalDisplay(true)}}
                data-cy="button-terms-link"
              >
                {t("terms-of-use")}
              </button>
            </div>
          </div>
        </div>
        <div className="buttons-row">
          <div className="button-item">
            <TextButton
              type="button"
              onClick={handleButtonCancel}
              dataCy="button-cancel"
            >
              {t("cancelLabel", { ns: PLATFORM_NAMESPACE })}
            </TextButton>
          </div>
          <div className="button-item">
            <Button
              disabled={isSubmitting}
              type="submit"
              onClick={() => null}
              dataCy="button-done"
            >
              {t("done", { ns: COMMON_NAMESPACE })}
            </Button>
          </div>
        </div>
        <TermsModal
          isOpen={termsModalDisplay}
          isMobile={isMobile}
          onClose={() => setTermsModalDisplay(false)}
          header="termsOfUse"
        />
      </div>
    </form>
  );
};
