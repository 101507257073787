import React from "react";
import { SkeletonItem } from "../skeleton-item/skeleton-item";

interface DashboardSkeletonProps {
    visible: boolean;
}

export const DashboardSkeleton = ({ visible }: DashboardSkeletonProps) => {
    if (visible) {
        return (<div data-testid = "dashboard-skeleton">
            <div className="ez-pay-balance-frame">

                <div className="ez-pay-balance-top">

                    <div className="validate-header">
                        <div className="biller-logo-container">
                            <SkeletonItem width="316px" height="60px" />
                        </div>
                    </div>
                    <div className="ez-pay-balance-row">

                        <div className="ez-pay-balance-content-center-amount-due-row">
                            <SkeletonItem width="261px" height="32px" />
                        </div>
                        <div className="ez-pay-balance-content-center-small">
                            <SkeletonItem width="261px" height="64px" />
                        </div>

                        <div className="ez-pay-balance-content-center-small">
                            <SkeletonItem width="389px" height="24px" />
                        </div>
                        <div className="ez-pay-balance-content-center-small">
                            <SkeletonItem width="261px" height="32px" />
                        </div>
                    </div>
                    <div className="ez-pay-balance-divider"></div>

                    <form>
                        <div className="ez-pay-balance-no-frame">
                            <SkeletonItem width="187px" height="31px" />
                        </div>

                        <div className="ez-pay-balance-button-container">
                            <SkeletonItem width="100%" height="64px" />
                        </div>

                        <div className="ez-pay-balance-button-container">
                            <SkeletonItem width="100%" height="64px" />
                        </div>

                        <div className="ez-pay-balance-button-container">
                            <SkeletonItem width="100%" height="64px" />
                        </div>

                        <div className="ez-pay-balance-container-scheduled">
                            <SkeletonItem width="100%" height="31px" />
                        </div>

                        <div className="ez-pay-charity-container">
                            <SkeletonItem width="244px" height="31px" />
                        </div>

                        <div className="ez-pay-balance-button-container">
                            <SkeletonItem width="100%" height="34px" />
                        </div>

                        <div className="ez-pay-balance-row-noframe button">
                            <SkeletonItem width="100%" height="48px" />
                        </div>

                        <div className="ez-pay-balance-content-center-small">
                            <SkeletonItem width="210px" height="20px" />
                        </div>
                        <br />
                        <div className="ez-pay-balance-content-center-small">
                            <SkeletonItem width="210px" height="20px" />
                        </div>
                    </form>
                </div>
            </div>
        </div>

        );
    }
    return null;
};
