import { LoadingButton, TextButton, Banner } from "kubra-ux-forge";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPaymentIntent,
  getPaymentMethodLabel,
} from "selectors/paymentIntent";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getBiller } from "selectors";
import {
  postInitiateIntent,
  postProcessIntent,
} from "api/IamApi/entities/ezpay";
import { ACTION } from "actions/paymentIntentActions";
import { useCurrencyFormatter, useDateFormatter } from "hooks";
import FundingOption from "constants/FundingOption";
import { useTranslation } from "react-i18next";
import { BALANCE_NAMESPACE, REVIEW_CHARGES_MODAL_NAMESPACE } from "constants/i18n-namespaces";
import { PLATFORM_NAMESPACE } from "kubra-labels";
import { TermsModal } from "components/ezpay/common/terms-modal";
import { ISession } from "types/session";
import google_pay_mark from "assets/google-pay-mark.png";
import apple_pay_mark from "assets/apple-pay-mark.svg";
import paypal_mark from "assets/paypal-mark.png";
import routing from "routing";
import { PaymentIntentProcessRequest } from "types/Api/Payment/paymentIntentProcessRequest";
import { isSuccessfulPayment } from "constants/paymentStatus";
import { AnalyticsEventType, useTracking } from "kubra-ui-lib-mfe";
import type { AnalyticsEvent } from "kubra-ui-lib-mfe";
import { mapUiLanguageToSessionLanguage } from "utilities/helper";
import i18n from "i18n/I18nApp";
import { setAppLoading } from "actions/appStateActions";

export interface IReviewChargesModalProps {
  closeModalCallBack: () => void;
  showPaymentMethodModalCallback?: () => void;
  isMobile: boolean;
  setDisplayPaymentIntentBack: (value: boolean) => void;
  setReceiptInfoHeader: (value: string) => void;
}

export const ReviewChargesModal = ({
  closeModalCallBack,
  showPaymentMethodModalCallback,
  isMobile,
  setDisplayPaymentIntentBack,
  setReceiptInfoHeader,

}: IReviewChargesModalProps) => {
  const { t } = useTranslation([REVIEW_CHARGES_MODAL_NAMESPACE, BALANCE_NAMESPACE]);
  const { account, login, applePaySession } = useOutletContext<ISession>(); 
  

  // load paymentIntent from paymentIntent state
  const paymentIntentState = useSelector(getPaymentIntent);
  const bankAccount = paymentIntentState.bankAccount;
  const creditDebitCard = paymentIntentState.creditDebitCard;
  const wallet = paymentIntentState.wallet;
  const paymentMethod = paymentIntentState.paymentMethod;
  const paymentMethodLabel = useSelector((state) => getPaymentMethodLabel(state, t));
  const digitalWallet = paymentIntentState.digitalWallet;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const biller = useSelector(getBiller);
  const [errorMessage, setErrorMessage] = useState("");
  const [termsModalDisplay, setTermsModalDisplay] = useState(false);
  const [paymentState, setPaymentState] = useState("text");
  const [paymentFailed, setPaymentFailed] = useState(false);
  const currencyFormatter = useCurrencyFormatter();
  const dateFormatter = useDateFormatter();
  const { trackEvent } = useTracking<Partial<AnalyticsEvent>>();
  //This value used to maintain payment amount display after payment failure / intent re-initialization
  const [paymentAmount] = useState<number>(paymentIntentState.paymentAmount);

  const selectedCharities = paymentIntentState.charities?.filter(
    (char) => char.selected
  );
  let charitiesTotal = 0.0;
  if (
    paymentIntentState.charities != null &&
    paymentIntentState.charities.length > 0
  ) {
    charitiesTotal = paymentIntentState.charities
      .filter((ch) => ch.selected)
      .reduce((charitiesTotal, charity) => charitiesTotal + charity.amount, 0);
  }

  const totalPayment =
    paymentAmount +
    charitiesTotal +
    paymentIntentState.serviceFee;

  const reinitiatePaymentIntent = () => {
    if (biller !== null && biller !== undefined) {
      postInitiateIntent(
        biller.clientId,
        account.realm,
        account.fingerprint,
        navigator.userAgent
      )
        .then((res) => {
          login({ ...account, paymentIntentId: res.data.intentId });
        })
        .catch((err) => {
          console.log("Error occurred initiating new intent: ", err);
        });
    }
  };

  const getProcessRequest = (): PaymentIntentProcessRequest | null => {
    const mobileAlertEnabled = paymentIntentState.receiptInfo?.alertEnabled
      ? paymentIntentState.receiptInfo.emailAlert
      : false;
    const smsAlertEnabled = paymentIntentState.receiptInfo?.alertEnabled
      ? paymentIntentState.receiptInfo.mobileAlert &&
      paymentIntentState.receiptInfo.mobileNumber.length > 0
      : false;
    if (paymentMethod === FundingOption.Card && creditDebitCard) {
      return {
        email: paymentIntentState.receiptInfo?.email ?? "",
        phoneNumber: paymentIntentState.receiptInfo?.mobileNumber.replace(
          /[\(\)\s\-]/g,
          ""
        ),
        alerts: {
          emailAlert: mobileAlertEnabled,
          smsAlert: smsAlertEnabled,
        },
        fundingOption: paymentMethod,
        card: {
          card: creditDebitCard.cardNo.replace(/\s/g, ""),
          cvc: creditDebitCard.securityCode,
          cardType: creditDebitCard.cardType,
          expiry: creditDebitCard.cardExpiry,
          zip: creditDebitCard.zipCode,
          holderName:
            creditDebitCard.firstName + " " + creditDebitCard.lastName,
          saveToWallet: creditDebitCard.saveToWallet,
        },
      };
    } else if (paymentMethod === FundingOption.BankAccount && bankAccount) {
      return {
        email: paymentIntentState.receiptInfo?.email ?? "",
        phoneNumber: paymentIntentState.receiptInfo?.mobileNumber.replace(
          /[\(\)\s\-]/g,
          ""
        ),
        alerts: {
          emailAlert: mobileAlertEnabled,
          smsAlert: smsAlertEnabled,
        },
        fundingOption: paymentMethod,
        bank: {
          bankNumber: bankAccount.bankAccountNumber,
          routingNumber: bankAccount.routingNumber,
          accountType: bankAccount.accountType,
          holderName: bankAccount.accountOwnerName,
          saveToWallet: bankAccount.saveToWallet,
        },
      };
    } else if (paymentMethod === FundingOption.Wallet && wallet) {
      return {
        email: paymentIntentState.receiptInfo?.email ?? "",
        phoneNumber: paymentIntentState.receiptInfo?.mobileNumber.replace(
          /[\(\)\s\-]/g,
          ""
        ),
        alerts: {
          emailAlert: mobileAlertEnabled,
          smsAlert: smsAlertEnabled,
        },
        fundingOption: paymentMethod,
        wallet: {
          fundingSourceId: wallet.fundingSourceId,
          type: wallet.fundingOption,
        },
      };
    } else if (digitalWallet) {
      return {
        email: paymentIntentState.receiptInfo?.email ?? "",
        phoneNumber: paymentIntentState.receiptInfo?.mobileNumber.replace(
          /[\(\)\s\-]/g,
          ""
        ),
        alerts: {
          emailAlert: mobileAlertEnabled,
          smsAlert: smsAlertEnabled,
        },
        fundingOption: paymentMethod,
        digitalWallet: {
          paymentToken: digitalWallet.paymentToken,
          cardType: digitalWallet.cardType,
        },
      };
    }
    return null;
  };

  const trackPaymentEvent = (fundingSourceType: string, wallet: string, success: string) => {
    trackEvent({
      type: AnalyticsEventType.customEvent,
      event: `payment_${success}_${fundingSourceType.toLowerCase()}_${wallet}`
    });
  }
  const handleProcessPaymentIntentFailed = (processRequest: PaymentIntentProcessRequest) => {
    dispatch(setAppLoading(false));
    const defaultErrorMessage = t("generic-process-error-msg");
    setPaymentState("text");
    setPaymentFailed(true);
    setErrorMessage(defaultErrorMessage);
    trackPaymentEvent(processRequest.fundingOption, processRequest.wallet ? "wallet": "adhoc", "failure");
    applePaySession?.completePayment(
      window.ApplePaySession.STATUS_FAILURE
    );
    
  };

  const handlePayClick = () => {
    dispatch(setAppLoading(true, t("payment-processing-loader-title"), t("payment-processing-loader-subText")));
    setErrorMessage("");
    setPaymentState("loading");
    setDisplayPaymentIntentBack(false);
    setReceiptInfoHeader("");
    // prepare process request
    const processRequest = getProcessRequest();
    if (processRequest === null) {
      return;
    }

    // call api to make the payment.
    postProcessIntent(
      biller != null ? biller.clientId : "",
      paymentIntentState.intentId,
      processRequest,
      mapUiLanguageToSessionLanguage(i18n.language),
    )
      .then((res) => {
        // process api response
        // show payment receipt page
        // update status and confirmation code in paymentIntentState
        // eslint-disable-next-line max-len
        if (res.status == 200 && isSuccessfulPayment(res.data.status)) {
          setPaymentState("success");
          dispatch(setAppLoading(false));
          dispatch({
            type: ACTION.ADD_PAYMENT_STATUS,
            payload: res.data.status,
          });
          dispatch({
            type: ACTION.ADD_PAYMENT_CONFIRMATION_NUMBER,
            payload: res.data.paymentId,
          });
          setErrorMessage("");
          trackPaymentEvent(processRequest.fundingOption, processRequest.wallet ? "wallet": "adhoc", "success");
          navigate(routing.paymentReceipt.index());
          // mark ApplePay session complete
          applePaySession?.completePayment(
            window.ApplePaySession.STATUS_SUCCESS
          );
          reinitiatePaymentIntent();
        } else {
          handleProcessPaymentIntentFailed(processRequest);
          reinitiatePaymentIntent();
        }
      })
      .catch(() => {
        handleProcessPaymentIntentFailed(processRequest);
        reinitiatePaymentIntent();
      });
  };

  return (
    <div className="review-charges-body">
      {errorMessage.length > 0 && (
        <div className="error-banner">
          <Banner
            hyperlinkText={`${t("error-banner-link")}`}
            messageBody={errorMessage}
            messageLabel={`${t("error-banner-label")}`}
            variant="error"
            withIcon
            withHyperlink
            hyperlink={showPaymentMethodModalCallback}
          />
        </div>
      )}

      <div className="review-charges-container">
        <div className="review-charges-title-container">
          <div className="review-charges-title">{t("review-charges")}</div>
          {paymentIntentState.receiptInfo?.mobileNumber !== "" && (
            <div className="review-charges-sub-title">
              <div
                dangerouslySetInnerHTML={{
                  __html: t("receipt-delivery-info-email-phone", {
                    email: paymentIntentState.receiptInfo?.email,
                    mobileNumber: paymentIntentState.receiptInfo?.mobileNumber,
                    interpolation: {
                      escapeValue: false,
                    },
                  }),
                }}
              />
            </div>
          )}
          {paymentIntentState.receiptInfo?.mobileNumber === "" && (
            <div className="review-charges-sub-title">
              <div
                dangerouslySetInnerHTML={{
                  __html: t("receipt-delivery-info-email-only", {
                    email: paymentIntentState.receiptInfo?.email,
                  }),
                }}
              />
            </div>
          )}
        </div>

        <div className="review-charges-details-container">
          <div className="review-charges-details-row">
            <div className="review-charges-left-accno-label-container">
              <div className="review-charges-left-label">
                {t("account-number")}
              </div>
            </div>
            <div
              className="review-charges-right-label"
              data-cy="account-number"
            >
              {paymentIntentState.accountNumber}
            </div>
          </div>

          <div className="review-charges-details-row">
            <div className="review-charges-left-label">{t("payment-date")}</div>
            <div className="review-charges-right-label" data-cy="payment-date">
              {dateFormatter.shortFormat(paymentIntentState.paymentDate)}
            </div>
          </div>

          <div className="review-charges-details-row">
            <div className="review-charges-left-label">{t("due-date")}</div>
            <div className="review-charges-right-label" data-cy="due-date">
              {dateFormatter.shortFormat(paymentIntentState.dueDate)}
            </div>
          </div>

          <div className="review-charges-details-row">
            <div className="review-charges-left-label">
              {t("payment-method")}
            </div>
            <div
              className="review-charges-right-label"
              data-cy="payment-method"
            >
              {paymentMethod == FundingOption.ApplePay && (
                <img
                  src={apple_pay_mark}
                  alt="Apple Pay"
                  style={{ height: "30px" }}
                ></img>
              )}
              {paymentMethod == FundingOption.GooglePay && (
                <img
                  src={google_pay_mark}
                  alt="Google Pay"
                  style={{ height: "30px" }}
                ></img>
              )}
              {paymentMethod == FundingOption.PayPal && (
                <img src={paypal_mark} alt="PayPal"></img>
              )}
              {paymentMethodLabel}
            </div>
          </div>

          <div className="review-charges-details-row">
            <div className="review-charges-left-label">
              {t("payment-amount")}
            </div>
            <div
              className="review-charges-right-label"
              data-cy="payment-amount"
            >
              {
                currencyFormatter.format(paymentAmount)
              }
            </div>
          </div>

          <div className="review-charges-details-row">
            <div className="review-charges-left-label">{t("amount-due")}</div>
            <div className="review-charges-right-label" data-cy="amount-due">
              {currencyFormatter.format(paymentIntentState.amountDue)}
            </div>
          </div>

          {selectedCharities != undefined &&
            selectedCharities.length > 0 &&
            selectedCharities.map(
              (charityState) =>
                charityState.selected &&
                charityState.amount > 0 && (
                  <div
                    className="review-charges-details-row"
                    key={charityState.charity.code}
                  >
                    <div className="review-charges-left-label">
                      {t("donation-to", { name: charityState.charity.name })}
                    </div>
                    <div
                      className="review-charges-right-label"
                      data-cy={`donation-${charityState.charity.name.replaceAll(
                        " ",
                        "-"
                      )}`}
                    >
                      {currencyFormatter.format(charityState.amount)}
                    </div>
                  </div>
                )
            )}

          <div className="review-charges-details-row">
            <div className="review-charges-left-service-fee-label-container">
              <div className="review-charges-left-label">
                {t("service-fee")}
              </div>
            </div>
            <div className="review-charges-right-label" data-cy="service-fee">
              {currencyFormatter.format(paymentIntentState.serviceFee)}
            </div>
          </div>
        </div>
      </div>
      <div className="review-charges-payment-total-container">
        <div className="review-charges-payment-total-label ">
          {t("payment-total")}
        </div>
        <div
          className="review-charges-payment-total-value"
          data-cy="total-payment"
        >
          {currencyFormatter.format(totalPayment)}
        </div>
      </div>
      <div className="review-charges-bottom-text-container">
        <div className="review-charges-bottom-text">
          {t("charges-disclaimer")}{" "}
          <button
            className="footer-link-button"
            onClick={(e) =>{ e.preventDefault(); setTermsModalDisplay(true)} }
          >
            {t("terms-of-use")}
          </button>
          .
        </div>
      </div>
      <div className="buttons-row-container">
        <div className="button-item">
          <TextButton onClick={closeModalCallBack}>
            {t("cancelLabel", { ns: PLATFORM_NAMESPACE })}
          </TextButton>
        </div>
        <div className="button-item">
          <LoadingButton
            type="submit"
            onClick={handlePayClick}
            data-testid="pay-button"
            loadingState={paymentState}
            disabled={paymentFailed}
          >
            {t("pay-button-label", {
              amount: currencyFormatter.format(totalPayment),
            })}
          </LoadingButton>
        </div>
      </div>

      <TermsModal
        isOpen={termsModalDisplay}
        isMobile={isMobile}
        onClose={() => setTermsModalDisplay(false)}
        header="termsOfUse"
      />
    </div>
  );
};
