export const SET_APP_CONFIG = 'SET_APP_CONFIG';

export interface IAppConfig {
    tenantId: string,
}

export const setAppConfig = (config: IAppConfig): Record<string, unknown> => ({
    type: SET_APP_CONFIG,
    config: { ...config },
})
