import React from "react";
import { Trans } from "react-i18next";
import { HELP_MODAL_NAMESPACE } from "constants/i18n-namespaces";

function HowToPay() {
  return (
    <div>
      <Trans
        i18nKey="how-to-make-payment-description"
        ns={HELP_MODAL_NAMESPACE}
        components={{ newLine: <br />, list: <ul />, listItem: <li /> }}
      />
    </div>
  );
}

export default HowToPay;
