/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { retailerInitialState, GET_RETAILER_SUCCESS, GET_RETAILER_REQUEST, GET_RETAILER_FAILURE } from "actions/retailerActions";
import { DONE, EMPTY, ERROR, IN_PROGRESS } from "constants/Status";
import { IRetailerGetResult } from "types/retailer";

export const retailerReducer = (
    state = { status: EMPTY, ...retailerInitialState},
    action: any
): IRetailerGetResult => {
    const newState = reduceRetailerRequest(state, action);
    return newState;
};

const reduceRetailerRequest = (state: any, action: any): IRetailerGetResult => {
    switch(action.type) {
        case GET_RETAILER_SUCCESS:
        return {
            ...state,
            status: DONE,
            retailers: action.response,
        };
    case GET_RETAILER_REQUEST:
        return {
            status: IN_PROGRESS,
        };
        case GET_RETAILER_FAILURE:
            return {
                status: ERROR,
            };
            default:
                return state;
    }
    
};