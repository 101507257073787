import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EMPTY, IN_PROGRESS, DONE, ERROR } from "constants/Status";
import { IBiller } from "types/biller";
import { getBiller } from "selectors";
import { BillerActionsCreator } from "../../actions/billerActionsCreator";
import { getSubDomain } from "utilities/helper";

export interface IUseBiller {
    getBiller: () => IBiller;
    loadBiller: () => Promise<void>;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (): IUseBiller => {
    const dispatch = useDispatch();
    const getBillerAction = BillerActionsCreator.getBiller;
    const biller = useSelector(getBiller);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loadBillerStatus, setLoadBillerStatus] = useState(EMPTY);

    const loadBillerRequest = (subDomain: string) =>
        new Promise((resolve, reject) =>
            dispatch(getBillerAction(subDomain, resolve, reject))
        );

    const loadBiller = async () => {
        const subDomain = getSubDomain();
        setLoadBillerStatus(IN_PROGRESS);
        try {
            await loadBillerRequest(subDomain);
            setLoadBillerStatus(DONE);
        } catch (error) {
            setLoadBillerStatus(ERROR);
            throw error;
        }
    };

    return {
        loadBiller,
        getBiller: () => biller,
    };
};