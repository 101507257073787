import React from "react";
import { useBarcode } from "next-barcode";
import incommIcon from "assets/barcode-incomm-icon.png";
import { useTranslation } from "react-i18next";
import { BARCODE_VIEW } from "constants/i18n-namespaces";
import { useCurrencyFormatter } from "hooks";

export interface IBarcodeViewProps {
  barcodeText: string;
  billerName: string;
  billerLogo: string;
  accountNumber: string;
  serviceFee: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const BarcodeView = (props: IBarcodeViewProps) => {
  const { barcodeText, billerLogo, accountNumber, serviceFee } = props;
  const { t } = useTranslation(BARCODE_VIEW);
  const currencyFormatter = useCurrencyFormatter();

  // Display Servicefee text if enabled and > 0
  let serviceFeeDisplay = "";
  if (serviceFee !== undefined && serviceFee > 0.0) {
    serviceFeeDisplay = t("service-fee-label", {
      serviceFee: currencyFormatter.format(serviceFee),
    });
  }

  //Set barcode properties
  const { inputRef } =
    useBarcode({
      value: barcodeText,
      options: {
        displayValue: true,
        background: "#ffffff",
        fontSize: 22,
      },
    }) || {};

  return (
    <div
      className="barcode-view"
      id="barcodeview"
      title={`${t("barcode-view-title")}`}
    >
      <div className="cash-account-logo-container">
        <div className="cash-biller-logo-container">
          <img
            src={billerLogo}
            alt={`${t("biller-logo-alt", { billerName: props.billerName })}`}
            className="biller-logo-cash"
            data-cy="biller-logo"
          />
        </div>
        <div className="cash-account-number">
          <div className="account-display-cash" data-cy="account-number">
            <b>{t("account-number")}</b> {accountNumber}
          </div>
        </div>
      </div>

      <div className="barcode-container">
        <div className="barcode-column">
          <img src={incommIcon} alt="Incomm icon" className="incom-icon" />
          <canvas
            ref={inputRef}
            className="barcode"
            title={`${t("cash-barcode-title")}`}
            data-cy="cash-barcode"
          />
        </div>
      </div>
      <div className="content-row">
        <div className="left-text-14" data-cy="link-how-to-use-barcode">
          {t("how-to-use-barcode", { serviceFee: serviceFeeDisplay })}
        </div>
      </div>
      <div className="content-row">
        <div className="left-text-10">
          {t("barcode-disclaimer", {
            termsUrl: "vanilladirect.com/pay/terms",
            receiptUrl: "vanilladirect.com/pay/ereceipt",
            phoneNumber: "(888)-888-8888",
            interpolation: {
              escapeValue: false,
            },
          })}
        </div>
      </div>
    </div>
  );
};
