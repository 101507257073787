import { Skeleton } from "kubra-ux-forge";
import React from "react";
import { createUseStyles } from "react-jss";

interface SkeletonItemProps {
    height: string;
    width: string;
}

export const SkeletonItem = ({ height, width }: SkeletonItemProps) => {

    const useStyles = createUseStyles({
        skeletonContainer: {
            display: 'flex',
            width: width,
            height: height,
            justifyContent: 'center',
            alignItems: 'center',
            flexShrink: '0',
        }
    });

    const classes = useStyles();
    return (
        <div aria-label="skeleton-item" className={classes.skeletonContainer}>
            <Skeleton shape="rectangle" />
        </div>
    );
};
