import React from "react";
import {
  FUNDING,
  PayPalScriptProvider,
  BraintreePayPalButtons,
} from "@paypal/react-paypal-js";
import { BraintreeTokenizePayload } from "@paypal/react-paypal-js/dist/types/types/braintree/commonsTypes";
import { PaymentIntentPrepareRequest } from "types/Api/Payment/paymentIntentPrepareRequest";
import IamApi from "api/IamApi";
import FundingOption from "constants/FundingOption";
import { DigitalPaymentData } from "types/digitalPaymentData";
import { IPayPalConfig } from "types/digitalPaymentConfigurations";
import { setAppLoading } from "actions/appStateActions";
import { useDispatch } from "react-redux";

export interface IPayPalButtonWrapper {
  clientId: string;
  paymentIntentId: string;
  amount: number;
  currency: string;
  reviewDigitalPayment: (paymentData: DigitalPaymentData) => void;
  verifyPaymentAmount: () => { isValid: boolean };
  onPrepareIntentError: (err: any, prepareRequest: PaymentIntentPrepareRequest) => void;
  config: IPayPalConfig;
}

// Custom component to wrap the PayPalButtons and handle currency changes
const PayPalButtonWrapper = (props: IPayPalButtonWrapper) => {
  const {
    amount,
    currency,
    clientId,
    paymentIntentId,
    reviewDigitalPayment,
    verifyPaymentAmount,
    onPrepareIntentError,
    config,
  } = props;

  const dispatch = useDispatch();

  const payPalConfig = {
    clientId: config.clientId,
    merchantAccountId: config.merchantAccountId,
    merchantId: config.merchantId,
    braintreeToken: config.braintreeToken,
    fundingSources: [FUNDING.PAYPAL, FUNDING.VENMO],
    style: { color: "gold", layout: "vertical", height: 50 },
  };

  const buttons: React.ReactNode[] = [];

  const paypalOptions = {
    clientId: payPalConfig.clientId,
    merchantId: payPalConfig.merchantId,
    dataUserIdToken: payPalConfig.braintreeToken,
    intent: "authorize",
    components: "buttons",
    currency: "USD",
    commit: false,
    enableFunding: "venmo",
  };

  const handleButtonClick = (event: any) => {
    const isValidAmount = verifyPaymentAmount();
    console.log( "verify amount: ", isValidAmount);
    if (!isValidAmount.isValid) {
      event.preventDefault();
      dispatch(setAppLoading(false));
    }
  }
  const preparePaymentIntent = async (payload: BraintreeTokenizePayload) => {
    dispatch(setAppLoading(true));
    const prepareRequest: PaymentIntentPrepareRequest = {
      paymentAmount: amount,
      fundingOption: FundingOption.PayPal,
      digitalWallet: {
        paymentToken: payload.nonce,
      },
    };

    await IamApi.ezpay.postPrepareIntent(
      clientId,
      paymentIntentId,
      prepareRequest
    ).then((response) => {

    const paymentData: DigitalPaymentData = {
      payer: `${payload.details.firstName} ${payload.details.lastName}`,
      email: payload.details.email,
      mobileNumber: payload.details.phone,
      paymentToken: payload.nonce,
      paymentMethod: FundingOption.PayPal,
      serviceFee: Number(response.data.feeTransactionAmount),
    };
    reviewDigitalPayment(paymentData);
    dispatch(setAppLoading(false));
  })
  .catch((err) => {
    onPrepareIntentError(err, prepareRequest);
    dispatch(setAppLoading(false));
   });
  };
  const payPalStyle = {
    color: "gold",
    layout: "vertical",
    height: 55,
  };

  const venmoStyle = {
    color: "blue",
    layout: "vertical",
    height: 55,
  };

  payPalConfig.fundingSources.forEach((fundingSource) => {
    let buttonStyle = payPalStyle;
    if (fundingSource.valueOf() === FUNDING.VENMO.valueOf()) {
      buttonStyle = venmoStyle;
    }

    buttons.push(
      <BraintreePayPalButtons
        key={fundingSource}
        /* @ts-ignore */
        style={buttonStyle}
        merchantAccountId={payPalConfig.merchantAccountId}
        forceReRender={[amount, currency, payPalConfig.style]}
        fundingSource={fundingSource}
        createOrder={function (data, actions) {
          return actions.braintree
            .createPayment({
              flow: "checkout",
              amount: amount,
              intent: "authorize",
              currency: currency,
            })
            .then((orderId) => {
              // prepare payment intent
              return orderId;
            });
        }}
        onApprove={function (data, actions) {
          return actions.braintree.tokenizePayment(data).then((payload) => {
            // process payment intent
            preparePaymentIntent(payload);
          });
        }}
        onClick={handleButtonClick}
      />
    );
  });

  return (
    <div style={{ height: "50px", width: "100%", alignSelf: "center" }}>
      <PayPalScriptProvider options={paypalOptions}>
        {buttons}
      </PayPalScriptProvider>
    </div>
  );
};

export default PayPalButtonWrapper;
