import { IBiller } from '../types/biller';

export const GET_BILLER_REQUEST = "GET_BILLER_REQUEST";
export const GET_BILLER_SUCCESS = "GET_BILLER_SUCCESS";
export const GET_BILLER_FAILURE = "GET_IBILLER_FAILURE";

export const billerInitialState: IBiller = {
  billerName: "KUBRA",
  clientId: "",
  snapshotId: "",
  instanceId: "",
  realm: "SANDBOX",
  accountPlaceholder: "000-00000-00",
  vcodePlaceholder: "A1A 1A1",
  validationFields: [
    {
      name: "AccountNumber",
      label: [
        {
            "language": "en-US",
            "value": "Account number"
        },
        {
            "language": "es-US",
            "value": "Número de cuenta"
        },
        {
            "language": "fr-CA",
            "value": "Numéro de compte"
        }
    ],
      validation: ""
    }
  ],
  properties: [
    {
      name: "PaymentOptions",
      properties: {
        allowPartialPayment: "true",
        maxPaymentAmount: "",
        minPaymentAmount: "",
        allowCashPayment: "true",
        allowOverPayment: "true",
        scheduledPaymentMaximumDays: "0",
      }
    },
    {
      name: "ImageResources",
      properties: {
        logoURL: "",
        billSampleURL: ""
      }
    },
    {
      name: "ExternalLinks",
      properties: {
        termsAndConditions: ""
      }
    },
    {
      name: "InstanceConfiguration",
      properties: {
        phone: "",
        email: "",
        street: "",
        city: "",
        state: "",
        zipCode: "",
        website: ""
      }
    }
  ]
};