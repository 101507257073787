import {
  Button,
  Checkbox,
  InputField,
  TextButton,
  Toggle,
} from "kubra-ux-forge";
import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import Modal from "react-modal";
import { useFormik } from "formik";
import Sheet from "react-modal-sheet";
import * as Yup from "yup";
import { ACTION } from "actions/paymentIntentActions";
import validator from "validator";
import { getReceiptInfo } from "selectors/paymentIntent";
import { PaymentIntentComponentsOptions } from "../../balance/balance-frame";
import { useTranslation } from "react-i18next";
import {
  COMMON_NAMESPACE,
  PRIVACY_MODAL_NAMESPACE,
  RECEIPT_INFO_MODAL_NAMESPACE,
} from "constants/i18n-namespaces";
import { PLATFORM_NAMESPACE } from "kubra-labels";
import circleInfo from "assets/icons/circleInfo.png";
import XButton from "assets/icons/x_button.svg";
import { PrivacyModal } from "components/ezpay/common/privacy-modal";
import { TermsModal } from "components/ezpay/common/terms-modal";
import { customStyles } from "constants/customStyles";
import { getBiller } from "selectors";
import { IExternalLinksProperties } from "types/biller";

export interface IReceiptInfo {
  email: string;
  mobileNumber: string;
  emailAlert: boolean;
  mobileAlert: boolean;
  alertEnabled: boolean;
}
export const ReceiptInfoInitialState: IReceiptInfo = {
  email: "",
  mobileNumber: "",
  emailAlert: false,
  mobileAlert: false,
  alertEnabled: false,
};
export interface IReceiptInfoModalProps {
  flowCallback: (arg0: PaymentIntentComponentsOptions) => any;
  closeModalCallBack: () => void;
}

export const ReceiptInfoModal = ({
  flowCallback,
  closeModalCallBack,
}: IReceiptInfoModalProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(RECEIPT_INFO_MODAL_NAMESPACE);
  const [privacyModalDisplay, setPrivacyModalDisplay] = useState(false);
  const [termsModalDisplay, setTermsModalDisplay] = useState(false);
  const finalBiller = useSelector(getBiller);
  const externalLinks = finalBiller?.properties?.find(
    (element) => element.name === "ExternalLinks"
  )?.properties as IExternalLinksProperties;

  const termsAndConditionsLink = externalLinks?.termsAndConditions;

  const handleToggleChange = (
    event: SyntheticEvent<HTMLInputElement, Event>
  ) => {
    void (async () => {
      const newToggleValue = event.currentTarget.checked;
      await setFieldValue("alertEnabled", newToggleValue);

      if (newToggleValue) {
        await setFieldValue("emailAlert", newToggleValue);
        if (errors.mobileNumber == undefined && values.mobileNumber != "") {
          await setFieldValue("mobileAlert", newToggleValue);
        } else {
          await setFieldValue("mobileAlert", false);
        }
      }
    })();
  };

  const handleMobileNumberChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const regex = /^[0-9\b()-]+$/;
    if (
      event.currentTarget.value === "" ||
      regex.test(event.currentTarget.value.replace(/[^\d]/g, ""))
    ) {
      if (event.currentTarget.value.length < 15) {
        const mobNo = phoneFormat(event.currentTarget.value);
        await setFieldValue("mobileNumber", mobNo);
      }
    }
  };

  function phoneFormat(inputPhone: string) {
    return inputPhone
      .replace(/\D+/g, "")
      .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }

  function validateEmail(value: string) {
    if (value == undefined || value == null || value === "") {
      return false;
    } else {
      return validator.isEmail(value);
    }
  }

  function validateMobileNumber(value: string | null | undefined) {
    if (value == undefined || value == null || value === "") {
      return true; // mobile number is optional
    } else {
      return validator.isMobilePhone(value, "en-US");
    }
  }

  const handleEmailAlertChange = (
    event: SyntheticEvent<HTMLInputElement, Event>
  ) => {
    void (async () => {
      const newToggleValue = event.currentTarget.checked;
      if (!newToggleValue && !values.mobileAlert) {
        await setFieldValue("alertEnabled", false);
      }
      await setFieldValue("emailAlert", newToggleValue);
    })();
  };

  const handleMobileAlertChange = (
    event: SyntheticEvent<HTMLInputElement, Event>
  ) => {
    void (async () => {
      const newToggleValue = event.currentTarget.checked;
      if (!values.emailAlert && !newToggleValue) {
        await setFieldValue("alertEnabled", false);
      }
      await setFieldValue("mobileAlert", newToggleValue);
    })();
  };

  // load receipt info from paymentIntent state if previously saved
  const receiptInfoState = useSelector(getReceiptInfo);

  const setPrivacyModalIsOpenToTrue = () => {
    setPrivacyModalDisplay(true);
  };

  const setPrivacyModalIsOpenToFalse = () => {
    setPrivacyModalDisplay(false);
  };

  const setTermsModalIsOpenToTrue = () => {
    setTermsModalDisplay(true);
  };

  const setTermsModalIsOpenToFalse = () => {
    setTermsModalDisplay(false);
  };

  const formik = useFormik({
    initialValues: { ...(receiptInfoState ?? ReceiptInfoInitialState) },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .required(t("email-required"))
        .test("email", t("email-invalid"), function (email) {
          return validateEmail(email);
        }),
      mobileNumber: Yup.string()
        .nullable()
        .optional()
        .max(14, t("mobile-number-invalid"))
        .test(
          "mobileNumber",
          t("mobile-number-invalid"),
          function (mobileNumber) {
            return validateMobileNumber(mobileNumber);
          }
        ),

      emailAlert: Yup.bool(),
      mobileAlert: Yup.bool(),
      alertEnabled: Yup.bool(),
    }),
    onSubmit: (values: IReceiptInfo, actions: any) => {
      actions.setSubmitting(true);
      dispatch({ type: ACTION.ADD_RECEIPTINFO, payload: values });
      actions.resetForm();
      setSubmitting(false);
      flowCallback("ReviewChargesModal");
    },
  });
  const {
    values,
    touched,
    errors,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="receipt-info-container">
          <div className="receipt-info-subtitle">
            {t("receipt-info-header")}
          </div>
          <div className="receipt-info-fields-container">
            <div className="receipt-info-inputs-container">
              <div className="receipt-info-textbox-container">
                <InputField
                  name="email"
                  id="email"
                  errorText={touched.email ? errors.email : undefined}
                  helperText=""
                  label={`${t("email-label")}`}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.email && errors.email != undefined}
                  dataCy="input-email"
                />
              </div>
            </div>

            <div className="receipt-info-inputs-container">
              <div className="receipt-info-textbox-container">
                <InputField
                  name="mobileNumber"
                  id="mobileNumber"
                  errorText={
                    touched.mobileNumber ? errors.mobileNumber : undefined
                  }
                  helperText={`${t("mobile-number-helper-text")}`}
                  label={`${t("mobile-number-label")}`}
                  placeholder="(xxx) xxx-xxxx"
                  value={values.mobileNumber}
                  onChange={handleMobileNumberChange}
                  onBlur={handleBlur}
                  hasError={
                    touched.mobileNumber && errors.mobileNumber != undefined
                  }
                  dataCy="input-mobile-number"
                />
              </div>
            </div>
            <div className="receipt-info-inputs-container">
              <div
                className={
                  values.alertEnabled && errors.email == undefined
                    ? "receipt-info-alert-container-enabled"
                    : "receipt-info-alert-container"
                }
              >
                <div className="receipt-info-alert-title-toggle-container">
                  <span className="receipt-info-alert-title">
                    {t("payment-due-alert-label")}
                  </span>
                  <Toggle
                    label={`${t("payment-due-alert-label")}`}
                    labelPosition="hidden"
                    onChange={handleToggleChange}
                    isCheckedDefault={
                      values.alertEnabled && errors.email == undefined
                    }
                    disabled={
                      values.email.length == 0 || errors.email != undefined
                    }
                    /* @ts-ignore */
                    dataCy="toggle-payment-due-alert"
                  />
                </div>
                <div
                  className={
                    values.alertEnabled && errors.email == undefined
                      ? "receipt-info-alert-line-separator-enabled"
                      : "receipt-info-alert-line-separator"
                  }
                ></div>
                <div className="receipt-info-alert-text">
                  {t("payment-due-alert-text")}
                </div>
                {values.alertEnabled && errors.email == undefined && (
                  <>
                    <div id="alertEnabled">
                      <div className="receipt-info-alert-line-separator-enabled"></div>
                      <br />
                      <div className="receipt-info-checkbox-container">
                        <span
                          className={
                            values.email && errors.email == undefined
                              ? "receipt-info-checkbox-label"
                              : "receipt-info-checkbox-label-disabled"
                          }
                        >
                          {t("send-alerts-to-email", {
                            email: values.email ? values.email : "email",
                          })}
                        </span>
                        <Checkbox
                          name="emailAlert"
                          label=""
                          onChange={handleEmailAlertChange}
                          disabled={!values.email || errors.email != undefined}
                          defaultChecked={values.emailAlert}
                          dataCy="checkbox-email-alert"
                        />
                      </div>
                      <br />
                      <div className="receipt-info-checkbox-container">
                        <span
                          className={
                            errors.mobileNumber == undefined &&
                            values.mobileNumber != ""
                              ? "receipt-info-checkbox-label"
                              : "receipt-info-checkbox-label-disabled"
                          }
                        >
                          {t("send-alerts-to-mobile-number", {
                            mobileNumber: values.mobileNumber
                              ? values.mobileNumber
                              : "mobile number",
                          })}
                        </span>
                        <Checkbox
                          name="mobileAlert"
                          label=""
                          onChange={handleMobileAlertChange}
                          disabled={
                            errors.mobileNumber != undefined ||
                            values.mobileNumber == ""
                          }
                          defaultChecked={
                            values.mobileAlert &&
                            !errors.mobileNumber &&
                            values.mobileNumber.length > 0
                          }
                          dataCy="checkbox-mobile-alert"
                        />
                      </div>
                    </div>
                    <div className="receipt-info-alert-line-separator-enabled"></div>
                    <div className="receipt-info-mobile-helper-text-container">
                      <img
                        src={circleInfo}
                        alt="info icon"
                        className="infoIcon"
                      />
                      <span className="receipt-info-mobile-helper-text">
                        {values.mobileNumber
                          ? t("mobile-number-alerts-filled-help")
                          : t("mobile-number-alerts-empty-help")}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="receipt-info-subtitle">
                {t("receipt-info-footer")}
                <button
                  className="footer-link-button"
                  type="button"
                  onClick={() => setPrivacyModalIsOpenToTrue()}
                >
                  {t("privacy-policy")}
                </button>
                {t(" and ")}
                <button
                  className="footer-link-button"
                  type="button"
                  onClick={() => {
                    termsAndConditionsLink
                      ? window.open(termsAndConditionsLink, "_blank")
                      : setTermsModalIsOpenToTrue();
                  }}
                >
                  {t("terms-and-conditions")}
                </button>
              </div>
            </div>

            <div className="buttons-row">
              <div className="button-item">
                <TextButton onClick={closeModalCallBack} dataCy="button-cancel">
                  {t("cancelLabel", { ns: PLATFORM_NAMESPACE })}
                </TextButton>
              </div>
              <div className="button-item">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => null}
                  dataCy="button-next"
                >
                  {t("next", { ns: COMMON_NAMESPACE })}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
      {/* privacy policy modal and sheet */}
      {isMobile && (
        <Sheet
          isOpen={privacyModalDisplay}
          onClose={() => setPrivacyModalIsOpenToFalse}
          disableDrag={true}
        >
          <Sheet.Container>
            <Sheet.Header>
              <div className="react-modal-sheet-header">
                <div className="help-modal-header-text">
                  {t("header", { ns: PRIVACY_MODAL_NAMESPACE })}
                </div>
                <button
                  className="modal-close"
                  onClick={setPrivacyModalIsOpenToFalse}
                  data-cy="modal-close-x-privacy"
                >
                  <img
                    src={XButton}
                    alt="close modal window"
                    className="modal-close-x"
                  />
                </button>
              </div>
            </Sheet.Header>
            <Sheet.Content>
              <PrivacyModal />
            </Sheet.Content>
          </Sheet.Container>
          {/* @ts-ignore */}
          <Sheet.Backdrop onClick={setPrivacyModalIsOpenToFalse} />
        </Sheet>
      )}
      {!isMobile && (
        // @ts-ignore
        <Modal
          isOpen={privacyModalDisplay}
          ariaHideApp={false}
          className="privacy-terms-modal-frame"
          shouldCloseOnOverlayClick={true}
          onRequestClose={setPrivacyModalIsOpenToFalse}
          style={customStyles}
        >
          <div className="modal-header">
            <div className="help-modal-header-text">
              {t("header", { ns: PRIVACY_MODAL_NAMESPACE })}
            </div>
            <button
              className="modal-close"
              onClick={setPrivacyModalIsOpenToFalse}
              data-cy="modal-close-x-privacy"
            >
              <img
                src={XButton}
                alt="close modal window"
                className="modal-close-x"
              />
            </button>
          </div>
          <PrivacyModal />
        </Modal>
      )}

      {/* terms and conditions modal and sheet */}
      <TermsModal
        isOpen={termsModalDisplay}
        isMobile={isMobile}
        onClose={setTermsModalIsOpenToFalse}
      />
    </>
  );
};
