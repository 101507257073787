import React, { useState } from "react";
import { Banner, Button, Radio, RadioGroup } from "kubra-ux-forge";
import { useTranslation } from "react-i18next";
import { LANGUAGE_MODAL_NAMESPACE } from "constants/i18n-namespaces";
import { ENGLISH, FRENCH, PLATFORM_NAMESPACE, SPANISH } from "kubra-labels";

export interface LanguageModalProps {
  onClose?: () => void;
}

export const LanguageModal = ({ onClose }: LanguageModalProps) => {
  const { t, i18n } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedLanguage, setSelectedLanguage] = useState(
    i18n.language || ENGLISH
  );

  const updateLanguage = () => {
    setErrorMessage("");
    i18n
      .changeLanguage(selectedLanguage)
      .then(() => {
        if (onClose) {
          onClose();
        }
      })
      .catch(() => {
        const errorMsg = t("error-changing-language", {
          ns: LANGUAGE_MODAL_NAMESPACE,
        });
        setErrorMessage(errorMsg);
      });
  };

  return (
    <div className="language-modal">
      {errorMessage && <Banner messageLabel={errorMessage} variant="error" withIcon/>}
      <RadioGroup
        groupName="language"
        label={`${t("language-radio-group-label", {
          ns: LANGUAGE_MODAL_NAMESPACE,
        })}`}
        onChange={(e) => setSelectedLanguage(e.currentTarget.value)}
        defaultValue={selectedLanguage}
        /* @ts-ignore */
        dataCy="radio-group-language"
      >
        <Radio
          key={ENGLISH}
          label={`${t("english", { ns: LANGUAGE_MODAL_NAMESPACE })}`}
          value={ENGLISH}
          dataCy="radio-english"
        />
        <Radio
          key={SPANISH}
          label={`${t("spanish", { ns: LANGUAGE_MODAL_NAMESPACE })}`}
          value={SPANISH}
          dataCy="radio-spanish"
        />
        <Radio
          key={FRENCH}
          label={`${t("french", { ns: LANGUAGE_MODAL_NAMESPACE })}`}
          value={FRENCH}
          dataCy="radio-french"
        />
      </RadioGroup>
      <Button
        onClick={updateLanguage}
        className="language-modal-save-button"
        dataCy="button-save"
      >
        {t("saveLabel", { ns: PLATFORM_NAMESPACE })}
      </Button>
    </div>
  );
};
