import styled from "styled-components";
import { TextButton } from "kubra-ux-forge";
import Sheet from "react-modal-sheet";

//define custom sheet for about us styling
export const CustomSheet = styled(Sheet)`
  .react-modal-sheet-container {
    background: linear-gradient(180deg, #c4e7ff 0%, #ffffff 57.29%);
  }
`;

export const StyledLanguageMobileButton = styled(TextButton)`
  min-width: 4rem;
  width: 6rem;
  padding-right: 2.1rem;
`;

export const StyledChangeAccountMobileButton = styled(TextButton)`
  min-width: 2rem;
  width: 3rem;
  padding-right: 0rem;
  padding-left: 2.1rem;
`;