import { IBankAccount } from "components/ezpay/secure/common/bank-account-modal";
import { ICreditDebitCard } from "components/ezpay/secure/common/credit-debit-modal";
import { IReceiptInfo } from "components/ezpay/secure/common/receipt-info-modal";
import { ICharityState } from "./charity";

export interface IPaymentIntent {
  intentId: string;
  accountNumber: string;
  bankAccount?: IBankAccount | null;
  creditDebitCard?: ICreditDebitCard | null;
  digitalWallet?: IDigitalWallet;
  charities?: Array<ICharityState> | null;
  amountDue: number;
  paymentAmount: number;
  serviceFee: number;
  dueDate: string;
  paymentDate: Date;
  receiptInfo?: IReceiptInfo | null;
  paymentStatus: string;
  paymentId: string;
  paymentMethod: string;
  wallet?: IWallet;
}

export const paymentIntentInitialState: IPaymentIntent = {
  intentId: "",
  accountNumber: "",
  bankAccount: null,
  creditDebitCard: null,
  charities: null,
  amountDue: 0,
  paymentAmount: 0,
  receiptInfo: null,
  serviceFee: 0,
  dueDate: "",
  paymentDate: new Date(),
  paymentStatus: "",
  paymentId: "",
  paymentMethod: "",
};

export interface IDigitalWallet {
  cardType?: string;
  paymentToken: string;
}

export interface IWallet {
  fundingSourceId: string;
  fundingOption: string;
  presentableMOPCode: string;
  maskedNumber: string;
  expiry: string;
  isPreferred: boolean;
}

export type PaymentIntent = IPaymentIntent;
