import React from 'react';
import pageNotFound from "assets/page_not_found.svg";

export const PageNotFound = (): JSX.Element => {

  return (
    <div className="page-not-found-frame">
      <div className="page-not-found-inner-frame">
        <div className="page-not-found-image" >
          <img
            data-testid="page-not-found-image"
            src={pageNotFound}
            alt="page-not-found"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;