
import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { BrowserRouter } from 'react-router-dom';
import { appConfig } from '../../../config';
import { rootReducer } from '../../../reducers';
import createApiMiddleware from '../../../middleware/api';
import { envs } from '../../../config/index';

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && process.env.REACT_APP_ENV !== envs.PRODUCTION ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const store = createStore(
  rootReducer,
  {},
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware,
      createApiMiddleware(),
    ),
  ),
)

const basePath = appConfig.basePath;

/**
 * Export a function that takes an App component, and wraps it with the redux store provider and a
 * router before rendering
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default (appComponent: FunctionComponent, containerId = 'root'): void => {
  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
  root.render(
    /* @ts-ignore */
    <Provider store={store}>
      {/* @ts-ignore */}
      <BrowserRouter basename={basePath}>
        {React.createElement(appComponent)}
      </BrowserRouter>
    </Provider>
  );
};
