import _ from 'lodash';
import config from './default';

const prod = _.cloneDeep(config);

prod.api = {
  ez_pay: {
    hostInstances: 'https://api.ez-pay.co/ez-pay/v1',
  },
  rcp: {
    hostInstances: 'https://api.kubra.io/rcp/v1',
  }
};



prod.googleAnalyticsCode = 'G-VJRHN9SM1R';

export default prod;
