import _ from 'lodash';
import config from './default';

const local = _.cloneDeep(config);

const authConfig = {
  ...local.auth,
  useCookies: true,
  silentCallbackPath: 'http://localhost:3000/silent-callback',
  callbackPath: 'http://localhost:3001/login/callback',
  logoutPath: 'http://localhost:3001/login/logout',
};

local.api = {
  ez_pay: {
    hostInstances: 'https://api.dev.kubra.io/ez-pay/v1',
  },
  rcp: {
    hostInstances: 'https://api.dev.kubra.io/rcp/v1',
  }
}

local.auth = authConfig;

local.googlemapsApiKey = 'AIzaSyD-3r5UjRnWMD2z0VEC_XQye510slIxxlQ';

export default local;
