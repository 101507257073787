import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessageExclamation } from "@fortawesome/pro-regular-svg-icons";
import styled from "styled-components";
import { theme } from "styled-tools";
import { useJiraIssueCollector } from "../hooks";
import { isMobile } from "react-device-detect";

export interface IFeddbackProps {
  userEmail: string;
}

const StyledFeedbackButton = styled.button`
    display: flex;
    align-items: center;
    gap: 8px;

    background-color: ${theme("forgeLib.primary.50")};
    color: ${theme("forgeLib.primary.99")};

    padding: 6px 24px;
    border: 0;
    border-radius: 8px;

    &:hover {
        background-color: ${theme("forgeLib.primary.30")};
        box-shadow: 0px 2px 12px 0px rgba(36, 36, 36, 0.33);
    }

    svg {
        width: 16px;
        height: 16px;
    }

    div {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 14px;
        line-height: 20px;
    }

    @media screen and (max-width: 479px) {
      display: block;
      padding: 10%;

    }
`;
export const Feedback = (props: IFeddbackProps) => {
  const { userEmail } = props;
  useJiraIssueCollector(userEmail);

    return (
      <StyledFeedbackButton id="feedback-button" data-cy="feedback-button" onClick={(e) => { e.preventDefault(); window.showCollectorDialog(); }}>
        <FontAwesomeIcon
          // @ts-ignore
          icon={faMessageExclamation}
          style={{ color: "#ffffff" }} />
        { !isMobile && (<div>Feedback</div>)}
      </StyledFeedbackButton>
    );
}