
import IamApi from "api/IamApi";
import { VALIDATE_NAMESPACE } from "constants/i18n-namespaces";
import { useBiller } from "hooks";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import routing from "routing";
import { ISession } from "types/session";
import { getDomain, mapUiLanguageToSessionLanguage } from "utilities/helper";

export interface IDirectLandingProps {
  fingerprint: string;
}

export const DirectLanding = (props: IDirectLandingProps) => {
  const { fingerprint } = props;
  const [searchParams] = useSearchParams();
  const { loadBiller, getBiller } = useBiller();
  const biller = getBiller();
  const { login } = useOutletContext<ISession>();
  const { i18n } = useTranslation(VALIDATE_NAMESPACE);

  const navigate = useNavigate();
  const domain = getDomain();
  const accountId = searchParams.get("AccountId");
  const hash = searchParams.get("hash");
  const instanceId = searchParams.get("InstanceId");

  if (!accountId || !instanceId) {
    // should be replace by error page once it is ready
    navigate(routing.index());
  }

  useEffect(() => {
    if (biller === null || biller === undefined) {
      loadBiller();
    } else if (biller && accountId && instanceId) {
      IamApi.ezpay.postDirectLanding(
        {
          accountId: accountId,
          hash: hash
        },
        instanceId,
        mapUiLanguageToSessionLanguage(i18n.language),
        domain,
        fingerprint,
        navigator.userAgent,
      ).then((response) => {
        const account = response.data;
        login({ ...account, fingerprint: fingerprint })
        navigate(routing.balance.index());
      }).catch((error) => {
        console.log(error);
        // should be replace by error page once it is ready
        navigate(routing.index());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [biller, accountId, instanceId]);

  return (<></>)
};

export default DirectLanding;
