import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import mainLogo from "assets/ez_pay_logo.svg";
import aboutIcon from "assets/icons/about_icon.svg";
import helpIcon from "assets/icons/question_icon.svg";
import privacyIcon from "assets/icons/shield_icon.svg";
import termsIcon from "assets/icons/checkDoc_icon.svg";
import Modal from "react-modal";
import Sheet from "react-modal-sheet";
import AboutModal from "../about-modal/about-modal";
import XButton from "assets/icons/x_button.svg";
import { PrivacyModal } from "../privacy-modal";
import { TermsModal } from "../terms-modal";
import { MenuButton, Drawer, MenuItem, TextButton} from "kubra-ux-forge";
import { Outlet, useOutletContext } from "react-router";
import { ISession } from "types/session";
import { useTranslation } from "react-i18next";
import {
  COMMON_NAMESPACE,
  LANGUAGE_MODAL_NAMESPACE,
  MENU_NAMESPACE,
  PRIVACY_MODAL_NAMESPACE,
} from "constants/i18n-namespaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/pro-solid-svg-icons";
import { ENGLISH, FRENCH, SPANISH } from "kubra-labels";
import routing from "routing";
import { customStyles } from "constants/customStyles";
import { HelpModal } from "../help-modal";
import { LanguageModal } from "../language-modal";
import {
  CustomSheet,
  StyledHeaderItemGroupDiv,
  StyledLanguageMobileButton,
  StyledChangeAccountMobileButton
} from "./styled-components";
import { Feedback } from "./feedback";
import { envs } from "config";
import { faSignOut } from "@fortawesome/pro-regular-svg-icons";
import { setAppLoading } from "actions/appStateActions";
import { useDispatch } from "react-redux";
import { PageLoader } from "components/ezpay/common/page-loader";
import { account } from "utilities/preload-state";

export const Menu = (props: { isMobile: boolean }) => {
  const session = useOutletContext<ISession>();
  const { t, i18n } = useTranslation();
  const [menuButtonOpen, setMenuButtonOpen] = useState(false);
  const [aboutModalDisplay, setAboutModalDisplay] = useState(false);
  const [helpModalDisplay, setHelpModalDisplay] = useState(false);
  const [privacyModalDisplay, setPrivacyModalDisplay] = useState(false);
  const [termsModalDisplay, setTermsModalDisplay] = useState(false);
  const [languageModalDisplay, setLanguageModalDisplay] = useState(false);
  const wasVisited = localStorage.getItem("was_visited");
  let firstVisit: boolean;
  const location = useLocation();
  const dispatch = useDispatch();  

  const handleLogoutClick = async () => {
    dispatch(setAppLoading(true));    
    session.logout();
  }

  function handleMenuClick() {
    setMenuButtonOpen(!menuButtonOpen);
  }

  const setAboutModalIsOpenToTrue = () => {
    setAboutModalDisplay(true);
    handleMenuClick();
  };

  const setAboutModalIsOpenToFalse = () => {
    setAboutModalDisplay(false);
  };

  const setHelpModalIsOpenToTrue = () => {
    setHelpModalDisplay(true);
    handleMenuClick();
  };

  const setHelpModalIsOpenToFalse = () => {
    setHelpModalDisplay(false);
  };

  const setPrivacyModalIsOpenToTrue = () => {
    setPrivacyModalDisplay(true);
    handleMenuClick();
  };

  const setPrivacyModalIsOpenToFalse = () => {
    setPrivacyModalDisplay(false);
  };

  const setTermsModalIsOpenToTrue = () => {
    setTermsModalDisplay(true);
    handleMenuClick();
  };

  const setTermsModalIsOpenToFalse = () => {
    setTermsModalDisplay(false);
  };

  const closeLanguagesModal = () => {
    setLanguageModalDisplay(false);
  };

  useEffect(() => {
    if (location.pathname.includes("terms")) {
      setTermsModalDisplay(true);
    } else if (location.pathname.includes("privacy")) {
      setPrivacyModalDisplay(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLanguageDisplayValue = (language: string) => {
    switch (language) {
      case ENGLISH:
        return t("eng", { ns: MENU_NAMESPACE });
      case SPANISH:
        return t("spa", { ns: MENU_NAMESPACE });
      case FRENCH:
        return t("fre", { ns: MENU_NAMESPACE });
      default:
        return t("eng", { ns: MENU_NAMESPACE });
    }
  };

  if (wasVisited === null && location.pathname === routing.index()) {
    firstVisit = true;
    localStorage.setItem("was_visited", "1");
  } else {
    firstVisit = false;
  }

  if (firstVisit) {
    setAboutModalDisplay(true);
  }

  const userEmail = session?.account?.processedPayment?.initiator.emailAddress ?? "";
  const isInternalUser = () => {
    return userEmail !== undefined && userEmail.endsWith("@kubra.com");
  }
  const isProduction = () => {
    return process.env.REACT_APP_ENV === envs.PRODUCTION;
  }
  

  return (
    <>
      <Drawer
        drawerClassnames="drawer"
        open={menuButtonOpen}
        // @ts-ignore
        direction="left"
        overlayClassnames="ez-pay-menu-greyout"
      >
        <div className="ez-pay-menu-dropdown">
          <div className="menu-hamburger-in-dropdown">
            <MenuButton
              onClick={handleMenuClick}
              isOpen={menuButtonOpen}
              title="menu button"
              dataCy="button-menu"
            />
          </div>
          <MenuItem
            onClick={() => setAboutModalIsOpenToTrue()}
            variant="large"
            icon={
              <img
                src={aboutIcon}
                alt="home"
                className="ez-pay-menu-dropdown-icon"
              />
            }
            itemTheme="dark"
            className="ez-pay-menu-dropdown-button"
          >
            {t("about-ez-pay", { ns: MENU_NAMESPACE })}
          </MenuItem>
          <MenuItem
            onClick={() => setHelpModalIsOpenToTrue()}
            variant="large"
            icon={
              <img
                src={helpIcon}
                alt="help"
                className="ez-pay-menu-dropdown-icon"
              />
            }
            itemTheme="dark"
            className="ez-pay-menu-dropdown-button"
          >
            {t("help", { ns: COMMON_NAMESPACE })}
          </MenuItem>
          <MenuItem
            onClick={() => setPrivacyModalIsOpenToTrue()}
            variant="large"
            icon={
              <img
                src={privacyIcon}
                alt="privacy policy"
                className="ez-pay-menu-dropdown-icon"
              />
            }
            itemTheme="dark"
            className="ez-pay-menu-dropdown-button"
          >
            {t("privacy-policy", { ns: MENU_NAMESPACE })}
          </MenuItem>
          <MenuItem
            onClick={() => setTermsModalIsOpenToTrue()}
            variant="large"
            icon={
              <img
                src={termsIcon}
                alt="terms of use"
                className="ez-pay-menu-dropdown-icon"
              />
            }
            itemTheme="dark"
            className="ez-pay-menu-dropdown-button"
          >
            {t("terms-of-use", { ns: MENU_NAMESPACE })}
          </MenuItem>          
        </div>
      </Drawer>
      <div className="ez-pay-menu">
        <PageLoader />
        <div className="ez-pay-top-header">
          <div>
            <div className="ez-pay-menu-hamburger">
              <MenuButton
                onClick={handleMenuClick}
                isOpen={menuButtonOpen}
                title="menu button"
                dataCy="button-menu"
              />
            </div>

            <div className="ez-pay-menu-main">
              <span className="ez-pay-menu-logo-helper"></span>
              <img
                src={mainLogo}
                alt="KUBRA EZ-PAY"
                className="ez-pay-menu-logo"
                data-cy="menu-logo"
              />
            </div>
          </div>

          {(isInternalUser() && isProduction()) && (<StyledHeaderItemGroupDiv variant="right">
            <Feedback
              userEmail={userEmail}
            />
          </StyledHeaderItemGroupDiv>)}

          <div>
            {!props.isMobile && (
              <TextButton
                // @ts-ignore
                icon={<FontAwesomeIcon icon={faGlobe} />}
                onClick={() => {
                  setLanguageModalDisplay(true);
                }}
                dataCy="button-language"
              >
                {getLanguageDisplayValue(i18n.language)}
              </TextButton>
              )
            }
            {props.isMobile && (
              <StyledLanguageMobileButton
                // @ts-ignore
                icon={<FontAwesomeIcon icon={faGlobe} />}
                onClick={() => {
                  setLanguageModalDisplay(true);
                }}
                dataCy="button-language"
              >
                {getLanguageDisplayValue(i18n.language)}
              </StyledLanguageMobileButton>
            )}            
            {session.account && !props.isMobile && session.account.channel !== "CallCenter" && (                 
              <TextButton
                icon={<FontAwesomeIcon icon={faSignOut} />}
                onClick={() => handleLogoutClick()}
                dataCy="button-logout"
              >
                {t("change-account", { ns: MENU_NAMESPACE })}
              </TextButton>
              )
            }
            {session.account && props.isMobile && session.account.channel !== "CallCenter" && (                 
              <StyledChangeAccountMobileButton
                icon={<FontAwesomeIcon icon={faSignOut} />}
                onClick={() => handleLogoutClick()}
                dataCy="button-logout"
              >               
              </StyledChangeAccountMobileButton>
              )
            }
          </div>
        </div>

        {/* about ez-pay modal and sheet */}
        {props.isMobile && (
          // @ts-ignore
          <CustomSheet
            isOpen={aboutModalDisplay}
            onClose={() => setAboutModalIsOpenToFalse}
            detent="content-height"
            disableDrag={true}
          >
            <CustomSheet.Container>
              <CustomSheet.Header>
                <div>
                  <button
                    className="modal-close-mobile"
                    data-cy="modal-close-x-about"
                    onClick={setAboutModalIsOpenToFalse}
                  >
                    <img
                      src={XButton}
                      alt="close modal window"
                      className="modal-close-x"
                    />
                  </button>
                </div>
              </CustomSheet.Header>
              <CustomSheet.Content>
                <AboutModal closeCallBack={setAboutModalIsOpenToFalse} />
              </CustomSheet.Content>
            </CustomSheet.Container>
            {/* @ts-ignore */}
            <Sheet.Backdrop onClick={setAboutModalIsOpenToFalse} />
          </CustomSheet>
        )}
        {!props.isMobile && (
          // @ts-ignore
          <Modal
            isOpen={aboutModalDisplay}
            ariaHideApp={false}
            className="modal-frame-about"
            shouldCloseOnOverlayClick={true}
            onRequestClose={setAboutModalIsOpenToFalse}
            style={customStyles}
          >
            <div>
              <button
                className="modal-close"
                onClick={setAboutModalIsOpenToFalse}
                data-cy="modal-close-x-about"
                style={{ background: "#C4E7FF" }}
              >
                <img
                  src={XButton}
                  alt="close modal window"
                  className="modal-close-x"
                />
              </button>
            </div>
            <AboutModal closeCallBack={setAboutModalIsOpenToFalse} />
          </Modal>
        )}

        {/* help modal and sheet */}
        {props.isMobile && (
          <Sheet
            isOpen={helpModalDisplay}
            onClose={() => setHelpModalIsOpenToFalse}
            detent="content-height"
            disableDrag={true}
          >
            <Sheet.Container>
              <Sheet.Header>
                <div className="react-modal-sheet-header">
                  <div className="help-modal-header-text">
                    {t("help", { ns: COMMON_NAMESPACE })}
                  </div>
                  <button
                    className="modal-close"
                    onClick={setHelpModalIsOpenToFalse}
                    data-cy="modal-close-x-help"
                  >
                    <img
                      src={XButton}
                      alt="close modal window"
                      className="modal-close-x"
                    />
                  </button>
                </div>
              </Sheet.Header>
              <Sheet.Content>
                <HelpModal />
              </Sheet.Content>
            </Sheet.Container>
            {/* @ts-ignore */}
            <Sheet.Backdrop onClick={setHelpModalIsOpenToFalse} />
          </Sheet>
        )}
        {!props.isMobile && (
          // @ts-ignore
          <Modal
            isOpen={helpModalDisplay}
            ariaHideApp={false}
            className="modal-frame-help"
            shouldCloseOnOverlayClick={true}
            onRequestClose={setHelpModalIsOpenToFalse}
            style={customStyles}
          >
            <div className="modal-header">
              <div className="help-modal-header-text">
                {t("help", { ns: COMMON_NAMESPACE })}
              </div>
              <button
                className="modal-close"
                onClick={setHelpModalIsOpenToFalse}
                data-cy="modal-close-x-help"
              >
                <img
                  src={XButton}
                  alt="close modal window"
                  className="modal-close-x"
                />
              </button>
            </div>
            <HelpModal />
          </Modal>
        )}

        {/* privacy policy modal and sheet */}
        {props.isMobile && (
          <Sheet
            isOpen={privacyModalDisplay}
            onClose={() => setPrivacyModalIsOpenToFalse}
            disableDrag={true}
            detent="content-height"
          >
            <Sheet.Container>
              <Sheet.Header>
                <div className="react-modal-sheet-header">
                  <div className="help-modal-header-text">
                    {t("header", { ns: PRIVACY_MODAL_NAMESPACE })}
                  </div>
                  <button
                    className="modal-close"
                    onClick={setPrivacyModalIsOpenToFalse}
                    data-cy="modal-close-x-privacy"
                  >
                    <img
                      src={XButton}
                      alt="close modal window"
                      className="modal-close-x"
                    />
                  </button>
                </div>
              </Sheet.Header>
              <Sheet.Content>
                <PrivacyModal />
              </Sheet.Content>
            </Sheet.Container>
            {/* @ts-ignore */}
            <Sheet.Backdrop onClick={setPrivacyModalIsOpenToFalse} />
          </Sheet>
        )}
        {!props.isMobile && (
          // @ts-ignore
          <Modal
            isOpen={privacyModalDisplay}
            ariaHideApp={false}
            className="privacy-terms-modal-frame"
            shouldCloseOnOverlayClick={true}
            onRequestClose={setPrivacyModalIsOpenToFalse}
            style={customStyles}
          >
            <div className="modal-header">
              <div className="help-modal-header-text">
                {t("header", { ns: PRIVACY_MODAL_NAMESPACE })}
              </div>
              <button
                className="modal-close"
                onClick={setPrivacyModalIsOpenToFalse}
                data-cy="modal-close-x-privacy"
              >
                <img
                  src={XButton}
                  alt="close modal window"
                  className="modal-close-x"
                />
              </button>
            </div>
            <PrivacyModal />
          </Modal>
        )}

        {/* terms and conditions modal and sheet */}
        <TermsModal
          isOpen={termsModalDisplay}
          isMobile={props.isMobile}
          onClose={setTermsModalIsOpenToFalse}
          header="termsOfUse"
        />

        {/* language modal and sheet */}
        {props.isMobile && (
          <Sheet
            isOpen={languageModalDisplay}
            onClose={closeLanguagesModal}
            disableDrag={true}
            detent="content-height"
          >
            <Sheet.Container>
              <Sheet.Header>
                <div className="react-modal-sheet-header">
                  <div className="help-modal-header-text">
                    {t("header", { ns: LANGUAGE_MODAL_NAMESPACE })}
                  </div>
                  <button
                    className="modal-close"
                    onClick={closeLanguagesModal}
                    data-cy="modal-close-x-language"
                  >
                    <img
                      src={XButton}
                      alt={`${t("close-modal", { ns: COMMON_NAMESPACE })}`}
                      className="modal-close-x"
                    />
                  </button>
                </div>
              </Sheet.Header>
              <Sheet.Content>
                <LanguageModal onClose={closeLanguagesModal} />
              </Sheet.Content>
            </Sheet.Container>
            {/* @ts-ignore */}
            <Sheet.Backdrop onClick={closeLanguagesModal} />
          </Sheet>
        )}
        {!props.isMobile && (
          // @ts-ignore
          <Modal
            isOpen={languageModalDisplay}
            ariaHideApp={false}
            className="modal-frame-help"
            shouldCloseOnOverlayClick={true}
            onRequestClose={closeLanguagesModal}
            style={customStyles}
          >
            <div className="modal-header">
              <div className="help-modal-header-text">
                {t("header", { ns: LANGUAGE_MODAL_NAMESPACE })}
              </div>
              <button
                className="modal-close"
                onClick={closeLanguagesModal}
                data-cy="modal-close-x-help"
              >
                <img
                  src={XButton}
                  alt={`${t("close-modal", { ns: COMMON_NAMESPACE })}`}
                  className="modal-close-x"
                />
              </button>
            </div>
            <LanguageModal onClose={closeLanguagesModal} />
          </Modal>
        )}
      </div>
      <Outlet context={session} />
    </>
  );
};
