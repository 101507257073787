import React from "react";
import HowToPay from "textcontent/howtopay";
import WhatIsEzPay from "textcontent/whatisezpay";
import PaymentMethods from "textcontent/paymentmethods";
import { useTranslation } from "react-i18next";
import { HELP_MODAL_NAMESPACE } from "constants/i18n-namespaces";
import { Accordion, AccordionGroup } from "kubra-ux-forge";

export function HelpModal() {
  const { t } = useTranslation();

  const accordionData = [
    {
      id: 1,
      title: t("what-is-ez-pay-title", { ns: HELP_MODAL_NAMESPACE }),
      content: WhatIsEzPay,
    },
    {
      id: 2,
      title: t("how-to-make-payment-title", { ns: HELP_MODAL_NAMESPACE }),
      content: HowToPay,
    },
    {
      id: 3,
      title: t("accepted-payment-forms-title", { ns: HELP_MODAL_NAMESPACE }),
      content: PaymentMethods,
    },
  ];

  return (
    <div className="help-modal-accordion">
      <AccordionGroup>
        {accordionData.map(({ id, title, content }) => (
          <Accordion
            id={id.toString()}
            header={title}
            content={content()}
            key={id}
            iconPosition="left"
            data-cy="help-accordion"
          />
        ))}
      </AccordionGroup>
    </div>
  );
}
