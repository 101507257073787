import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "kubra-ux-forge";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { COMMON_NAMESPACE } from "constants/i18n-namespaces";

export interface PaymentButtonErrorFallbackProps {
  displayErrorMessage: string;
  error: any;
  resetErrorBoundary: () => void;
}

export const PaymentButtonErrorFallback = ({
  displayErrorMessage,
  error,
  resetErrorBoundary,
}: PaymentButtonErrorFallbackProps) => {
  const { t } = useTranslation(COMMON_NAMESPACE);

  console.error(error);
  return (
    <div className="payment-button-container">
      <div>{displayErrorMessage}</div>
      <div>
        <IconButton
          label={`${t("try-again")}`}
          icon={<FontAwesomeIcon icon={faRotateRight} />}
          variant="outline"
          onClick={resetErrorBoundary}
          title={`${t("try-again")}`}
        />
      </div>
    </div>
  );
};
