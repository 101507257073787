import { BILL_MODAL_NAMESPACE } from "constants/i18n-namespaces";
import React from "react";
import { useTranslation } from "react-i18next";

export interface IBillModalProps {
    billImage: string
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const BillModal =(props:IBillModalProps)=> {
    const {billImage} = props;
    const { t } = useTranslation();
    return(
            <div className="bill-modal-window">
                <div className="modal-label-bill">{t("modal-label", { ns: BILL_MODAL_NAMESPACE })}</div>
                <img src={billImage} alt="bill displaying account number" className="bill-image"></img>
            </div>
    );
}