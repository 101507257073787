/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SET_APP_STATE, IAppState } from "actions/appStateActions";

const initialState: IAppState = {
  loading: false,
};

const reduceAppState = (state: IAppState, action: any): IAppState => {
  if (action.type === SET_APP_STATE) {
    return {
      ...state,
      ...action.payload,
    };
  } else {
    return state;
  }
};

export const appStateReducer = (
  state = initialState,
  action: any
): IAppState => {
  const newState = reduceAppState(state, action);
  return newState;
};

export default appStateReducer;
