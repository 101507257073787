export const ACTION = {

    ADD_PAYMENT_AMOUNT: "AddPaymentAmount",
    ADD_BANK_ACCOUNT: "AddBankAccount",
    ADD_CREDIT_DEBIT_CARD: "AddCreditDebitCard",
    ADD_DIGITAL_WALLET: "AddDigitalWallet",
    ADD_WALLET: "AddWallet",
    ADD_INTENT_ID: "AddIntentId",
    ADD_CHARITIES: "AddCharities",
    ADD_AMOUNT_DUE: "AddAmountDue",
    ADD_RECEIPTINFO: "AddReceiptInfo",
    ADD_ACCOUNT_NO: "AddAccountNo",
    ADD_PAYMENT_DATE: "AddPaymentDate",
    ADD_DUE_DATE: "AddDueDate",
    ADD_SERVICE_FEE: "AddServiceFee",
    ADD_PAYMENT_STATUS: "AddPaymentStatus",
    ADD_PAYMENT_CONFIRMATION_NUMBER: "AddPaymentConfirmationNumber",
    ADD_PAYMENT_METHOD: "AddPaymentMethod"
}
