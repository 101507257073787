import origin from '../lib/origin';

const BASE_URL = origin();

export const api = {
  ez_pay: {
    hostInstances: 'https://api.dev.kubra.io/ez-pay/v1',
  },
  rcp: {
    hostInstances: 'https://api.dev.kubra.io/rcp/v1',
  }
};

export const imagePath = {
  imagePath: 'https://7a33ea98-c56f-4847-ba0e-7d101d37dcd3.mock.pstmn.io/rcp/v1/images?imageName=',
};

const authConfig = {
  clientId: 'PcggvMMnn7EzE9POSiz9CN4XtibvBfD2',
  origin: 'auth0.kubra.io',
  audience: 'https://kubra.io/api',
  silentCallbackPath: `${BASE_URL}/login/silent-callback`,
  callbackPath: `${BASE_URL}/login/callback`,
  logoutPath: `${BASE_URL}/login/logout`,
  responseType: 'token id_token',
  scope: 'openid profile name email',
  useCookies: false,
  redirectToCaller: true,
  allowUrlConfig: '',
};

const googleAnalyticsCode = 'G-EWSRXD93X2';

//GoogleMaps api key
const googlemapsApiKey = 'AIzaSyD-3r5UjRnWMD2z0VEC_XQye510slIxxlQ';

export default {
  api,
  auth: authConfig,
  googleAnalyticsCode,
  googlemapsApiKey
};
