import { paymentIntentInitialState } from "types/paymentIntent";
import { ACTION } from "actions/paymentIntentActions";


export const paymentIntentReducer = (
  state = paymentIntentInitialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case ACTION.ADD_INTENT_ID:
      return { ...state, intentId: action.payload };
    case ACTION.ADD_ACCOUNT_NO:
      return { ...state, accountNumber: action.payload };
    case ACTION.ADD_BANK_ACCOUNT:
      return { ...state, bankAccount: action.payload };
    case ACTION.ADD_CREDIT_DEBIT_CARD:
      return { ...state, creditDebitCard: action.payload };
    case ACTION.ADD_DIGITAL_WALLET:
      return { ...state, digitalWallet: action.payload };
    case ACTION.ADD_WALLET:
      return { ...state, wallet: action.payload };
    case ACTION.ADD_CHARITIES:
      return { ...state, charities: action.payload };
    case ACTION.ADD_AMOUNT_DUE:
      return { ...state, amountDue: action.payload };
    case ACTION.ADD_PAYMENT_AMOUNT:
      return { ...state, paymentAmount: action.payload };
    case ACTION.ADD_DUE_DATE:
      return { ...state, dueDate: action.payload };
    case ACTION.ADD_PAYMENT_DATE:
      return { ...state, paymentDate: action.payload };
    case ACTION.ADD_SERVICE_FEE:
      return { ...state, serviceFee: action.payload };
    case ACTION.ADD_RECEIPTINFO:
      return { ...state, receiptInfo: action.payload };
    case ACTION.ADD_PAYMENT_CONFIRMATION_NUMBER:
      return { ...state, paymentId: action.payload };
    case ACTION.ADD_PAYMENT_STATUS:
      return { ...state, paymentStatus: action.payload };
    case ACTION.ADD_PAYMENT_METHOD:
      return { ...state, paymentMethod: action.payload };
    default:
      return state;
  }
};
