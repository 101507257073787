import IamApi from "api/IamApi";
import { EMPTY, IN_PROGRESS, ERROR } from "constants/Status";
import { useBiller, usePaymentHistory } from "hooks";
import { Spinner } from "kubra-ux-forge";
import _ from "lodash";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { matchPath, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import routing from "routing";
import { getBillerLoadStatus } from "selectors";
import { ISession } from "types/session";
import { getDomain, mapSessionLanguageToUiLanguage } from "utilities/helper";
import { useTranslation } from "react-i18next";
import { RecentPayments } from "../common/recent-payments";
import { isMobile } from "react-device-detect";

export interface IPaymentProps {
  fingerprint: string;
}

export const Payment = (props: IPaymentProps) => {

  const { fingerprint } = props;
  const { loadBiller, getBiller } = useBiller();
  const loadBillerStatus = useSelector(getBillerLoadStatus);
  const biller = getBiller();
  const { login } = useOutletContext<ISession>();
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  const maxRecentPayments = 3;

  const getBillerConfiguration = () => {
    loadBiller();
  };

  const {
    loadRecentPayments,
    loadUpcomingPayment,
  } = usePaymentHistory();

  const getPaymentId = () => {
    const match = matchPath(routing.payment.read(":id"), location.pathname);
    return _.get(match, ["params", "id"]);
  };

  const queryParams = new URLSearchParams(location.search);

  const accountId = queryParams.get("AccountId");
  const language = queryParams.get("Language");
  const instanceId = queryParams.get("InstanceId");
  const hash = queryParams.get("hash");

  const checkParams = () => {
    return instanceId && accountId && language && hash;
  }

  useEffect(() => {
 
    const loadBillerAndPayments = async () => {
      const paymentId = getPaymentId();
      if (biller === null || biller === undefined) {
        getBillerConfiguration();
      }
      else if (biller && paymentId && fingerprint) {
        if (checkParams()) {
  
          const paymentRequest = {
            accountId: accountId,
            hash: hash
          };
  
          await IamApi.ezpay
            .getPayment(fingerprint, navigator.userAgent,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              getDomain(), instanceId!, paymentId, language!, biller.clientId, paymentRequest)
            .then((paymentResponse) => {
              //handle success
              console.log("direct landing success");
              login(paymentResponse.data);
              const selectedLanguage = mapSessionLanguageToUiLanguage(language?? "en");
              i18n.changeLanguage(selectedLanguage);
            })
            .catch((error) => {
              console.log(error);
              navigate(routing.index());
            });

            try {
              await Promise.all([
                loadRecentPayments(
                  maxRecentPayments,
                  biller.clientId,
                  biller.realm
                ),
                loadUpcomingPayment(biller.clientId, biller.realm),
              ]);
            } catch (error) {
              console.log('Error loading recent payments:', error);
              navigate(routing.index());
            }
        }
        else
          navigate(routing.index());
      }
    }

    loadBillerAndPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [biller, fingerprint]);

  if (
    loadBillerStatus === EMPTY ||
    loadBillerStatus === IN_PROGRESS ||
    loadBillerStatus === ERROR 
  ) {
    if (loadBillerStatus === ERROR) {
      console.log("failed to retrieve instance");
    }

    return (
      <div className="validate-frame">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Spinner size="large" variant="primary" />
        </div>
      </div>
    );
  }

  return (
    <RecentPayments  isMobile= {isMobile}/>)
};
export default Payment;