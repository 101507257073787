import React, { } from "react";
import { OverPaymentAmount } from "./over-payment-amount";
import { PartialPaymentAmount } from "./partial-payment-amount";

export interface IPaymentAmountModalProps {
  fullBalanceAmount: number;
  allowPartialPayment: boolean;
  allowOverPayment: boolean;
  updateCallBack: (amount: number) => void;
}

export const PaymentAmountModal = (props: IPaymentAmountModalProps) => {
  const {
    allowPartialPayment,
    allowOverPayment,
    fullBalanceAmount,
    updateCallBack,
  } = props;

  if (fullBalanceAmount <= 0 && allowOverPayment) {
    return (
      <OverPaymentAmount
        updateCallBack={updateCallBack}
      ></OverPaymentAmount>
    );
  }

  return (
    <PartialPaymentAmount
      fullBalanceAmount={fullBalanceAmount}
      allowPartialPayment={allowPartialPayment}
      updateCallBack={updateCallBack}
    ></PartialPaymentAmount>
  );
};
