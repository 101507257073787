import { SET_NEARBY_RETAILER_LOCATIONS, SET_SELECTED_STORE_LOCATIONS } from "actions/retailerLocationActions";
import { IRetailerStoreLocations } from "types/store-location";

const initialState: IRetailerStoreLocations = {
  nearbyStoreLocations: [],
  selectedStoreLocations: []
};

const reduceRetailerLocationState = (
  state: IRetailerStoreLocations,
  action: any
): IRetailerStoreLocations => {
  if (action.type === SET_NEARBY_RETAILER_LOCATIONS) {
    return {
      ...state,
      nearbyStoreLocations: action.payload,
    };
  } else if (action.type === SET_SELECTED_STORE_LOCATIONS) {
    return {
      ...state,
      selectedStoreLocations: action.payload,
    };
  } else {
    return state;
  }
};

export const retailerLocationReducer = (
  state = initialState,
  action: any
): IRetailerStoreLocations => {
  const newState = reduceRetailerLocationState(state, action);
  return newState;
};

export default retailerLocationReducer;
