import { SessionSupportedLanguages } from "types/session";
import { api } from "../../../config";
import ezpay from "../entities/ezpay";

// This defines the main structure of the routes object. Each key defines sub routes.
interface IGetRoutes {
  ezPay: IGetRoutesEzPay;
  rcp: IGetRoutesRcp;
}

/** Contract definitions * */
interface IGetRoutesTenants {
  readAll: () => string;
  read: (tenantId: string) => string;
}

interface IGetRoutesEzPay {
  getConfiguration: (subDomain: string) => string;
  postValidation: (instanceId: string) => string;
  postBalance: () => string;
  postAccountMessages: () => string;
  getBalance: (accountNumber: string, instanceId: string) => string;
  getTenantSnapshot: (tenantId: string) => string;
  getPaymentSettings: (snapshotId: string) => string;
  postEmail: (email: string) => string;
  postSms: (phoneno: string) => string;
  logout: () => string;
  getAccountFromSession: () => string;
  checkSession: () => string;
  updateSessionLanguage: (language: SessionSupportedLanguages) => string;
  postPaymentMethod: () => string;
  getBarcode: () => string;
  postInitiateIntent: () => string;
  postPrepareIntent: (paymentIntentId: string) => string;
  postProcessIntent: (paymentIntentId: string) => string;
  getPaymentIntent: (paymentIntentId: string) => string;
  getPayment: (paymentId: string, instanceId: string) => string;
  patchCancelIntent: (paymentIntentId: string) => string;
  validateApplePayMerchant: () => string;
  getWalletItems: () => string;
  deleteWalletItem: (fundingSourceId: string) => string;
  deletePaymentDueAlerts: () => string;
  getAccountByPaymentIntentId: (instanceId: string, paymentIntentId: string) => string;
  getRecentPayments: (count: number) => string;
  getUpcomingPayment: () => string;
  postCancelPayment: (paymentId: string) => string;
  postDirectLanding: (instanceId: string) => string;
  getCsrDirectLanding: (instanceId: string, paymentIntentId: string) => string;
  getExtendedSession: () => string;
}

interface IGetRoutesRcp {
  getRetailers: () => string;
  getLogo: (imageName: string) => string;
  postBarcode: () => string;
  postGooglePass: () => string;
  postApplePass: () => string;
  getBillerConfiguration: () => string;
  getLocationByGeo: (latitude: string, longitude: string, radius: number, limit: number) => string;
  getLocationByZip: (zipcode: string, limit: number) => string;
}

/**
 * This object defines the endpoint URLs for where requests should be sent. Note that they dont' actually send anything,
 * but instead are used by the other functions within the actions.
 * @param host
 */
const getRoutes = (ezpayHostInstances: string, rcpHostInstances: string): IGetRoutes => ({
  ezPay: {
    getConfiguration: (subDomain: string) => `${ezpayHostInstances}/configurations/web?subdomain=${subDomain}`,
    postValidation: (instanceId: string) => `${ezpayHostInstances}/validation/${instanceId}`,
    postBalance: () => `${ezpayHostInstances}/validation/balance`,
    getBalance: (accountNumber: string, instanceId: string) => `${ezpayHostInstances}/balance/${instanceId}/${accountNumber}`,
    getTenantSnapshot: (tenantId: string) => `${ezpayHostInstances}/snapshots/tenants/${tenantId}`,
    getPaymentSettings: (snapshotId: string) => `${ezpayHostInstances}/snapshots/${snapshotId}/paymentsettings`,
    postEmail: (email: string) => `${ezpayHostInstances}/barcodes/email/${email}`,
    postSms: (phoneno: string) => `${ezpayHostInstances}/barcodes/sms/${phoneno}`,
    logout: () => `${ezpayHostInstances}/session/logout`,
    getAccountFromSession: () => `${ezpayHostInstances}/session/account`,
    checkSession: () => `${ezpayHostInstances}/session`,
    updateSessionLanguage: (language: SessionSupportedLanguages) => `${ezpayHostInstances}/session/language/${language}`,
    postPaymentMethod: () => `${ezpayHostInstances}/payment`,
    getBarcode: () => `${ezpayHostInstances}/barcode`,
    postInitiateIntent: () => `${ezpayHostInstances}/initiate`,
    postPrepareIntent: (paymentIntentId: string) => `${ezpayHostInstances}/prepare/${paymentIntentId}`,
    postProcessIntent: (paymentIntentId: string) => `${ezpayHostInstances}/process/${paymentIntentId}`,
    getPaymentIntent: (paymentIntentId: string) => `${ezpayHostInstances}/retrieve/${paymentIntentId}`,
    getPayment: (paymentId: string, instanceId: string) => `${ezpayHostInstances}/validation/${instanceId}/payment/${paymentId}`,
    patchCancelIntent: (paymentIntentId: string) => `${ezpayHostInstances}/cancel/${paymentIntentId}`,
    validateApplePayMerchant: () => `${ezpayHostInstances}/applepay/validate`,
    getWalletItems: () => `${ezpayHostInstances}/wallets`,
    deleteWalletItem: (fundingSourceId: string) => `${ezpayHostInstances}/wallets/${fundingSourceId}`,
    getAccountByPaymentIntentId: (instanceId: string, paymentIntentId: string) => `${ezpayHostInstances}/validation/${instanceId}/paymentIntent/${paymentIntentId}`,
    deletePaymentDueAlerts: () => `${ezpayHostInstances}/alerts/unenroll`,
    getRecentPayments: (count: number) => `${ezpayHostInstances}/payments/recent/${count}`,
    getUpcomingPayment: () => `${ezpayHostInstances}/payments/upcoming`,
    postCancelPayment: (paymentId: string) => `${ezpayHostInstances}/payments/cancel/${paymentId}`,
    postDirectLanding: (instanceId: string) => `${ezpayHostInstances}/validation/${instanceId}/landing`,
    getCsrDirectLanding: (instanceId: string, paymentIntentId: string) => `${ezpayHostInstances}/validation/${instanceId}/csrDirectLanding/paymentIntent/${paymentIntentId}`,
    getExtendedSession: () => `${ezpayHostInstances}/Session/heartbeat`,
    postAccountMessages: () => `${ezpayHostInstances}/validation/restrictions`,
  },
  rcp: {
    getRetailers: () => `${rcpHostInstances}/billers/retailers`,
    getLogo: (imageName: string) =>
      `${rcpHostInstances}/images?imageName=${imageName}`,
    postBarcode: () => `${rcpHostInstances}/barcodes`,
    postGooglePass: () => `${rcpHostInstances}/googlepass`,
    getBillerConfiguration: () =>
      `${rcpHostInstances}/billers/configuration`,
    postApplePass: () => `${rcpHostInstances}/applepass`,
    getLocationByGeo: (latitude: string, longitude: string,
      radius: number,
      limit: number) => `${rcpHostInstances}/locations/geo/${latitude}/${longitude}?radius=${radius}&limit=${limit}`,
    getLocationByZip: (zipcode: string, limit: number) => `${rcpHostInstances}/locations/zip/${zipcode}?numberOfStores=${limit}`,

  },
});

const routes = getRoutes(api.ez_pay.hostInstances, api.rcp.hostInstances);

export default routes;
