import React, {  } from "react";
import { BillerLogo } from "components/ezpay/common/biller-logo";
import errorBalanceImage from "assets/error_gear_graphic.svg";
import { IBiller } from "types/biller";
import { useOutletContext } from "react-router";
import { ISession } from "types/session";
import {
    BALANCE_NAMESPACE,
    PAYMENT_RECEIPT_NAMESPACE,
} from "constants/i18n-namespaces";
import { Banner, Button } from "kubra-ux-forge";
import { useTranslation } from "react-i18next";
import { ContactAndTocFooter } from "components/ezpay/common/contact-and-toc-footer";
import { phoneFormat } from "utilities/helper";

interface ErrorBalanceProps {
    visible: boolean;
    biller: IBiller;
    accountNumber: string;
    isMobile: boolean;
}

export const ErrorBalance = ({ visible, biller, accountNumber, isMobile }: ErrorBalanceProps) => {
    const { logout } = useOutletContext<ISession>();

    const { t } = useTranslation(BALANCE_NAMESPACE);
    // get required values from subresources
    const imageResources: any = biller?.properties?.find((element: any) => {
        return element.name === "ImageResources";
    });

    const contactUs: any = biller?.properties?.find((element: any) => {
        return element.name === "InstanceConfiguration";
    });

    const contactNumber =  phoneFormat(contactUs?.properties?.phone ?? "");

    if (visible) {
        return (
            <>
                <div className="ez-pay-balance-banner">
                    <Banner
                        active={true}
                        variant={'info'}
                        messageLabel={t("could-not-load-balance-banner", {
                            phone: contactNumber,
                        })}
                        withClose={true}
                        withIcon={true}
                    />
                </div>
                <div data-testid="error-balance">

                    <div className="ez-pay-balance-frame">

                        <div className="ez-pay-balance-top">

                            <div className="validate-header">
                                <BillerLogo
                                    billerLogo={imageResources?.properties?.logoURL}
                                    billerUrl={contactUs?.properties?.website}
                                />
                            </div>
                            <div className="ez-pay-balance-row">

                                <div className="ez-pay-no-balance-content-center-amount-due-column">
                                    <img
                                        data-testid="error-loading-balance"
                                        src={errorBalanceImage}
                                        alt="could-not-load-balance"
                                    ></img>
                                    <div className="could-not-load-balance">
                                        {t("could-not-load-balance")}
                                    </div>
                                </div>
                                <div className="ez-pay-balance-content-center-small">
                                    <div className="ez-pay-balance-content-cell">
                                        {t("account-number")}{" "}
                                        <span
                                            className="ez-pay-balance-cell-value"
                                            data-cy="account-number"
                                        >
                                            {accountNumber}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="ez-pay-balance-divider"></div>

                            <form>

                                <div className="ez-pay-balance-row-noframe button">

                                    <Button
                                        type="button"
                                        className="validate-button"
                                        onClick={logout}
                                        dataCy="button-validate"
                                    >
                                        {t("change-account", {
                                            ns: PAYMENT_RECEIPT_NAMESPACE,
                                            name: biller?.billerName,
                                        })}
                                    </Button>

                                </div>
                                <ContactAndTocFooter biller={biller} isMobile={isMobile} />
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    return null;
};
