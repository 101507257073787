import React from "react";
import { postApplePass } from 'api/IamApi/entities/rcp';
import { IBiller, IImageResourcesProperties } from 'types/biller';
import imageSrc from 'assets/add_to_apple_wallet_button.svg';
export interface IAppleWalletButtonProps {
  href: string,
  biller: IBiller,
  barcodeText: string,
  accountNumber: string,
  realm: string
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AppleWalletButton = (props: IAppleWalletButtonProps) => {
  const { href = "#", biller, barcodeText, accountNumber, realm } = props;
  // ApplePass download

  const downloadApplePass = () => {

    const imageResources = biller?.properties?.find(element => element.name === "ImageResources")?.properties as IImageResourcesProperties;
    const applePassRequest = {
      billerName: biller.billerName,
      accountNumber: accountNumber,
      logoUrl: imageResources?.logoURL,
      barcode: barcodeText,
      modifiedBy: 'ezPay'
    };

    // Create and get Apple pass
    postApplePass(biller.clientId, applePassRequest, realm)
      .then(res => {
        if (res.data.content.length !== 0) {
          //create download link
          const linkSource = `data:application/vnd.apple.pkpass;base64,${res.data.content}`;
          const downloadLink = document.createElement('a');
          document.body.appendChild(downloadLink);
          downloadLink.href = linkSource;
          downloadLink.target = '_self';
          downloadLink.download = "appleWalletPass.pkpass";
          downloadLink.title = 'apple wallet downloaded to device';
          downloadLink.click();
        } else {
          console.log("Could not fetch apple pass.");
        }
      }).catch((error) => {
        console.log('Could not fetch apple pass.')
      });
  };

  return (
    <a href={href} onClick={downloadApplePass} onKeyDown={downloadApplePass}>
      <img
        src={imageSrc}
        alt="Apple wallet button"
        className="add-to-apple-wallet-button"
      />
    </a>
  );

}
