import React from "react";
import { TERMS_MODAL_NAMESPACE } from "constants/i18n-namespaces";
import { useTranslation } from "react-i18next";

function TermsAndConditions() {
  const { t } = useTranslation();

  return (
    <div className="long-text-scroll">
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: t("content", {
              interpolation: { escapeValue: false },
              ns: TERMS_MODAL_NAMESPACE,
            }),
          }}
        />
      </div>
    </div>
  );
}

export default TermsAndConditions;
