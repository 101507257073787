import { IBalance } from '../types/balance';

export const GET_BALANCE_REQUEST = "GET_ACCOUNT_BALANCE_REQUEST";
export const GET_BALANCE_SUCCESS = "GET_ACCOUNT_BALANCE_SUCCESS";
export const GET_BALANCE_FAILURE = "GET_ACCOUNT_BALANCE_FAILURE";

export const balanceInitialState: IBalance = {
    currentAmountDue: 120.00,
    dueDate: "2022-11-02 1:20:56 PM",
    lastUpdatedDate: "2022-10-08 1:20:56 PM",
    currentCharges: 90.00,
    balanceForward: 30.00,
    minimumAmountDue: 90.00,
    billedAmount: 60.00,
    messageCodes: [],
    fundingSourceTypeRestrictionsInEffect: [],
    lastUpdatedAtSourceDate: '',
    amountDueAfterDueDate: 0
};