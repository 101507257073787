import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useTranslation } from "react-i18next";

function useIdle({ onIdle, idleTime }) {
  const [isIdle, setIsIdle] = useState(null);
  const { i18n } = useTranslation();

  const handleOnIdle = (event) => {
    setIsIdle(true);
    const currentTime = new Date();
    const formattedCurrentTime = currentTime.toLocaleString(i18n.language, {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    });

    console.log("user is idle", event);
    console.log("Last Active time", getLastActiveTime());
    console.log("Current time", formattedCurrentTime);

    onIdle();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * idleTime,
    onIdle: handleOnIdle,
    debounce: 500,
  });
  return {
    getRemainingTime,
    getLastActiveTime,
    isIdle,
  };
}

export default useIdle;
