/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { balanceInitialState, GET_BALANCE_SUCCESS, GET_BALANCE_REQUEST, GET_BALANCE_FAILURE } from "actions/balanceActions";
import { DONE, EMPTY, ERROR, IN_PROGRESS } from "constants/Status";
import { IBalanceGetResult } from "types/balance";

export const balanceReducer = (
    state = { status: EMPTY, balance: balanceInitialState },
    action: any
): IBalanceGetResult => {
    const newState = reduceBalanceRequest(state, action);
    return newState;
};

const reduceBalanceRequest = (state: any, action: any): IBalanceGetResult => {
    switch (action.type) {
        case GET_BALANCE_SUCCESS:
            return {
                ...state,
                status: DONE,
                balance: action.response,
            };
        case GET_BALANCE_REQUEST:
            return {
                status: IN_PROGRESS,
            };
        case GET_BALANCE_FAILURE:
            return {
                status: ERROR,
            };
        default:
            return state;
    }

};