
import IamApi from "api/IamApi";
import { useBiller } from "hooks";
import _ from "lodash";
import React, { useEffect } from "react";
import { matchPath, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import routing from "routing";
import { ISession } from "types/session";
import { Spinner } from "kubra-ux-forge";
import { getDomain } from "utilities/helper";

export interface ICSRDirectLandingProps {
    fingerprint: string;
}

export const CsrDirectLanding = (props: ICSRDirectLandingProps) => {
    const { fingerprint } = props;
    const { loadBiller, getBiller } = useBiller();
    const biller = getBiller();
    const { login } = useOutletContext<ISession>();
    const navigate = useNavigate();
    const location = useLocation();
    const domain = getDomain();
    const getBillerConfiguraiton = () => {
        loadBiller();
    };

    const getPaymentIntentId = () => {
        const match = matchPath(
          routing.csrDirectLanding.read(":paymentIntentId"),
          location.pathname
        );
        return _.get(match, ["params", "paymentIntentId"]);
      };

    const paymentIntentId = getPaymentIntentId();

    if(!paymentIntentId)
      throw "Failed to retrieve payment intent Id from path";


    useEffect(() => {

        if (biller === null || biller === undefined) {
            getBillerConfiguraiton();
        } else if (biller) {
            IamApi.ezpay.getCsrDirectLanding(
                biller.instanceId,
                paymentIntentId,
                domain
            ).then((account) => {
                console.log("csr direct landing success");
                login({ ...account, fingerprint: fingerprint })
                navigate(routing.balance.index());
            }).catch((error) => {
                console.log(error);
                navigate(routing.index());
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [biller]);

    return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
        }}  
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: "0 auto",
            height: "50vh",
            backgroundColor: "white",
            width: "75vw",
            justifyContent: "center",
            borderRadius: "24px",
            verticalAlign: "middle"
          }}
        >
          <Spinner size="large" variant="primary" />
        </div>
      </div>
        
    </>)
};
export default CsrDirectLanding;