import { setAppLoading } from "actions/appStateActions";
import _ from "lodash";
import { Response, ErrorResponse } from "types/apiResponse";

const middleware = (
  result: Response,
  callback: (result: Response) => void,
  error = false
): void => {
  callback(result);
};

// the key on the action object where the details of the API call are stored
export const CALL_API = "CALL_API";

const createApiMiddleware =
  (
    authGuard?: (
      request: () => void,
      success: (response: Response) => void,
      error: (error: ErrorResponse) => void
    ) => void
  ): any =>
  (): any =>
  (next: any): any =>
  (action: any): any => {
    // get the API call details from the action object
    const callAPI = action[CALL_API];
    // if the CALL_API property is not found on the object, it is not an API action, and we can next()
    // to pass it on to the next middleware
    if (typeof callAPI === "undefined") {
      return next(action);
    }

    // destructure the properties of the API action
    const {
      types,
      request,
      misc,
      successHandler: callback,
      failureHandler: errorCallback,
    } = callAPI;

    // confirm we have the expected number of types
    if (!Array.isArray(types) || types.length !== 3) {
      throw new Error("Expected an array of three action types.");
    }
    if (!types.every((type): boolean => typeof type === "string")) {
      throw new Error("Expected action types to be strings.");
    }

    const actionWith = (
      data: Record<string, unknown>
    ): Record<string, unknown> => {
      const finalAction = {...action, ...data};
      delete finalAction[CALL_API];
      return finalAction;
    };

    // destructure the specific action types
    const [requestType, successType, failureType] = types;

    // next() an action indicating the request has started
    next(actionWith({ type: requestType }));
    next(setAppLoading(true));

    if (authGuard) {
      // if we are using authGuard, pass the request, success handlers and failure handlers to
      // the authGuard function
      return authGuard(
        // request fn
        (): void => request(),
        // success handler
        (response: Response): void => {
          next(
            actionWith({
              type: successType,
              response: response || {},
              misc,
            })
          );
          next(setAppLoading(false));
          if (callback) {
            middleware(response, callback);
          }
        },
        // error handler
        (error: ErrorResponse): void => {
          next(
            actionWith({
              type: failureType,
              response: _.get(error, "response.data", {}),
              misc,
            })
          );
          next(setAppLoading(false));
          if (errorCallback) middleware(error, errorCallback, true);
        }
      );
    }

    // if we are not using authGuard, just make the request and then handle the success/failure
    return (
      request()
        .then((response: Response): void => {
          // if we succeed, next() an action with the successType
          next(
            actionWith({
              type: successType,
              response: response || {},
              misc,
            })
          );
          next(setAppLoading(false));
          if (callback) middleware(response, callback);
        })
        // if we throw, next() an action with the failureType
        .catch((error: ErrorResponse): void => {
          next(
            actionWith({
              type: failureType,
              response: _.get(error, "response.data", {}),
              misc,
            })
          );
          next(setAppLoading(false));
          if (errorCallback) middleware(error, errorCallback, true);
        })
    );
  };

export default createApiMiddleware;
