import { IBankAccount } from "components/ezpay/secure/common/bank-account-modal";
import { ICreditDebitCard } from "components/ezpay/secure/common/credit-debit-modal";
import { IReceiptInfo } from "components/ezpay/secure/common/receipt-info-modal";
import FundingOption, { translateBankAccountType } from "constants/FundingOption";
import { getCardNetworkByShortCode } from "constants/FundingOption";
import { TFunction } from "i18next/typescript/t";

import { createSelector } from "reselect";
import { IPaymentIntent, IWallet } from "types/paymentIntent";

export const getBankAccount = (state: any): IBankAccount =>
  state.paymentIntent.bankAccount;
export const getCreditDebitCard = (state: any): ICreditDebitCard =>
  state.paymentIntent.creditDebitCard;
export const getWallet = (state: any): IWallet => state.paymentIntent.wallet;
export const getReceiptInfo = (state: any): IReceiptInfo =>
  state.paymentIntent.receiptInfo;
export const getPaymentIntent = (state: any): IPaymentIntent =>
  state.paymentIntent;
export const getPaymentMethod = (state: any): string =>
  state.paymentIntent.paymentMethod;
export const getTranslationFunction = (state: any, t: TFunction) => t;

export const getPaymentMethodLabel = createSelector(
  [getPaymentMethod, getBankAccount, getCreditDebitCard, getWallet, getTranslationFunction],
  (paymentMethod, bankAccount, creditDebitCard, wallet, t) => {

    let paymentMethodLabel = "";
    if (paymentMethod === FundingOption.BankAccount && bankAccount) {
      paymentMethodLabel =
      translateBankAccountType(bankAccount.accountType, t)  +
        " *" +
        bankAccount.bankAccountNumber.substring(
          bankAccount.bankAccountNumber.length - 4
        );
    } else if (paymentMethod === FundingOption.Card && creditDebitCard) {
      paymentMethodLabel =
        creditDebitCard.cardType +
        " *" +
        creditDebitCard.cardNo.substring(creditDebitCard.cardNo.length - 4);
    } else if (paymentMethod === FundingOption.Wallet && wallet) {
      if (wallet.fundingOption == FundingOption.Card) {
        paymentMethodLabel =
          getCardNetworkByShortCode(wallet.presentableMOPCode) +
          " " +
          wallet.maskedNumber;
      } else if (wallet.fundingOption === FundingOption.BankAccount) {
        paymentMethodLabel =
          translateBankAccountType(wallet.presentableMOPCode, t) +
          " " +
          wallet.maskedNumber;
      }
    }
    return paymentMethodLabel;
  }
);
