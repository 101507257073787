import { IPaymentSettings } from "types/paymentSettings";

export const GET_PAYMENTSETTINGS_REQUEST = "GET_PAYMENTSETTINGS_REQUEST";
export const GET_PAYMENTSETTINGS_SUCCESS = "GET_PAYMENTSETTINGS_SUCCESS";
export const GET_PAYMENTSETTINGS_FAILURE = "GET_PAYMENTSETTINGS_FAILURE";

export const paymentSettingsInitialState: IPaymentSettings = {
    workspaceId: "",
    allowPartialPayment: true,
    minPaymentAmount: 1,
    maxPaymentAmount: 10000,
    allowCashPayment: true,
};