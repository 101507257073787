import { CALL_API } from "../middleware/api";
import iamApi from "../api/IamApi";
import {
    GET_BILLER_CONFIGURATION_FAILURE,
    GET_BILLER_CONFIGURATION_REQUEST,
    GET_BILLER_CONFIGURATION_SUCCESS,
    GET_RETAILER_FAILURE,
    GET_RETAILER_REQUEST,
    GET_RETAILER_SUCCESS,
} from './retailerActions';
import { IActionsCreator } from "../types/action";

export const RetailerActionsCreator = {
    getRetailers: (
        clientId: string,
        realm: string,
        successHandler?: any,
        failureHandler?: any
    ): IActionsCreator => ({
        [CALL_API]: {
            types: [GET_RETAILER_REQUEST, GET_RETAILER_SUCCESS, GET_RETAILER_FAILURE],
            request: () => iamApi.rcp.getRetailers(clientId, realm),
            successHandler,
            failureHandler,
        },
    }),
    getBillerConfiguration: (
        clientId: string,
        realm: string,
        successHandler?: any,
        failureHandler?: any
    ): IActionsCreator => ({
        [CALL_API]: {
            types: [GET_BILLER_CONFIGURATION_REQUEST, GET_BILLER_CONFIGURATION_SUCCESS, GET_BILLER_CONFIGURATION_FAILURE],
            request: () => iamApi.rcp.getBillerConfiguration(clientId, realm),
            successHandler,
            failureHandler,
        },
    })
};

export default RetailerActionsCreator;