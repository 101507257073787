import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { getErrorMessage } from "utilities/helper";

function getEnhancedConfig(config?: AxiosRequestConfig) {
  return {
    ...(config ?? {}),
    headers: {
      ...(config?.headers || {}),
      accept: "application/json",
    },
  };
}

function getEnhancedConfigDeleteAlertsPayload(payload: any, config?: AxiosRequestConfig) {
  return {
    ...(config ?? {}),
    headers: {
      ...(config?.headers || {}),
      accept: "application/json",
      ...(payload.headers)
    },
    data: { ...payload.unenrollRequest }
  };
}

export const sendPostRequest = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  data: unknown,
  url: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<any>> =>
  axios
    .post(url, data, getEnhancedConfig(config))
    .then((response): any => response)
    .catch((reason) => {
      const error = getErrorMessage(reason);
      throw error;
    });

export const sendPutRequest = (
  data: unknown,
  url: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  axios
    .put(url, data, getEnhancedConfig(config))
    .then((response): any => response)
    .catch((reason) => {
      throw new Error(reason);
    });

export const sendGetRequest = (
  url: string,
  config?: AxiosRequestConfig
): Promise<any> => {
  //api call
  // Append a timestamp so this url is never cached
  let updatedUrl;
  if (url.includes("?")) {
    updatedUrl = `${url}&d=`;
  } else {
    updatedUrl = `${url}?d=`;
  }
  updatedUrl = `${updatedUrl}${new Date().getTime()}`;

  return axios
    .get(updatedUrl, getEnhancedConfig(config))
    .then((response): any => response.data)
    .catch((reason) => {
      throw new Error(reason);
    });
};

export const sendDeleteRequest = (
  url: string,

  config?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  axios
    .delete(url, getEnhancedConfig(config))
    .then((response): any => response)
    .catch((reason) => {
      throw new Error(reason);
    });

export const sendDeleteRequestWithPayload = (
  url: string,
  payload: unknown,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  axios
    .delete(url, getEnhancedConfigDeleteAlertsPayload(payload, config))
    .then((response): any => response)
    .catch((reason) => {
      throw new Error(reason);
    });

export const sendPatchRequest = (
  data: unknown,
  url: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  axios
    .patch(url, data, getEnhancedConfig(config))
    .then((response): any => response)
    .catch((reason) => {
      throw new Error(reason);
    });

