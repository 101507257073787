import _ from 'lodash';
import config from './default';

const qa = _.cloneDeep(config);

qa.api = {
  ez_pay: {
    hostInstances: 'https://api.qa.ez-pay.co/ez-pay/v1',
  },
  rcp: {
    hostInstances: 'https://api.qa.kubra.io/rcp/v1',
  }
};

export default qa;
