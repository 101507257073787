import { IStoreLocation } from "types/store-location";

export const SET_NEARBY_RETAILER_LOCATIONS = "SET_NEARBY_RETAILER_LOCATIONS";
export const SET_SELECTED_STORE_LOCATIONS = "SET_SELECTED_STORE_LOCATIONS";

export interface IRetailerLocationState {
  retailerLocations: IStoreLocation[];
}

export const setNearbyRetailerLocations = (
  retailerLocations: IStoreLocation[]
): Record<string, unknown> => ({
  type: SET_NEARBY_RETAILER_LOCATIONS,
  payload: retailerLocations
  ,
});

export const setSelectedStoreLocations = (
  retailerLocations: IStoreLocation[]
): Record<string, unknown> => ({
  type: SET_SELECTED_STORE_LOCATIONS,
  payload: retailerLocations
  ,
});
