import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { RETAILER_VIEW } from "constants/i18n-namespaces";
import { TextButton } from "kubra-ux-forge";
import { IStoreLocation } from "types/store-location";

export interface IRetailerItemProps {
  onSelectedStoreChange?: () => void;
  storeLocation: IStoreLocation
  mapMode: boolean;
  mapCallback: () => void;
}

export const RetailerItem = (props: IRetailerItemProps) => {
  const { t } = useTranslation(RETAILER_VIEW);
  const {
    onSelectedStoreChange,
    storeLocation,
    mapMode,
    mapCallback
  } = props;


  return (
    <div
      onClick={() => onSelectedStoreChange?.()}
      aria-hidden="true"
      key={storeLocation.storeId}
    >
      <div className="retailer-button">
        <div className="retailer-icon">
          <FontAwesomeIcon icon={faLocationDot} className="retailer-icon-pin" />
        </div>
        <div className="retailer-label">
          <div
            className="retailer-item-label-large"
            title={`${t("retailer-name")}`}
          >
            <b>{storeLocation.name}</b>
          </div>
          <div
            className="retailer-item-label"
            title={`${t("retailer-address")}`}
          >
            {storeLocation.street}
            <br />
            {storeLocation.city + " " + storeLocation.state}
          </div>
        </div>
        <div className="view-map-container">
          <div className="retailer-location">
            <div
              className="retailer-location-inner"
              title={`${t("retailer-location")}`}
            >
              {storeLocation.distance.toFixed(2)} mi
            </div>
          </div>
          {!mapMode && (
            <TextButton
              onClick={mapCallback}
              className="map-button"
              hoverStyle="underline"
              data-testid="retailer-item-view-map-button"
            >
              {t("view-map-label")}
            </TextButton>
          )}
        </div>
      </div>
    </div>
  );
};
