import { GET_BILLER_CONFIGURATION_FAILURE, GET_BILLER_CONFIGURATION_REQUEST, GET_BILLER_CONFIGURATION_SUCCESS, billerConfigurationInitialState }
    from "actions/retailerActions";
import { IBillerConfigurationGetResult } from "types/billerConfiguration";
import { DONE, EMPTY, ERROR, IN_PROGRESS } from "constants/Status";

const reduceBillerConfigurationRequest = (
    state: any,
    action: any
): IBillerConfigurationGetResult => {
    switch (action.type) {
        case GET_BILLER_CONFIGURATION_SUCCESS:
            return {
                ...state,
                status: DONE,
                billerConfiguration: action.response,
            };
        case GET_BILLER_CONFIGURATION_REQUEST:
            return {
                status: IN_PROGRESS,
            };
        case GET_BILLER_CONFIGURATION_FAILURE:
            return {
                status: ERROR,
            };
        default:
            return state;
    }
}

export const billerConfigurationReducer = (
    state = { status: EMPTY, ...billerConfigurationInitialState },
    action: any
): IBillerConfigurationGetResult => {
    const newState = reduceBillerConfigurationRequest(state, action);
    return newState;
};