import React from "react";
import Privacy from "textcontent/privacy";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function PrivacyModal() {
  return (
    <div className="terms-modal">
      <Privacy />
    </div>
  );
}
