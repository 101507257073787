import WalletActionsCreator from "actions/walletActionsCreator";
import { useDispatch, useSelector } from "react-redux";
import { getWalletItems } from "selectors/wallet";
import { WalletItemResponse } from "types/Api/Wallet/walletItemResponse";

export interface IUseRetailerConfiguration {
  loadWalletItems: (clientId: string) => Promise<void>;
  getWalletItems: () => WalletItemResponse[];
}

export default (): IUseRetailerConfiguration => {
  const dispatch = useDispatch();
  const getWalletItemsAction = WalletActionsCreator.getWalletItems;
  const walletItems = useSelector(getWalletItems);

  const loadWalletItems = (clientId: string) =>
    new Promise<void>((resolve, reject) =>
      dispatch(getWalletItemsAction(clientId, resolve, reject))
    );

  return {
    loadWalletItems,
    getWalletItems: () => walletItems,
  };
};
