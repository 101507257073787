import { useOutletContext, Navigate, Outlet } from "react-router";
import React from "react";
import routing from "routing";
import { ISession } from "types/session";
import { SessionTimeoutModal } from "components/ezpay/secure/common/session-timeout-modal";

export interface IProtectedRouteProps {
  element: JSX.Element;
}

export const ProtectedRoute = (props: IProtectedRouteProps) => {
  const session = useOutletContext<ISession>();

  return session?.account ? (
    (props.element && <>{props.element}<SessionTimeoutModal logout={session.logout} /></>) || <Outlet context={session} />
  ) : (
    <Navigate to={routing.index()} replace />
  );
};
