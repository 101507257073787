import React from "react";
import { Trans } from "react-i18next";
import { HELP_MODAL_NAMESPACE } from "constants/i18n-namespaces";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function PaymentMethods() {
  return (
    <div>
      <Trans
        i18nKey="accepted-payment-forms-description"
        ns={HELP_MODAL_NAMESPACE}
      />
    </div>
  );
}

export default PaymentMethods;
