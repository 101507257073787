/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'kubra-ux-forge';

export interface SkeletonRowProps {
  count?: number;
}

export const SkeletonRow: FC<SkeletonRowProps> = ({ count = 8 }) => {
  const rowSkeletons = [];
  for (let i = 0; i < count; i += 1) {
    rowSkeletons.push(
      <div key={i} className="skeleton-row">
        <Skeleton/>
      </div>,
    );
  }

  return <>{rowSkeletons}</>;
};

SkeletonRow.propTypes = {
  count: PropTypes.number,
};

SkeletonRow.defaultProps = {
  count: 8,
};

export default SkeletonRow;
