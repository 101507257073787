import { IRetailer } from '../types/retailer';
import { IBillerConfiguration } from "types/billerConfiguration"

export const GET_RETAILER_REQUEST = "GET_RETAILER_REQUEST";
export const GET_RETAILER_SUCCESS = "GET_RETAILER_SUCCESS";
export const GET_RETAILER_FAILURE = "GET_RETAILER_FAILURE";


export const GET_BILLER_CONFIGURATION_REQUEST = "GET_BILLER_CONFIGURATION_REQUEST";
export const GET_BILLER_CONFIGURATION_SUCCESS = "GET_BILLER_CONFIGURATION_SUCCESS";
export const GET_BILLER_CONFIGURATION_FAILURE = "GET_BILLER_CONFIGURATION_FAILURE";


export const retailerInitialState: IRetailer = {
    name: '',
    id: '',
    logo: '',
    icon: '',
    orderNo: 0,
    enabled: false
};

export const billerConfigurationInitialState: IBillerConfiguration = {
    id: 0,
    billerId: '',
    serviceFee: 0,
    minAmount: 0,
    maxAmount: 0,
    modifiedBy: '',
    dateModified: new Date()
}