import React, { useState } from "react";
import { ACTION } from "actions/paymentIntentActions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  COMMON_NAMESPACE,
  PAYMENT_AMOUNT_MODAL_NAMESPACE,
} from "constants/i18n-namespaces";
import { Button, RadioInput } from "kubra-ux-forge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";

export interface IOverPaymentAmountProps {
  updateCallBack: (amount: number) => void;
}

export const OverPaymentAmount = (props: IOverPaymentAmountProps) => {
  const { updateCallBack } = props;
  const [otherPaymentAmount, setOtherPaymentAmount] = useState<number | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const dispatch = useDispatch();
  const { t } = useTranslation(PAYMENT_AMOUNT_MODAL_NAMESPACE);

  const validateOtherPaymentAmount = () => {
    if (!otherPaymentAmount) {
      setErrorMessage(t("other-input-required"));
      return false;
    }
    return true;
  }
  const onSubmit = () => {
    if (validateOtherPaymentAmount() && otherPaymentAmount) {
      dispatch({
        type: ACTION.ADD_PAYMENT_AMOUNT,
        payload: Number(otherPaymentAmount),
      });
      updateCallBack(Number(otherPaymentAmount));
    }
  };

  return (
    // @ts-ignore
    <div className="balance-amount-frame-container">
      <div className="balance-amount-frame">
        <div
          className={"ez-pay-balance-frame-small"}
        >
          <RadioInput
            selected={true}
            label={t("other")}
            onSelect={() => undefined}
            icon={<FontAwesomeIcon icon={faDollarSign}></FontAwesomeIcon>}
            value={otherPaymentAmount ?? ""}
            onChange={(number) => { setOtherPaymentAmount(number); }}
            errorMessage={errorMessage}
          />
        </div>
        <Button
          type="submit"
          className="ez-pay-balance-done"
          title="continue button"
          data-cy="button-continue"
          onClick={onSubmit}
        >
          {t("done", { ns: COMMON_NAMESPACE })}
        </Button>
      </div>
    </div>
  );
};
