export const SET_APP_STATE = "SET_APP_STATE";

export interface IAppState {
  loading: boolean;
  title?: string;
  subText?: string;
}

export const setAppLoading = (
  loading: boolean,
  title = "",
  subText = ""
): Record<string, unknown> => ({
  type: SET_APP_STATE,
  payload: {
    loading,
    title: title ?? "",
    subText: subText ?? ""
  },
});
