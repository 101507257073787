import React from "react";
import PhoneIcon from "assets/icons/phone.svg";
import EnvelopeIcon from "assets/icons/envelope.svg";
import LocationIcon from "assets/icons/location-pin.svg";
import WebsiteIcon from "assets/icons/website.svg";

export interface IContactModalProps {
  contactAddress: string;
  contactCity: string;
  contactState: string;
  contactZip: string;
  contactPhone: string;
  contactEmail: string;
  contactWebsite: string;
}

export const ContactModal = (props: IContactModalProps) => {
  const {
    contactAddress,
    contactCity,
    contactState,
    contactZip,
    contactPhone,
    contactEmail,
    contactWebsite,
  } = props;
    const fullAddress = `${contactAddress}, ${contactCity} ${contactState} ${contactZip}`;
    return (
        <div className="contact-modal-panel">
            <div className="modal-window">
                <div title="contact phone" className="modal-label"><img src={PhoneIcon} alt="phone number" className="modal-icon" /><a href={"tel:" + contactPhone}>{contactPhone}</a></div>
                <div title="contact email address" className="modal-label"><img src={EnvelopeIcon} 
                    alt="email address" className="modal-icon" /><a href={"mailto:" + contactEmail}>{contactEmail}</a></div>
                <div title="contact address" className="modal-label"><img src={LocationIcon} alt="location" className="modal-icon" />
                    <a href={"https://maps.google.com/?q=" + fullAddress} target="_blank" rel="noreferrer">{fullAddress}</a></div>
                <div title="contact website" className="modal-label"><img src={WebsiteIcon} alt="website" className="modal-icon" />
                    <a href={contactWebsite} target="_blank" rel="noreferrer">{contactWebsite}</a>
                </div>
            </div>
        </div>

    );
};
