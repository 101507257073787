import { GET_WALLET_SUCCESS, GET_WALLET_REQUEST, GET_WALLET_FAILURE } from "actions/walletActions";
import { DONE, EMPTY, ERROR, IN_PROGRESS } from "constants/Status";
import { WalletItemsGetResult } from "types/Api/Wallet/walletItemResponse";

export const walletReducer = (
    state = { status: EMPTY, walletItems: [] },
    action: any
): WalletItemsGetResult => {
    const newState = reduceWalletItemsRequest(state, action);
    return newState;
};

const reduceWalletItemsRequest = (state: any, action: any): WalletItemsGetResult => {
    switch (action.type) {
        case GET_WALLET_SUCCESS:
            return {
                ...state,
                status: DONE,
                walletItems: action.response,
            };
        case GET_WALLET_REQUEST:
            return {
                status: IN_PROGRESS,
                walletItems: []
            };
        case GET_WALLET_FAILURE:
            return {
                status: ERROR,
                walletItems: []
            };
        default:
            return state;
    }

};