import { IAppState } from "actions/appStateActions";

export const getAppState = (state: any): IAppState => state.appState;
export const getAppLoading = (state: any): IAppState["loading"] => {
  return state.appState.loading;
};
export const getAppLoadingTitle = (state: any): IAppState["title"] => {
  return state.appState.title ?? "";
};
export const getAppLoadingSubText = (state: any): IAppState["subText"] => {
  return state.appState.subText ?? "";
};
