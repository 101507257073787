import { combineReducers } from "redux";
import { accountReducer } from "./accountReducer";
import appConfigReducer from "./appConfigReducer";
import { billerReducer } from "./billerReducer";
import { balanceReducer } from "./balanceReducer";
import { paymentSettingsReducer } from "./paymentSettingsReducer";
import { retailerReducer } from "./retailerReducer";
import { billerConfigurationReducer } from "./billerConfigurationReducer";
import { paymentIntentReducer } from "./paymentIntentReducer";
import appStateReducer from "./appStateReducer";
import { walletReducer } from "./walletReducer";
import { USER_LOGOUT } from "actions/userActions";
import { recentPaymentsReducer, upcomingPaymentReducer } from "./paymentHistoryReducer";
import retailerLocationReducer from "./retailerLocationsReducer";

const rootReducers = {
  appState: appStateReducer,
  config: appConfigReducer,
  biller: billerReducer,
  account: accountReducer,
  balance: balanceReducer,
  paymentSettings: paymentSettingsReducer,
  retailers: retailerReducer,
  billerConfiguration: billerConfigurationReducer,
  paymentIntent: paymentIntentReducer,
  wallet: walletReducer,
  recentPayments: recentPaymentsReducer,
  upcomingPayment: upcomingPaymentReducer,
  retailerLocations: retailerLocationReducer
};

/**
 * A function that will use combineReducers to merge additional reducers with the root reducer
 * @param {*} additionalReducers
 */

export const getRootReducerWith = (additionalReducers = {}): any =>
  combineReducers({
    ...rootReducers,
    ...additionalReducers,
  });

const appReducer = combineReducers(rootReducers);

export const rootReducer = (state: any, action: any) => {
  if (action.type === USER_LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
