import IamApi from "api/IamApi";
import FundingOption from "constants/FundingOption";
import React from "react";
import { PaymentIntentPrepareRequest } from "types/Api/Payment/paymentIntentPrepareRequest";
import { DigitalPaymentData } from "types/digitalPaymentData";
import { getDomain } from "utilities/helper";

export interface IApplePayButtonWrapperProps {
  country: string;
  currencyCode: string;
  clientId: string;
  paymentIntentId: string;
  amount: number;
  reviewDigitalPayment: (paymentData: DigitalPaymentData) => void;
  verifyPaymentAmount: () => { isValid: boolean };
  onPrepareIntentError: (err: any, prepareRequest: PaymentIntentPrepareRequest) => void;
}

export const ApplePayButtonWrapper = (props: IApplePayButtonWrapperProps) => {
  const {
    country,
    currencyCode,
    amount,
    clientId,
    paymentIntentId,
    reviewDigitalPayment,
    verifyPaymentAmount,
    onPrepareIntentError,
  } = props;

  const storeName = "KUBRA EZ-PAY";

  const isApplePaySupported = window.ApplePaySession?.canMakePayments();

  const sessionRequest = {
    countryCode: country,
    currencyCode: currencyCode,
    merchantCapabilities: ["supports3DS"],
    supportedNetworks: ["visa", "amex", "masterCard", "discover"],
    total: { label: storeName, amount: amount },
    lineItems: [{ label: "Payment Amount", amount: amount }],
    requiredShippingContactFields: ["name", "phone", "email"],
  };

  const applePayButtonClick = (event: any) => {

    const isValidAmount = verifyPaymentAmount();
    console.log( "verify amount: ", isValidAmount);
    if (!isValidAmount.isValid) {
      event.preventDefault();
      return;
    }
    
    // create payment session
    const session = new window.ApplePaySession(3, sessionRequest);

    // show the payment sheet..
    session.begin();

    /**
     * Merchant Validation
     * We call ShieldPay Api ApplePay validate merchant session endpoint, passing the URL to use
     */
    session.onvalidatemerchant = function (event: any) {
      const promise = IamApi.ezpay.validateApplePayMerchant({
        validationURL: event.validationURL,
        domainName: getDomain(),
      });
      promise.then(
        function (response: any) {
          //Validation successful, complete the merchant validation
          const data = JSON.parse(response.data);
          session.completeMerchantValidation(data);
        },
        function (error: any) {
          //Validation not successful or error occurred
          session.abort();
        }
      );
    };

    /**
     * This is called when user dismisses the payment modal
     */
    session.oncancel = function (event: any) {
      // Re-enable Apple Pay button or display appropriate message to user ..
    };

    /**
     * Payment Authorization
     * Here you receive the encrypted payment data. You would then send it
     * on to your payment provider for processing, and return an appropriate
     * status in session.completePayment()
     */
    session.onpaymentauthorized = async function (event: any) {
      // Send payment for processing...
      const enc = window.btoa(JSON.stringify(event.payment.token.paymentData));
      const prepareRequest: PaymentIntentPrepareRequest = {
        paymentAmount: amount,
        fundingOption: FundingOption.ApplePay,
        digitalWallet: {
          paymentToken: enc,
          cardType: event.payment.token.paymentMethod.network,
        },
      };

     await IamApi.ezpay
        .postPrepareIntent(clientId, paymentIntentId, prepareRequest)
        .then((response) => {
          session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
          const paymentData: DigitalPaymentData = {
            payer: `${event.payment.shippingContact.givenName} ${event.payment.shippingContact.familyName}`,
            email: event.payment.shippingContact.emailAddress,
            mobileNumber: event.payment.shippingContact.phoneNumber,
            paymentToken: enc,
            cardType: event.payment.token.paymentMethod.network,
            paymentMethod: FundingOption.ApplePay,
            serviceFee: Number(response.data.feeTransactionAmount),
          };
          reviewDigitalPayment(paymentData);
        })
        .catch((error) => {
          onPrepareIntentError(error, prepareRequest);
          session.completePayment(window.ApplePaySession.STATUS_FAILURE);
        });
    };
  };

  if (!isApplePaySupported) return null;

  return (
    <button
      style={{
        height: "50px !important",
        width: "100%",
        alignSelf: "center",                
        boxSizing: "content-box",
        padding: "10px 0px"
      }}
      className="apple-pay-button apple-pay-button-white"
      onClick={applePayButtonClick}
    ></button>
  );
};
