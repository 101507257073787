import { useEffect } from "react";

export const useJiraIssueCollector = (email: string) => {
  //GET NAME AND EMAIL HERE

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src =
      // eslint-disable-next-line max-len
      "https://kubra.jira.com/s/d41d8cd98f00b204e9800998ecf8427e-T/-3o5b4z/b/5/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=01f9583c";
    script.integrity = "sha384-oqVuAfXRKap7fdgcCY5uykM6+R9GqQ8K/uxy9rx7HNQlGYl1kPzQho1wx4JwY8wC";
    const trigger = document.createElement("script");
    trigger.type = "text/javascript";
    trigger.async = true;
    trigger.textContent = `
        window.ATL_JQ_PAGE_PROPS =  {
          "triggerFunction": function (showCollectorDialog) {
            window.showCollectorDialog = showCollectorDialog;
          },
          "fieldValues": {
            fullname : '${''}',
            email : '${email}'
          }	
        };
      `;

    document.head.appendChild(script);
    document.head.appendChild(trigger);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);
};
