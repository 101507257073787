export const COMMON_NAMESPACE = "common";
export const MENU_NAMESPACE = "menu";
export const LANGUAGE_MODAL_NAMESPACE = "languageModal";
export const ABOUT_MODAL_NAMESPACE = "aboutModal";
export const BILLER_LOGO_NAMESPACE = "billerLogo";
export const VALIDATE_NAMESPACE = "validate";
export const BILL_MODAL_NAMESPACE = "billModal";
export const HELP_MODAL_NAMESPACE = "helpModal";
export const PRIVACY_MODAL_NAMESPACE = "privacyModal";
export const TERMS_MODAL_NAMESPACE = "termsConditionsModal";
export const BALANCE_NAMESPACE = "balance";
export const CONTACT_TOC_FOOTER = "contactAndTocFooter";
export const DONATION = "donation";
export const BANK_ACCCOUNT_MODAL = "bankAccountModal";
export const CREDIT_DEBIT_MODAL = "creditDebitModal";
export const CASH_MODAL = "cashModal";
export const BARCODE_VIEW = "barcodeView";
export const EMAIL_VIEW = "emailView";
export const SMS_VIEW = "smsView";
export const RETAILER_VIEW = "retailerView";
export const PAYMENT_AMOUNT_MODAL_NAMESPACE = "paymentAmountModal";
export const PAYMENT_METHOD_MODAL_NAMESPACE = "paymentMethodModal";
export const REVIEW_CHARGES_MODAL_NAMESPACE = "reviewChargesModal";
export const RECEIPT_INFO_MODAL_NAMESPACE = "receiptInfoModal";
export const PAYMENT_RECEIPT_NAMESPACE = "paymentReceipt";
export const PAYSLIP_NAMESPACE = "payslipView";
export const MANAGE_PAYMENT_METHODS_MODAL = "managePaymentMethodsModal"
export const PAYMENT_DATE_MODAL = "paymentDateModal";
export const UNSUBSCRIBE_ALERTS = "unsubscribeAlerts";
export const PROCESSED_PAYMENT_RECEIPT_NAMESPACE = "processedPaymentReceipt";
export const LINK_EXPIRED_NAMESPACE = "linkExpired";
export const RECENT_PAYMENTS_NAMESPACE = "recentPayments"
export const SESSION_TIMEOUT = "sessionTimeout";


const namespaces = {
  COMMON_NAMESPACE,
  MENU_NAMESPACE,
  LANGUAGE_MODAL_NAMESPACE,
  ABOUT_MODAL_NAMESPACE,
  BILLER_LOGO_NAMESPACE,
  VALIDATE_NAMESPACE,
  BILL_MODAL_NAMESPACE,
  HELP_MODAL_NAMESPACE,
  PRIVACY_MODAL_NAMESPACE,
  TERMS_MODAL_NAMESPACE,
  BALANCE_NAMESPACE,
  CONTACT_TOC_FOOTER,
  DONATION,
  BANK_ACCCOUNT_MODAL,
  CREDIT_DEBIT_MODAL,
  CASH_MODAL,
  BARCODE_VIEW,
  EMAIL_VIEW,
  SMS_VIEW,
  RETAILER_VIEW,
  PAYMENT_AMOUNT_MODAL_NAMESPACE,
  PAYMENT_METHOD_MODAL_NAMESPACE,
  REVIEW_CHARGES_MODAL_NAMESPACE,
  RECEIPT_INFO_MODAL_NAMESPACE,
  PAYMENT_RECEIPT_NAMESPACE,
  PAYSLIP_NAMESPACE,
  MANAGE_PAYMENT_METHODS_MODAL,
  UNSUBSCRIBE_ALERTS,
  PROCESSED_PAYMENT_RECEIPT_NAMESPACE,
  LINK_EXPIRED_NAMESPACE,
  RECENT_PAYMENTS_NAMESPACE,
  SESSION_TIMEOUT
};

export default namespaces;
