import React from "react";
import { ValidationContainer } from "../validation-container";
import { ThemeProvider } from "react-jss";
import { theme } from "constants/Theme";
import { Banner } from "kubra-ux-forge";
import { useBiller } from "hooks";
import { VALIDATE_NAMESPACE } from "constants/i18n-namespaces";
import { useTranslation } from "react-i18next";
import { IBillerMessage } from "types/biller";
import i18n from "i18n/I18nApp";

export interface IValidationView {
  isMobile: boolean;
  fingerprint: string;
}

export const ValidationView = (props: IValidationView) => {
  const { isMobile, fingerprint } = props;
  const { getBiller } = useBiller();
  const biller = getBiller();
  const { t } = useTranslation(VALIDATE_NAMESPACE);

  const billerMessageProperties = biller?.properties?.find((element) => {
    return element.name === "InstanceConfiguration";
  }) as IBillerMessage;

  const billerMessageEnabled = billerMessageProperties?.properties?.billerMessageWebEnabled === "true";

  const billerMessage = () => {
    if (billerMessageEnabled) {
      try {
        const billerMessage = JSON.parse(billerMessageProperties?.properties.billerMessageWeb);
        const msg = billerMessage.find((msg: { language: string; }) => msg.language === i18n.language);
        if (msg) {
          return msg.value;
        }
        return undefined;
      } catch (error) {
        return undefined;
      }

    }
  };

  return (
    // @ts-ignore
    <ThemeProvider theme={theme}>
      {/* banner for Biller Message*/}
      {(billerMessageEnabled && billerMessage() != undefined) && (
        <div className="ez-pay-balance-banner">
          <Banner
            active={true}
            variant="info"
            messageLabel={t("biller-message-label", {
              billerName: biller.billerName,
            })}
            messageBody={billerMessage()}
            withIcon
            withClose
          ></Banner>
        </div>
      )}
      <ValidationContainer isMobile={isMobile} fingerprint={fingerprint} />
    </ThemeProvider>
  );
};
export default ValidationView;