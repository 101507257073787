/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { billerInitialState, GET_BILLER_SUCCESS, GET_BILLER_REQUEST, GET_BILLER_FAILURE } from "actions/billerActions";
import { DONE, EMPTY, ERROR, IN_PROGRESS } from "constants/Status";
import { IBillerGetResult } from "types/biller";

export const billerReducer = (
    state = { status: EMPTY, ...billerInitialState },
    action: any
): IBillerGetResult => {
    const newState = reduceBillerRequest(state, action);
    return newState;
};

const reduceBillerRequest = (state: any, action: any): IBillerGetResult => {
    switch (action.type) {
        case GET_BILLER_SUCCESS:
            return {
                ...state,
                status: DONE,
                biller: action.response,
            };
        case GET_BILLER_REQUEST:
            return {
                ...state,
                status: IN_PROGRESS,
            };
        case GET_BILLER_FAILURE:
            return {
                ...state,
                status: ERROR,
            };
        default:
            return state;
    }

};