import { DONATION } from "constants/i18n-namespaces";
import {
  Checkbox,
  PaymentInput,
  RadioButton,
  RadioButtonGroup,
} from "kubra-ux-forge";
import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { useSelector } from "react-redux";
import { getPaymentIntent } from "selectors/paymentIntent";
import { useTranslation } from "react-i18next";
import { ICharity } from "types/charity";
import { useCurrencyFormatter } from "hooks";
import Sheet from "react-modal-sheet";
import Modal from "react-modal";
import { isMobile } from "react-device-detect";
import XButton from "assets/icons/x_button.svg";
import { customStyles } from "constants/customStyles";

export interface IDonationProps {
  charity: ICharity;
  onCharityAmountSelected: (amount: number) => void;
  onCharitySelected: (isSelected: boolean) => void;
}

const convertInputToNumber = (newInputValue = "0"): number => {
  return parseInt(newInputValue.replace(/\D*/g, ""), 10);
};

const OTHER = "other";
const donationOptions = ["10", "20", "50", OTHER];

export const Donation = ({
  charity,
  onCharityAmountSelected,
  onCharitySelected,
}: IDonationProps) => {
  const { t } = useTranslation(DONATION);
  const [selectedOption, setSelectedOption] = useState(donationOptions[0]);
  const isOptionOther = selectedOption === OTHER;
  const paymentIntentState = useSelector(getPaymentIntent);
  const charityState = paymentIntentState?.charities?.find(
    (charityState) => charityState.charity.code === charity.code
  ) ?? { charity, selected: false, amount: 0 };
  const [showCharityModal, setShowCharityModal] = useState(false);

  const handleSelectionCharityChange = () => {
    const newValue = !charityState.selected;
    onCharitySelected(newValue);
  };
  const handleAmountChange = (
    event: SyntheticEvent<HTMLInputElement, Event>
  ) => {
    const newOptionValue = event.currentTarget.value;
    setSelectedOption(newOptionValue);
    const newAmount = newOptionValue !== OTHER ? +newOptionValue : 0;
    onCharityAmountSelected(newAmount);
  };

  const handlePaymentInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newAmount = convertInputToNumber(event.currentTarget.value);
    newAmount /= 100;
    onCharityAmountSelected(newAmount);
  };

  const setCharityModalIsOpenToTrue = () => {
    setShowCharityModal(true);
  };

  const setCharityModalIsOpenToFalse = () => {
    setShowCharityModal(false);
  };

  return (
    <div>
      <div className="charity-checkbox-label" data-cy={`checkbox-donation-${charity.name}`}>
        <Checkbox
          defaultChecked={charityState.selected}
          label=""
          onChange={handleSelectionCharityChange}
          dataCy={`checkbox-donation-${charity.name}`}
        />
        <span data-cy="add-donation-text">{t("add-donation-to")}&nbsp;</span>
        {charityState.selected ? (
          <button
            className="footer-link-button"
            style={{ textDecoration: "underline", marginBottom: "0px" }}
            onClick={() => setCharityModalIsOpenToTrue()}
            data-cy={`donation-link-${charity.name}`}
          >
            {charity.name}
          </button>
        ) : (
          <span data-cy={`donation-link-${charity.name}`}>{charity.name}</span>
        )}
      </div>
      {charityState.selected && (
        <div>
          <br />
          <span className="charity-sub-title">
            {t("select-donation-amount")}
          </span>
          <br />
          <div className="charities-container">
            <div className="charities-content">
              <RadioButtonGroup
                groupName={`${charity.code}-charity-amount-selection`}
                onChange={handleAmountChange}
              >
                {donationOptions.map((option) => (
                  <RadioButton
                    id={`${charity.code}-${option}`}
                    key={`${charity.code}-${option}`}
                    label={
                      option === OTHER
                        ? t("other")
                        : `$${option}`
                    }
                    value={option}
                  />
                ))}
              </RadioButtonGroup>
            </div>
            <div
              className="charities-content"
              data-cy={`charities-content-${charity.name}`}
            >
              {isOptionOther && (
                <PaymentInput
                  min={1}
                  errorText={t("invalid-donation-amount")}
                  aria-label={`${t("other-field-label")}`}
                  onChange={handlePaymentInputChange}
                  defaultValue={`${charityState.amount}`}
                  dataCy={`input-payment-${charity.name}`}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {/* charity modal and sheet */}
      {isMobile && (
        <Sheet
          isOpen={showCharityModal}
          onClose={() => setCharityModalIsOpenToFalse}
          disableDrag={true}
          detent="content-height"
        >
          <Sheet.Container>
            <Sheet.Header>
              <div className="react-modal-sheet-header">
                <div className="help-modal-header-text">{`${charity.name} ${t(
                  "charity"
                )}`}</div>
                <button
                  className="modal-close"
                  onClick={setCharityModalIsOpenToFalse}
                  data-cy="modal-close-x-privacy"
                >
                  <img
                    src={XButton}
                    alt="close modal window"
                    className="modal-close-x"
                  />
                </button>
              </div>
            </Sheet.Header>
            <Sheet.Content>
              {/* TODO remove hardcoded charity description and add dynamic charity description from API call */}
              <div className="charity-description-container">
                <p className="charity-description-text">
                  ABC Charity: Empowering lives through education, healthcare,
                  and sustainability. Committed to making a difference, they
                  inspire hope and create a brighter future for communities in
                  need worldwide. Join their compassionate initiatives and
                  transform lives today!
                </p>
                <button
                  className="close-button"
                  onClick={() => setCharityModalIsOpenToFalse()}
                >
                  {t("Close")}
                </button>
              </div>
            </Sheet.Content>
          </Sheet.Container>
          {/* @ts-ignore */}
          <Sheet.Backdrop onClick={setCharityModalIsOpenToFalse} />
        </Sheet>
      )}
      {!isMobile && (
        // @ts-ignore
        <Modal
          isOpen={showCharityModal}
          ariaHideApp={false}
          className="charity-modal-frame"
          shouldCloseOnOverlayClick={true}
          onRequestClose={setCharityModalIsOpenToFalse}
          style={customStyles}
        >
          <div className="modal-header">
            <div className="help-modal-header-text">{`${charity.name} ${t(
              "charity"
            )}`}</div>
            <button
              className="modal-close"
              onClick={setCharityModalIsOpenToFalse}
              data-cy="modal-close-x-privacy"
            >
              <img
                src={XButton}
                alt="close modal window"
                className="modal-close-x"
              />
            </button>
          </div>
          {/* TODO remove hardcoded charity description and add dynamic charity description from API call */}
          <div className="charity-description-container">
            <p className="charity-description-text">
              ABC Charity: Empowering lives through education, healthcare, and
              sustainability. Committed to making a difference, they inspire
              hope and create a brighter future for communities in need
              worldwide. Join their compassionate initiatives and transform
              lives today!
            </p>
            <button
              className="close-button"
              onClick={() => setCharityModalIsOpenToFalse()}
            >
              {t("Close")}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};
