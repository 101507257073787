import { useDispatch, useSelector } from "react-redux";
import { IRetailer } from "types/retailer";
import { getBillerConfiguration, getRetailers } from "selectors";
import { RetailerActionsCreator } from "actions/retailerActionsCreator";
import { IBillerConfiguration } from "types/billerConfiguration";

export interface IUseRetailerConfiguration {
  loadRetailers: (headerValue: string, realm: string) => Promise<void>;
  getRetailers: () => IRetailer[];
  loadBillerConfiguration: (clientId: string, realm: string) => Promise<void>;
  getBillerConfiguration: () => IBillerConfiguration;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (): IUseRetailerConfiguration => {
  const dispatch = useDispatch();
  const getRetailerAction = RetailerActionsCreator.getRetailers;
  const getBillerConfigurationAction =
    RetailerActionsCreator.getBillerConfiguration;
  const retailers = useSelector(getRetailers);
  const configuration = useSelector(getBillerConfiguration);

  const loadRetailers = (tenantId: string, realm: string) =>
    new Promise<void>((resolve, reject) =>
      dispatch(getRetailerAction(tenantId, realm, resolve, reject))
    );

  const loadBillerConfiguration = (tenantId: string, realm: string) =>
    new Promise<void>((resolve, reject) =>
      dispatch(getBillerConfigurationAction(tenantId, realm, resolve, reject))
    );

  return {
    loadRetailers,
    getRetailers: () => retailers,
    loadBillerConfiguration,
    getBillerConfiguration: () => configuration,
  };
};
