import React from "react";
import TermsAndConditions from "textcontent/terms";
import Modal from "react-modal";
import Sheet from "react-modal-sheet";
import XButton from "assets/icons/x_button.svg";
import {
  COMMON_NAMESPACE,
  TERMS_MODAL_NAMESPACE,
} from "constants/i18n-namespaces";
import { useTranslation } from "react-i18next";
import { customStyles } from "constants/customStyles";

export interface TermsModalProps {
  isOpen: boolean;
  isMobile: boolean;
  onClose: () => void;
  header?: string;
}

export function TermsModal({ isOpen, isMobile, onClose, header }: TermsModalProps) {
  const { t } = useTranslation(TERMS_MODAL_NAMESPACE);

  return (
    <>
      {isMobile && (
        <Sheet
          isOpen={isOpen}
          onClose={onClose}
          detent="content-height"
          disableDrag={true}
        >
          <Sheet.Container>
            <Sheet.Header>
              <div className="react-modal-sheet-header">
                <div className="help-modal-header-text">
                  {header === "termsOfUse" ? t("menu-header") : t("header")}
                </div>
                <button
                  className="modal-close"
                  onClick={onClose}
                  aria-label={`${t("close-modal", { ns: COMMON_NAMESPACE })}}`}
                  data-cy="modal-close-x-help"
                >
                  <img
                    src={XButton}
                    alt={`${t("close-modal", { ns: COMMON_NAMESPACE })}}`}
                    className="modal-close-x"
                  />
                </button>
              </div>
            </Sheet.Header>
            <Sheet.Content>
              <div className="terms-modal">
                <TermsAndConditions />
              </div>
            </Sheet.Content>
          </Sheet.Container>
          {/*@ts-ignore*/}
          <Sheet.Backdrop onClick={onClose} />
        </Sheet>
      )}
      {!isMobile && (
        /* @ts-ignore */
        <Modal
          isOpen={isOpen}
          ariaHideApp={false}
          className="privacy-terms-modal-frame"
          shouldCloseOnOverlayClick={true}
          onRequestClose={onClose}
          style={customStyles}
        >
          <div className="modal-header">
            <div className="help-modal-header-text">
              {header === "termsOfUse" ? t("menu-header") : t("header")}
            </div>
            <button
              className="modal-close"
              onClick={onClose}
              aria-label={`${t("close-modal", { ns: COMMON_NAMESPACE })}}`}
              data-cy="modal-close-x-help"
            >
              <img
                src={XButton}
                alt={`${t("close-modal", { ns: COMMON_NAMESPACE })}}`}
                className="modal-close-x"
              />
            </button>
          </div>
          <div className="terms-modal">
            <TermsAndConditions />
          </div>
        </Modal>
      )}
    </>
  );
}
