/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { SET_APP_CONFIG, IAppConfig } from '../actions/appConfigActions';

const initialState: IAppConfig = {
    tenantId: 'tenantId',
}

const reduceAppConfig = (state: IAppConfig, action: any): IAppConfig => {
  if (action.type === SET_APP_CONFIG) {
    return {
      ...state,
      ...action.config,
    };
  } else {
    return state;
  }
};

export const appConfigReducer = (state = initialState, action: any): IAppConfig => {
  const newState = reduceAppConfig(state, action);
  return newState;
};

export default appConfigReducer;
