export const zipCode = "zipCode";
export const retailerId = "retailerId";
export const storeId = "storeId";
export const lat = "lat";
export const lng = "lng";

export default {
    zipCode,
    retailerId,
    storeId,
    lat,
    lng
}