import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import "./map.scss";
import { IStoreLocation } from "types/store-location";
import XButton from "assets/icons/x_button.svg";
import nubbin from "assets/nubbin.svg";
import { RETAILER_VIEW } from "constants/i18n-namespaces";
import { useTranslation } from "react-i18next";

export interface IMapPinProps {
  lat: number;
  lng: number;
  showStoreDetails: boolean;
  location: IStoreLocation;
  text?: string;
  onSelectedStoreChange: () => void;
}

export const MapPin = (props: IMapPinProps) => {
  const {
    showStoreDetails,
    location,
    onSelectedStoreChange,
  } = props;

  const { t } = useTranslation(RETAILER_VIEW);
  const [isShowStoreDetails, setIsShowStoreDetails] = useState(showStoreDetails);


  const closeStoreDetails = () => {
    setIsShowStoreDetails(false);
  }

  const openStoreDetails = () => {
    setIsShowStoreDetails(true);
  }

  useEffect(() => {
  setIsShowStoreDetails(showStoreDetails);
  }, [showStoreDetails])
  

  return (
    <div className="map-pin-container">
      {isShowStoreDetails && (
        <div className="map-bubble-container">
          <div className="map-logo-close-btn-container">
            <img alt="retailer-logo" src={location.logo} style={{ maxWidth: "60px" }} />
            <div className="map-bubble-close-btn-container">
              <button
                className="map-close"
                aria-label={t("close-info")}
                data-cy="modal-close-x-contact-toc"
                onClick={()=> closeStoreDetails()}
              >
                <img
                  src={XButton}
                  alt={t("close-info")}
                  className="modal-close-x"
                />
              </button>
            </div>
          </div>

          <div className="map-bubble-content">
            <div className="map-bubble-retailer-title">{location.name}</div>
            <div className="map-bubble-address">{`${location.street}, ${location.city}, ${location.state} `}</div>
            {/* <div className="map-bubble-status">Open now</div> */}
            <div className="map-bubble-distance">{t("miles-away", { distance: location.distance.toFixed(2) })}</div>
          </div>
          <img
            src={nubbin}
            alt="nubbin"
           style={{bottom: "-24px", position: "relative", left: "100px"}}

          />
        </div>
      
      )}
       
      <Icon
        icon={ isShowStoreDetails ? "el:map-marker-alt" : "mdi:map-marker"}
        className="pin-icon"
        data-testid="locationPin"
        onClick={ isShowStoreDetails ? () => onSelectedStoreChange() : () => openStoreDetails()} />
    </div>
  );
};
