export const IN_PROGRESS = 'IN_PROGRESS';
export const DONE = 'DONE';
export const ERROR = 'ERROR';
export const EMPTY = 'EMPTY';

export const hasNeverLoaded = (status: string): boolean => (status === EMPTY);
export const isLoading = (status: string): boolean => (status === IN_PROGRESS);

// returns true if the data is "not loaded", meaning its either never been loaded, or is currently loading
export const isNotLoaded = (status: string): boolean => ([EMPTY, IN_PROGRESS].includes(status));
export const isFinished = (status: string): boolean => ([DONE, ERROR].includes(status));

export const didSucceed = (status: string): boolean => (status === DONE);
export const didFail = (status: string): boolean => (status === ERROR);

export default {
  IN_PROGRESS,
  DONE,
  ERROR,
  EMPTY,
};
