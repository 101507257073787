import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CalendarImage from "assets/about_calendar.png";
import MapImage from "assets/about_map.png";
import BullseyeImage from "assets/about_bullseye.png";
import RightArrow from "assets/icons/arrow_right.svg";
import LeftArrow from "assets/icons/arrow_left.svg";
import { useTranslation } from "react-i18next";
import {
  ABOUT_MODAL_NAMESPACE,
  COMMON_NAMESPACE,
} from "constants/i18n-namespaces";
import { TextButton } from "kubra-ux-forge";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function AboutModal(props: { closeCallBack: () => any }) {
  const [displayBackButton, setDisplayBackButton] = useState(false);
  const [displayNextButton, setDisplayNextButton] = useState(true);
  const [displayDoneButton, setDisplayDoneButton] = useState(false);
  const sliderRef = React.createRef();
  const { t } = useTranslation();

  const goToNext = () => {
    // @ts-ignore
    sliderRef.current.slickNext();
  };

  const goToPrev = () => {
    // @ts-ignore
    sliderRef.current.slickPrev();
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (_: number, newIndex: number) => {
      switch (newIndex) {
        case 0:
          setDisplayBackButton(false);
          setDisplayNextButton(true);
          setDisplayDoneButton(false);
          break;
        case 1:
          setDisplayBackButton(true);
          setDisplayNextButton(true);
          setDisplayDoneButton(false);
          break;
        case 2:
          setDisplayBackButton(true);
          setDisplayNextButton(false);
          setDisplayDoneButton(true);
          break;
        default:
          break;
      }
    },
    appendDots: (dots: unknown) => (
      <div style={{ margin: "0px 0px 0px -20px" }}>
        <ul style={{ margin: "-5px" }}>{dots}</ul>
      </div>
    ),
  };

  return (
    <div>
      <div className="carousel-base">
        {/* @ts-ignore */}
        <Slider {...sliderSettings} ref={sliderRef}>
          <div className="carousel-card">
            <img
              src={CalendarImage}
              alt={`${t("help-icon", { ns: COMMON_NAMESPACE })}`}
              className="carousel-image"
            />
            <div className="carousel-header">
              {t("schedule-payments-title", { ns: ABOUT_MODAL_NAMESPACE })}
            </div>
            <div className="carousel-text">
              {t("schedule-payments-description", {
                ns: ABOUT_MODAL_NAMESPACE,
              })}
            </div>
          </div>
          <div className="carousel-card">
            <img
              src={MapImage}
              alt={`${t("help-icon", { ns: COMMON_NAMESPACE })}`}
              className="carousel-image"
            />
            <div className="carousel-header">
              {t("pay-anytime-title", { ns: ABOUT_MODAL_NAMESPACE })}
            </div>
            <div className="carousel-text">
              {t("pay-anytime-description", { ns: ABOUT_MODAL_NAMESPACE })}
            </div>
          </div>
          <div className="carousel-card">
            <img
              src={BullseyeImage}
              alt={`${t("help-icon", { ns: COMMON_NAMESPACE })}`}
              className="carousel-image"
            />
            <div className="carousel-header">
              {t("one-time-payments-title", { ns: ABOUT_MODAL_NAMESPACE })}
            </div>
            <div className="carousel-text">
              {t("one-time-payments-description", {
                ns: ABOUT_MODAL_NAMESPACE,
              })}
            </div>
          </div>
        </Slider>
      </div>
      <div className="carousel-bottom-buttons">
        <div className="carousel-bottom-buttons-left">
          {displayBackButton && (
            <TextButton
              hoverStyle="underline"
              icon={
                <img
                  src={LeftArrow}
                  alt={`${t("back-button", { ns: ABOUT_MODAL_NAMESPACE })}`}
                  className="carousel-arrow-button-arrow"
                />
              }
              onClick={() => goToPrev()}
              data-cy="button-back"
              style={{ minWidth: "0px" }}
              className="carousel-arrow-button"
            >
              {t("back", { ns: COMMON_NAMESPACE })}
            </TextButton>
          )}
        </div>
        <div className="carousel-bottom-buttons-right">
          {displayNextButton && (
            <TextButton
              hoverStyle="underline"
              icon={
                <img
                  src={RightArrow}
                  alt={`${t("forward-button", { ns: ABOUT_MODAL_NAMESPACE })}`}
                  className="carousel-arrow-button-arrow"
                />
              }
              iconLocation="right"
              onClick={() => goToNext()}
              data-cy="button-next"
              style={{ minWidth: "0px" }}
              className="carousel-arrow-button"
            >
              {t("next", { ns: COMMON_NAMESPACE })}
            </TextButton>
          )}
          {displayDoneButton && (
            <TextButton
              className="carousel-done-button"
              onClick={props.closeCallBack}
              data-cy="button-done"
              style={{ minWidth: "0px" }}
            >
              {t("done", { ns: COMMON_NAMESPACE })}
            </TextButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default AboutModal;
