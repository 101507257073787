import React from "react";
import { HELP_MODAL_NAMESPACE } from "constants/i18n-namespaces";
import { Trans } from "react-i18next";

function WhatIsEzPay() {
  return (
    <div>
      <Trans
        i18nKey="what-is-ez-pay-description"
        ns={HELP_MODAL_NAMESPACE}
        components={{ newLine: <br /> }}
      />
    </div>
  );
}

export default WhatIsEzPay;
