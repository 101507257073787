/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export default {
  index: () => '/',
  payslip: {
    index: () => '/payslip',
  },
  balance: {
    index: () => '/balance',
  },
  retailerLocation: {
    index: () => '/retailerLocation',
  },
  paymentReceipt: {
    index: () => '/paymentReceipt',
  },
  payment: {
    index: () => '/payment',
    read: (paymentId: string) => `/payment/${paymentId}`
  },
  unsubscribeAlerts: {
    index: () => '/unsubscribe',
    read: (email: string) => `/unsubscribe/${email}`
  },
  recentPayments: {
    index: () => '/recentPayments'
  },
  directLanding: {
    index: () => "/landing"
  },
  csrDirectLanding: {
    index: () => '/csr-payment-intent',
    read: (paymentIntentId: string) => `/csr-payment-intent/${paymentIntentId}`
  },
  linkExpired: {
    index: () => "/linkExpired"
  },
  terms:{
    index: () => "/terms"
  },
  privacy:{
    index: () => "/privacy"
  },  
  notFound: {
    index: () => "/404"
  }
};
