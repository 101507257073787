export const BankAccount = 'BankAccount';
export const Card = 'Card';
export const CreditCard = 'CreditCard';
export const Debit = 'Debit';
export const ApplePay = 'ApplePay';
export const GooglePay = 'GooglePay';
export const PayPal = 'PayPal';
export const Venmo = 'Venmo';
export const Cash = 'Cash';
export const Wallet = 'Wallet';
export const PinlessDebit = 'PinlessDebit';
export const CreditCardAmex = 'CreditCardAmex';