import React from "react";
import { useTranslation } from "react-i18next";
import { PRIVACY_MODAL_NAMESPACE } from "constants/i18n-namespaces";

function Privacy() {
  const { t } = useTranslation();
  return (
    <div className="long-text-scroll">
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: t("content", {
              interpolation: { escapeValue: false },
              ns: PRIVACY_MODAL_NAMESPACE,
            }),
          }}
        />
      </div>
    </div>
  );
}

export default Privacy;
