import React from 'react';
import { Outlet, useOutletContext } from 'react-router';
import { Navigate } from 'react-router-dom';
import { ISession } from 'types/session';

export interface IPublicRouteProps {
    element: JSX.Element,
    navToPath?: string;
}

export const PublicRoute = (props: IPublicRouteProps) => {
    const { element, navToPath } = props;
    const session = useOutletContext<ISession>();
    return session?.account && navToPath ? <Navigate to={navToPath} replace /> : (element || <Outlet context={session} />);
};