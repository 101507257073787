import styled, { css } from "styled-components";
import { theme, switchProp } from "styled-tools";

interface HeaderItemGroupDivProps {
    variant: "left" | "center" | "right";
  }

export const StyledHeaderItemGroupDiv = styled.div<HeaderItemGroupDivProps>`
  display: flex;

  flex: 1;
  justify-content: ${switchProp("variant", {
    left: "flex-start",
    center: "center",
    right: "flex-end",
  })};

  flex-direction: row;
  align-items: center;

  padding: 0;
  gap: 2.4rem;
  height: 4rem;
`;