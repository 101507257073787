import React from "react";
import { useSelector } from "react-redux";
import { getAppLoading, getAppLoadingSubText, getAppLoadingTitle } from "selectors/appState";
import { LoadingModal } from "kubra-ux-forge";
import _ from "lodash";

export const PageLoader = () => {

    const loading = useSelector(getAppLoading);
    const titleText = useSelector(getAppLoadingTitle) ?? "";
    const subText = useSelector(getAppLoadingSubText) ?? "";

    return (
        <LoadingModal
            open={loading}
            variant="dark"
            title={titleText}
            maskColor="rgb(20,24,31,0.8)"
        >
        { (!_.isEmpty(titleText) && !_.isEmpty(subText)) &&  (<div className="sub-text">{subText}</div>)}
        </LoadingModal>
    )
}