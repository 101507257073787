import mastercard_logo from "assets/mastercard_logo.svg";
import visa_logo from "assets/visa_logo.svg";
import discover_logo from "assets/discover_logo.svg";
import amex_logo from "assets/amex_logo.svg";
import _ from "lodash";
import {
    BANK_ACCCOUNT_MODAL,
  } from "constants/i18n-namespaces";
  

export const BankAccount = 'BankAccount';
export const Card = 'Card';
export const ApplePay = 'ApplePay';
export const GooglePay = 'GooglePay';
export const PayPal = 'PayPal';
export const Venmo = 'Venmo';
export const Cash = 'Cash';
export const Wallet = 'Wallet';

export const translateBankAccountType = (
    accountType: string,
    t: (type: string, namespace: { ns: string }) => string
  ) => {
    switch (accountType.toUpperCase()) {
      case "KCC":
      case "CHECKING":
        return t("checking", { ns: BANK_ACCCOUNT_MODAL });
      case "KCS":
      case "SAVINGS":
        return t("savings", { ns: BANK_ACCCOUNT_MODAL });
      case "KBC":
      case "BUSINESSCHECKING":
        return t("business-checking", { ns: BANK_ACCCOUNT_MODAL });
      case "KBS":
      case "BUSINESSSAVINGS":
        return t("business-savings", { ns: BANK_ACCCOUNT_MODAL });
    }
  };

export const CardNetworkType = {
    Visa: "Visa",
    Mastercard: "Mastercard",
    Discover: "Discover",
    Amex: "Amex",
    PinlessDebit: "Debit",
    Diners: "Diners"
}

export const CardNetworkShortCode = {
    Visa: "VI",
    Mastercard: "MC",
    Discover: "DI",
    Amex: "AX",
    PinlessDebit: "DP",
    Diners: "DD"
}

export const getCardNetworkByShortCode = (shortCode: string) => {

    switch (shortCode) {
        case CardNetworkShortCode.Visa:
            return CardNetworkType.Visa;
        case CardNetworkShortCode.Mastercard:
            return CardNetworkType.Mastercard;
        case CardNetworkShortCode.Discover:
            return CardNetworkType.Discover;
        case CardNetworkShortCode.Diners:
            return CardNetworkType.Diners;
        case CardNetworkShortCode.Amex:
            return CardNetworkType.Amex;
        case CardNetworkShortCode.PinlessDebit:
            return CardNetworkType.PinlessDebit
        default:
            return shortCode;
    }
}

export const getCardNetworkLogo = (cardNetwork: string) => {
    //case insensitive
    switch (_.lowerCase(cardNetwork)) {
        case CardNetworkType.Amex:
            return amex_logo;
        case CardNetworkType.Discover:
            return discover_logo;
        case CardNetworkType.Mastercard:
            return mastercard_logo;
        case CardNetworkType.Visa:
            return visa_logo;
        default:
            return undefined;
    }
}

export const isPaymentMethodAllowed =
    (requestFundingOption: string, availableFundingOptions: string[]): boolean => availableFundingOptions.includes(requestFundingOption);

export default {
    BankAccount,
    Cash,
    Card,
    Wallet,
    ApplePay,
    GooglePay,
    PayPal,
    Venmo,
};
