import { Button } from 'kubra-ux-forge';
import React, { useEffect } from 'react';
import routing from "routing";
import { useNavigate } from "react-router-dom";
import linkExpiredImage from "assets/link_expired_image.svg";
import { useTranslation } from 'react-i18next';
import { LINK_EXPIRED_NAMESPACE } from 'constants/i18n-namespaces';
import { useBiller } from 'hooks';

export const LinkExpired = (): JSX.Element => {
  const { loadBiller } = useBiller();

  const navigate = useNavigate();
  const { t } = useTranslation(LINK_EXPIRED_NAMESPACE);

  useEffect(() => {
    loadBiller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="link-expired-frame">
        <div className="link-expired-inner-frame">
          <div className="link-expired-image" >
            <img
              data-testid="link-expired-image"
              src={linkExpiredImage}
              alt={t("link-expired-button")}
            ></img>
          </div>
          <div className="link-expired-text">
            <div className="link-expired-header" data-testid="link-expired-header">
              {t("link-expired-header")}
            </div>
            <div className="link-expired-text-body" data-testid="link-expired-body">
              {t("link-expired-body")}
            </div>

            <Button
              onClick={() => navigate(routing.index())}
              title="link-expired-button"
              dataCy="link-expired-button"
              data-testid="link-expired-button"
            >
              {t("link-expired-button")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkExpired;