import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { LoadingButton } from "kubra-ux-forge";

export interface SubmitButtonProps {
  loading: boolean;
  buttonText: string;
}

const SubmitButton = (props: SubmitButtonProps) => {
  const { loading } = props;

  return (
    <LoadingButton
      loadingState="text"
      onClick={() => console.log("continue")}
      className="validate-button"
      type="submit"
      disabled={loading}
    >
      {loading && (
        <div className="button-icon">
          {/* @ts-ignore */}
          <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
        </div>
      )}
      {!loading && <div>{props.buttonText}</div>}
    </LoadingButton>
  );
};

SubmitButton.displayName = "SubmitButton";

export default SubmitButton;
