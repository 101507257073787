import { Calendar } from "kubra-ux-forge";
import React, { useState } from "react";
import Modal from "react-modal";
import Sheet from "react-modal-sheet";
import XButton from "assets/icons/x_button.svg";
import {
  COMMON_NAMESPACE,
  PAYMENT_DATE_MODAL,
} from "constants/i18n-namespaces";
import { useTranslation } from "react-i18next";
import { ACTION } from "actions/paymentIntentActions";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import "moment-timezone";
import { IBiller } from "types/biller";

export interface IPaymentDateModalProps {
  isMobile: boolean;
  isOpen: boolean;
  dueDate: Date;
  daysAfter: number;
  onClose: () => void;
  biller: IBiller | undefined;
}

export const PaymentDateModal = (props: IPaymentDateModalProps) => {
  const { isMobile, isOpen, dueDate, daysAfter, onClose, biller } = props;

  const today = moment().startOf("day").toDate();
  const currentTime = moment();
  // do we select next day if past cut off?
  const cutOffTime = moment()
    .tz("America/Toronto")
    .set({ hour: 20, minute: 0, second: 0 });

  const [selectedDate, setSelectedDate] = useState(today);
  const { t } = useTranslation(PAYMENT_DATE_MODAL);
  const dispatch = useDispatch();
  const isAfterCutOff = currentTime.isSameOrAfter(cutOffTime);

  const updatePaymentDate = (newDate: Date) => {
    setSelectedDate(newDate);
  };

  const onClickDone = () => {
    dispatch({
      type: ACTION.ADD_PAYMENT_DATE,
      payload: selectedDate,
    });
    onClose();
  };

  // minDate is today
  const minDate = moment().startOf("day").toDate();
  let maxDate = moment().startOf("day").toDate();

  const maxDueDate = moment(dueDate).startOf("day").toDate();
  maxDueDate.setDate(maxDueDate.getDate() + daysAfter);

  // Due Date + 60days >= Today
  if (moment(maxDueDate).isSameOrAfter(minDate)) {
    // max Date will be Due Date + 60 days
    maxDate = maxDueDate;
  }

  if (isMobile) {
    return (
      <Sheet
        isOpen={isOpen}
        onClose={onClose}
        detent="content-height"
        disableDrag={true}
      >
        <Sheet.Container>
          <Sheet.Header>
            <div className="react-modal-sheet-header">
              <div className="help-modal-header-text">{t("select-date")}</div>
              <button
                className="modal-close"
                onClick={onClose}
                aria-label={`${t("close-modal", { ns: COMMON_NAMESPACE })}`}
              >
                <img
                  src={XButton}
                  alt={`${t("close-modal", { ns: COMMON_NAMESPACE })}`}
                  className="modal-close-x"
                />
              </button>
            </div>
          </Sheet.Header>
          <Sheet.Content>
            <Calendar
              dueDate={dueDate}
              minDate={minDate}
              maxDate={maxDate}
              showLegend
              dataCy="date-picker"
              onChange={updatePaymentDate}
              value={selectedDate}
            ></Calendar>
            {isAfterCutOff && (
              <div className="cut-off-time-info">
                <div className="cut-off-time-info">
                  <FontAwesomeIcon
                    icon={faInfo}
                    className="cut-off-time-info-icon"
                  />
                </div>
                {t("cut-off-time-message", { biller: biller === undefined ? "" : biller.billerName})}
              </div>
            )}
            <div className="payment-date-save-button-row">
              <button className="done-button" onClick={onClickDone}>
                {t("done", { ns: COMMON_NAMESPACE })}
              </button>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    );
  }

  return (
    /* @ts-ignore */
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      className="modal-frame-help"
      shouldCloseOnOverlayClick={false}
      onRequestClose={onClose}
    >
      <div className="modal-header">
        <div className="help-modal-header-text">{t("select-date")}</div>
        <button
          className="modal-close"
          onClick={onClose}
          aria-label={`${t("close-modal", { ns: COMMON_NAMESPACE })}`}
        >
          <img
            src={XButton}
            alt={`${t("close-modal", { ns: COMMON_NAMESPACE })}`}
            className="modal-close-x"
          />
        </button>
      </div>
      <div className="calendar-frame">
        <Calendar
          dueDate={dueDate}
          minDate={minDate}
          maxDate={maxDate}
          showLegend
          dataCy="date-picker"
          onChange={updatePaymentDate}
          value={selectedDate}
        ></Calendar>
        {isAfterCutOff && (
          <div className="cut-off-time-info">
            <div className="cut-off-time-info">
              <FontAwesomeIcon
                icon={faInfo}
                className="cut-off-time-info-icon"
              />
            </div>
            {t("cut-off-time-message", { biller: biller == undefined ? "" : biller.billerName })}
          </div>
        )}
      </div>
      <div className="payment-date-save-button-row">
        <button className="done-button" onClick={onClickDone}>
          {t("done", { ns: COMMON_NAMESPACE })}
        </button>
      </div>
    </Modal>
  );
};
