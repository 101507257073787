import React, { useEffect, useState } from "react";
import { IBiller, IImageResourcesProperties } from "types/biller";
import imageSrc from "assets/add_to_google_wallet_button.svg";
import { postGooglePass } from "api/IamApi/entities/rcp";

export interface IGoogleWalletButtonProps {
  biller: IBiller;
  barcodeText: string;
  accountNumber: string;
  realm: string
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const GoogleWalletButton = (props: IGoogleWalletButtonProps) => {
  const { biller, barcodeText, accountNumber, realm } = props;
  const [googlePassLink, setGooglePassLink] = useState("#");
  const imageResources = biller?.properties?.find(element => element.name === "ImageResources")?.properties as IImageResourcesProperties;

  useEffect(() => {
    const googlePassRequest = {
      billerName: biller.billerName,
      accountNumber: accountNumber,
      logoUrl: imageResources?.logoURL,
      barcode: barcodeText,
      modifiedBy: "ezPay",
    };
    const createGooglePassLink = () => {
      postGooglePass(biller.clientId, googlePassRequest, realm)
        .then((res) => {
          if (res.data.link.length !== 0) {
            setGooglePassLink(res.data.link);
          } else {
            console.log("Could not fetch gooogle pass.");
          }
        }).catch((error) => {
          console.log("Could not fetch gooogle pass.");
        })
    };

    // call the function
    createGooglePassLink()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountNumber,
    barcodeText,
    biller.billerName,
    imageResources?.logoURL,
    biller.clientId,
  ]);

  return (
    <div title="google button">
      {googlePassLink !== "#" && (
        <a href={googlePassLink}>
          <img
            className="add-to-google-wallet-button"
            src={imageSrc}
            alt="Add to Google Wallet"
          />
        </a>
      )}
    </div>
  );
};