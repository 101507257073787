import {
  Accordion,
  Button,
  InputField,
  Select,
  TextButton,
  Toggle,
} from "kubra-ux-forge";
import React, { ChangeEvent, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import BankHelpImage from "assets/bank-account-help.png";
import { MenuOption } from "kubra-ux-forge/dist/elements/MenuList/hooks/useMenuList";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ACTION } from "actions/paymentIntentActions";
import { useTranslation } from "react-i18next";
import {
  BANK_ACCCOUNT_MODAL,
  COMMON_NAMESPACE,
} from "constants/i18n-namespaces";
import { PLATFORM_NAMESPACE } from "kubra-labels";
import FundingOption from "constants/FundingOption";
import { TermsModal } from "components/ezpay/common/terms-modal";
import { isMobile } from "react-device-detect";
import { AnalyticsEventType, useTracking } from "kubra-ui-lib-mfe";
import type { AnalyticsEvent } from "kubra-ui-lib-mfe";
import { MethodComponentsOptions } from "../../balance/balance-frame";

export interface IBankAccount {
  bankAccountNumber: string;
  routingNumber: string;
  accountOwnerName: string;
  accountType: string;
  saveToWallet: boolean;
}
export const BankAccountInitialState: IBankAccount = {
  bankAccountNumber: "",
  routingNumber: "",
  accountOwnerName: "",
  accountType: "",
  saveToWallet: false,
};
export interface IBankAccountModalProps {
  flowCallback: (method: MethodComponentsOptions) => void;
  closeModalCallBack: () => void;
  // The Accordion component is throwing a strange expcetion
  // when running unit tests with jsdom. Adding a flag to
  // not render it in the tests while this issue is not fixed
  // in the kubra-ux-forge library
  showAccountHelpAccordion?: boolean;
}

export const BankAccountModal = (props: IBankAccountModalProps) => {
  const { t } = useTranslation(BANK_ACCCOUNT_MODAL);
  const {
    flowCallback,
    closeModalCallBack,
    showAccountHelpAccordion = true,
  } = props;
  
  const [termsModalDisplay, setTermsModalDisplay] = useState(false);
  const dispatch = useDispatch();
  const { trackEvent } = useTracking<Partial<AnalyticsEvent>>(); 

  const bankAccountTypes = useMemo(
    () => [
      {
        name: t("checking"),
        value: "Checking",
      },
      {
        name: t("savings"),
        value: "Savings",
      },
      {
        name: t("business-checking"),
        value: "BusinessChecking",
      },
      {
        name: t("business-savings"),
        value: "BusinessSavings",
      },
    ],
    [t]
  );

  const handleButtonCancel = () => {
    flowCallback("PaymentMethodModal");
  };

  const handleToggleChange = () => {
    setFieldValue("saveToWallet", !values.saveToWallet);
  };

  const handleRoutingNumberChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const regex = /^[0-9\b]+$/;
    if (
      event.currentTarget.value === "" ||
      regex.test(event.currentTarget.value)
    ) {
      await setFieldValue("routingNumber", event.currentTarget.value);
    }
  };

  const handleBankAccNumberChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const regex = /^[0-9\b]+$/;
    if (
      event.currentTarget.value === "" ||
      regex.test(event.currentTarget.value)
    ) {
      await setFieldValue("bankAccountNumber", event.currentTarget.value);
    }
  };

  const handleAccOwnerNameChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const regex = /^[a-zA-Z\s]+$/;
    if (event.currentTarget.value === "" || regex.test(event.currentTarget.value)) {
      await setFieldValue("accountOwnerName", event.currentTarget.value);
    }
  }

  const formik = useFormik({
    initialValues: { ...BankAccountInitialState },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: Yup.object({
      accountOwnerName: Yup.string()
        .required(t("account-owner-name-required"))
        .matches(/^[a-zA-Z\s]+$/, t("account-owner-name-required")),
      accountType: Yup.string()
        .required(t("account-type-required"))
        .oneOf(
          bankAccountTypes.map((accountType) => accountType.value),
          t("account-type-required")
        ),
      routingNumber: Yup.string()
        .required(t("routing-number-required"))
        .matches(/^[0-9\b]+$/, t("routing-number-required"))
        .max(9, t("routing-number-invalid"))
        .min(9, t("routing-number-invalid"))
        .test("routingNumber", t("routing-number-required"), function (value) {
          return +value > 0;
        }),
      bankAccountNumber: Yup.string()
        .required(t("bank-account-required"))
        .matches(/^[0-9\b]+$/, t("bank-account-required"))
        .max(17, t("bank-account-invalid"))
        .test(
          "bankAccountNumber",
          t("bank-account-required"),
          function (value) {
            return +value > 0;
          }
        ),
      // .min(9, "Bank account number invalid"),
      saveToWallet: Yup.bool(),
    }),
    onSubmit: (values: IBankAccount, actions: any) => {
      actions.setSubmitting(true);
      dispatch({ type: ACTION.ADD_CREDIT_DEBIT_CARD, payload: null });
      dispatch({ type: ACTION.ADD_WALLET, payload: null });
      dispatch({ type: ACTION.ADD_BANK_ACCOUNT, payload: values });
      dispatch({
        type: ACTION.ADD_PAYMENT_METHOD,
        payload: FundingOption.BankAccount,
      });
      actions.resetForm();
      setSubmitting(false);
      trackEvent({
        type: AnalyticsEventType.customEvent,
        event: "bank_created"
      });
      closeModalCallBack();
    },
  });
  const {
    values,
    touched,
    errors,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    handleBlur,
    handleSubmit,
  } = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <div className="bank-account-container">
        <div className="bank-account-subtitle">
          {t("bank-account-subtitle")}
        </div>

        {showAccountHelpAccordion && (
          <div className="bank-account-help-container">
            <Accordion
              iconPosition="right"
              header={`${t("bank-account-help-header")}`}
              content={
                <div className="bank-account-help-image">
                  <img style={{maxWidth: "100%"}}
                    src={BankHelpImage}
                    alt={`${t("bank-account-help-header")}`}
                  />
                </div>
              }
              className="bank-account-accordion"
              /* @ts-ignore */
              dataCy="accordion-bank-account-help"
            />
          </div>
        )}

        <div className="bank-account-inputs-container">
          <div className="bank-account-name-textbox-container">
            <InputField
              name="accountOwnerName"
              id="accountOwnerName"
              errorText={
                touched.accountOwnerName ? errors.accountOwnerName : undefined
              }
              helperText=""
              label={`${t("account-owner-name-label")}`}
              value={values.accountOwnerName}
              onChange={handleAccOwnerNameChange}
              onBlur={handleBlur}
              hasError={
                touched.accountOwnerName && errors.accountOwnerName != undefined
              }
              dataCy="input-account-owner-name"
            />
          </div>
        </div>
        <div className="bank-account-inputs-container">
          <div className="bank-account-name-textbox-container">
            <Select
              name="accountType"
              errorText={touched.accountType ? errors.accountType : undefined}
              helperText=""
              label={`${t("account-type-label")}`}
              onClick={(event : any) => {
                event.stopPropagation();
                event.preventDefault();                
              }}
              onChange={(option: MenuOption[]) => {
                setFieldValue("accountType", option[0].id);
              }}
              placeholder={`${t("select", { ns: COMMON_NAMESPACE })}`}
              options={bankAccountTypes.map(
                (accountType) =>
                  ({
                    id: accountType.value,
                    name: accountType.name,
                  } as MenuOption)
              )}
              hasError={touched.accountType && errors.accountType != undefined}
              dataCy="select-account-type"
            />
          </div>
        </div>
        <div className="bank-account-routing-container">
          <div className="bank-account-routing-textbox">
            <InputField
              name="routingNumber"
              errorText={
                touched.routingNumber ? errors.routingNumber : undefined
              }
              hasShowHideButton
              helperText=""
              label={`${t("routing-number-label")}`}
              placeholder=""
              maxLength="9"
              value={values.routingNumber}
              onChange={handleRoutingNumberChange}
              onBlur={handleBlur}
              hasError={
                touched.routingNumber && errors.routingNumber != undefined
              }
              dataCy="input-routing-number"
            />
          </div>
          <div className="bank-account-routing-textbox">
            <InputField
              name="bankAccountNumber"
              errorText={
                touched.bankAccountNumber ? errors.bankAccountNumber : undefined
              }
              hasShowHideButton
              helperText=""
              label={`${t("bank-account-label")}`}
              placeholder=""
              maxLength="17"
              value={values.bankAccountNumber}
              onChange={handleBankAccNumberChange}
              onBlur={handleBlur}
              hasError={
                touched.bankAccountNumber &&
                errors.bankAccountNumber != undefined
              }
              dataCy="input-bank-account-number"
            />
          </div>
        </div>
        <div className="save-bank-account-inputs-container">
          <div
            className={
              values.saveToWallet
                ? "save-bank-account-container-enabled"
                : "save-bank-account-container"
            }
          >
            <div className="save-bank-account-title-toggle-container">
              <span className="save-bank-account-title">
                {t("save-bank-account-toggle-label")}
              </span>
              <Toggle
                label=""
                onChange={handleToggleChange}
                isCheckedDefault={values.saveToWallet}
                /* @ts-ignore */
                dataCy="toggle-save-to-wallet"
              />
            </div>
            <div
              className={
                values.saveToWallet
                  ? "save-bank-account-line-separator-enabled"
                  : "save-bank-account-line-separator"
              }
            ></div>
            <div className="save-bank-account-text">
              {t("save-bank-account-credential", {
                lastFourDigits:
                  values.bankAccountNumber && !errors.bankAccountNumber
                    ? values.bankAccountNumber.substring(
                        values.bankAccountNumber.length - 4
                      )
                    : "_____",
              })}
              <button
                className="link-button"
                onClick={(e) =>{ e.preventDefault(); setTermsModalDisplay(true)} }
                data-cy="button-terms-link"
              >
                {t("terms-of-use")}
              </button>
              .
              {/* <Toggle
            label={`${t("save-bank-account-toggle-label")}`}
            labelPosition="hidden"
            onChange={handleToggleChange}
            isCheckedDefault={values.saveToWallet}
          />
          <div className="toggle-label">
            {t("save-bank-account-toggle-label")}
          </div> */}
            </div>
          </div>
          <div className="buttons-row">
            <div className="button-item">
              <TextButton
                type="button"
                onClick={handleButtonCancel}
                dataCy="button-cancel"
              >
                {t("cancelLabel", { ns: PLATFORM_NAMESPACE })}
              </TextButton>
            </div>
            <div className="button-item">
              <Button
                type="submit"
                disabled={isSubmitting}
                onClick={() => null}
                dataCy="button-done"
              >
                {t("done", { ns: COMMON_NAMESPACE })}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <TermsModal
        isOpen={termsModalDisplay}
        isMobile={isMobile}
        onClose={() => setTermsModalDisplay(false)}
        header="termsOfUse"
      />
    </form>
  );
};
