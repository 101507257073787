import iamApi from "../../api/IamApi";
import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { IAccount } from "types/account";
import { useTranslation } from "react-i18next";
import { updateSessionLanguage } from "api/IamApi/entities/ezpay";
import { useDispatch } from "react-redux";
import { USER_LOGOUT } from "../../actions/userActions";
import { getDomain } from "utilities/helper";
import { mapUiLanguageToSessionLanguage } from "utilities/helper";

export const SessionProvider = () => {
  const [account, setAccount] = useState<IAccount>();
  const [applePaySession, setApplePaySession] = useState(null);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const domain = getDomain();

  const login = (account: IAccount) => {
    setAccount(account);
  };

  const logout = () => {    
    iamApi.ezpay.logout();
    dispatch({ type: USER_LOGOUT });
    setAccount(undefined);
  };

  useEffect(() => {
    iamApi.ezpay
      .getAccountFromSession(domain)
      .then((response) => {
        login(response);
      })
      .catch((error) => {
        console.log(error)
        setAccount(undefined);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const uiLanguage = mapUiLanguageToSessionLanguage(i18n.language);

    if (account && uiLanguage !== account.language) {
      updateSessionLanguage(uiLanguage)
        .then(() =>
          setAccount({
            ...account,
            language: uiLanguage,
          })
        )
        .catch(() => {
          console.error(
            "Unexpected error hapenned while updating user's selected language in the session"
          );
        });
    }
  }, [account, i18n.language]);

  return (
    <Outlet
      context={{
        account, applePaySession, login, logout, setApplePaySession
      }}
    />
  );
};

export default SessionProvider;
