import { UNSUBSCRIBE_ALERTS } from 'constants/i18n-namespaces';
import { LoadingButton, Banner, BannerProps } from 'kubra-ux-forge';
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { deletePaymentDueAlerts } from "api/IamApi/entities/ezpay";
import unsubscribeImage from "assets/unsubscribe.png";
import unsubscribeSuccessImage from "assets/unsubscribe-success.png";
import routing from "routing";
import { matchPath, useLocation } from "react-router-dom";
import { useBiller } from "hooks";
import _ from "lodash";
import { getDomain } from 'utilities/helper';

export const UnsubscribeAlerts = () => {
  const { loadBiller, getBiller } = useBiller();
  const biller = getBiller();
  const [loading, setLoading] = useState("text");
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [email, setEmail] = useState("");
  const location = useLocation();

  const [bannerState, setBannerState] = useState<BannerProps>({
    active: false,
    variant: "info",
    messageLabel: "",
  });
  const domain = getDomain();

  const { t } = useTranslation(UNSUBSCRIBE_ALERTS);

  console.log("unsubscribed = ", unsubscribed)

  const handleUnsubscribe = () => {
    //TODO - handle unsubscribe
    console.log('unsubscribing from email alerts!');
    setLoading("loading");
    const payload = {
      domain: domain,
      email: email
    };
    if (!biller || !domain || !email) {
      setLoading('text');
      setBannerState({
        active: true,
        variant: "warning",
        messageLabel: t("unsubscribe-error"),
        withClose: true,
        withIcon: true,
        withHyperlink: false
      });
      return;
    }

    deletePaymentDueAlerts(payload, biller.clientId, biller.realm).then((res) => {
      console.log('received response' + res);
      setUnsubscribed(true);
      setLoading('text');
    }).catch((err) => {
      console.log('failed to unsubscribe ' + err);
      setLoading('text');
      setBannerState({
        active: true,
        variant: "warning",
        messageLabel: t("unsubscribe-error"),
        withClose: true,
        withIcon: true,
        withHyperlink: false
      });
    });

  }

  const getEmail = () => {
    const match = matchPath(
      routing.unsubscribeAlerts.read(":email"),
      location.pathname
    );
    return _.get(match, ["params", "email"]);
  };

  useEffect(() => {
    loadBiller();

    const emailAddress = getEmail();
    setEmail(emailAddress ? emailAddress : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="ez-pay-balance-banner" data-testid="error-banner">
        <Banner
          {...bannerState}
          onClose={() => setBannerState((prev) => ({ ...prev, active: false }))}
        ></Banner>
      </div>
      <div className="unsubscribe-frame">
        {!unsubscribed &&
          <div className="unsubscribe-inner-frame">
            <div className="unsubscribe-image" data-testid="unsubscribe-image">
              <img
                src={unsubscribeImage}
                alt={t("unsubscribe-button")}
              ></img>
            </div>
            <div className="unsubscribe-text">
              <div className="center-text-32" data-testid="unsubscribe-header">
                {t("unsubscribe-header")}
              </div>
              <div className="unsubscribe-text-body">
                <div className="center-text-16" data-testid="unsubscribe-body">
                  {t("unsubscribe-body") + email}
                </div>
              </div>

            </div>

            <div className="unsubscribe-button" >
              <LoadingButton
                loadingState={loading}
                onClick={handleUnsubscribe}
                title="unsubscribe-button"
                dataCy="unsubscribe-button"
              >
                {t("unsubscribe-button")}
              </LoadingButton>
            </div>
          </div>
        }
        {unsubscribed &&
          <div className="unsubscribe-inner-frame">
            <div className="unsubscribe-image" data-testid="unsubscribe-success-image">
              <img
                src={unsubscribeSuccessImage}
                alt={t("unsubscribe-button")}
              ></img>
            </div>
            <div className="unsubscribe-text">
              <div className="center-text-32" data-testid="unsubscribe-success-header">
                {t("unsubscribe-success-header")}
              </div>
              <div className="unsubscribe-text-body">
                <div className="center-text-16" data-testid="unsubscribe-success-body">
                  {t("unsubscribe-success-body")}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>

  );
};

export default UnsubscribeAlerts;