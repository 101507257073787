const basePath = "";
const productName = "EZ-PAY";
const clientName = "ABCEnergy";
const instanceId = "testInstance";
const billerLogo = "../../../../../src/assets/ADOXSDIRECT_logo.png";
const billerUrl = 'https://kubra.com';
const billImage = "test";
const streetAddress = '123 Street Avenue';
const accountNumber = '123';
const currentBalance = 125.25;
const dueDate = '8/14/22';
const contactPhone = '+1 (888) 888-8888';
const contactEmail = 'help@abcenergy.com';
const contactAddress = '1111 W Energy Rd. ABC, AZ 11111';

const validationFields = [
    {
        name: "accountNumber",
        label: "Account Number",
        validation: ""
    },
    {
        name: "zipCode",
        label: "Zip Code",
        validation: ""
    }
];

const tenantId = 'a8c2b204-1563-4a9a-9581-72f0cf71e5ef';

//honeycomb / open telemetry
const OTEL_EXPORTER_OTLP_ENDPOINT="https://api.honeycomb.io";
const OTEL_EXPORTER_OTLP_HEADERS="x-honeycomb-team=[TODO API KEY]";
const OTEL_SERVICE_NAME="ez-pay-ui";


export default {
    basePath,
    productName,
    clientName,
    tenantId,
    instanceId,
    billerLogo,
    billerUrl,
    billImage,
    validationFields,
    streetAddress,
    accountNumber,
    currentBalance,
    dueDate,
    contactPhone,
    contactEmail,
    contactAddress,
    OTEL_EXPORTER_OTLP_ENDPOINT,
    OTEL_EXPORTER_OTLP_HEADERS,
    OTEL_SERVICE_NAME
};
