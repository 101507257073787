import React from "react";
import { BillerLogo } from "components/ezpay/common/biller-logo";

export function ValidationHeader(props: { logoUrl: string; billerUrl: string }) {
  return (
    <div className="validate-header" title="validate header">
      <BillerLogo billerLogo={props.logoUrl} billerUrl={props.billerUrl} />
    </div>
  );
}
