// eslint-disable-next-line import/prefer-default-export
export const theme = {
  colorBrandPrimary: '#007FAD',
  colorBrandSecondary: '#36434E',
  colorBrandtertiaryHighlight: '#DAEEF6',
  colorBarHighlight: '#B6E1F2',
  colorBlack: '#242424',
  colorWhite: '#fff',
  colorWhiteSemiTransparent: '#ffffff80',
  colorGray1: '#F6F6F6',
  colorGray2: '#EAECEE',
  colorGray3: '#CBD0D4',
  colorGray4: '#8697A7',
  colorGray5: '#536372',
  colorGray6: '#7D7F80',
  colorPositive: '#018748',
  colorWarning: '#EE7017',
  colorError: '#BE281E',
  colorRisk: '#BE281E',
  colorLowGradient: '#FFC627',
  colorLowPill: '#E86A43',
  colorGood: '#01A75A',
  colorGoodPill: 'linear-gradient(153.74deg, #01A75A 16.52%, #0093C9 83.48%)',
  colorTemperature: '#BE53F9',
  colorCostComparisonDailyBalance: 'rgba(1,135,72,0.3)',
  colorPopupoverlay: 'rgba(83, 99, 114, 0.7)',
  colorYellow: '#BF8808',
  gradientOption: 'linear-gradient(134.76deg, #01A75A 14.62%, #0093C9 85.38%)',
  fontFamily: 'Roboto',
  fontFamilyBold: 'Roboto',
  GradientOpacityStart: 16.52,
  GradientOpacityEnd: 83.48,
  gradientColorStartRed: 0,
  gradientColorStartGreen: 147,
  gradientColorStartBlue: 201,
  gradientColorEndRed: 1,
  gradientColorEndGreen: 167,
  gradientColorEndBlue: 90,
  disconnectProtectBackground: '#DAEEF6',
  disconnectProtectBorder: '2px solid #007FAD',

  smallText: {
    fontFamily: 'Roboto',
    fontSize: '1.4rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.8rem',
    letterSpacing: '0px',
    textAlign: 'center',
  },

  tinyText: {
    fontFamily: 'Roboto',
    fontSize: '1.2rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.8rem',
    letterSpacing: '0px',
  },

  // kubra logo
  kubraBlue: '#0093C9',
  kubraGreen: '#82BC00',

  textExtraLargeBold: {
    composes: ['strong'],
    fontSize: '5rem',
    lineHeight: '5.8rem',
  },
  textLargeBold: {
    composes: ['strong'],
    fontSize: '3.2rem',
    lineHeight: '3.5rem',
  },
  colorPrePaySecondBar: '#F6B78B',
  dropShadow: 'rgba(36,36,36,0.16)',
  toolTipArrowInGraph: {
    width: 0,
    height: 0,
    position: 'absolute',
    borderStyle: 'solid',
    borderWidth: '.8rem .8rem 0 .8rem',
    bottom: '-.6rem',
  },
  legendContainer: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  legendPrimary: {
    width: 16,
    height: 16,
    borderRadius: '50%',
  },
  legendColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
export type ITheme = typeof theme;
