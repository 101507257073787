import routes from "../routing";
import { IBillerConfig } from "types/billerConfigResponse";
import { IBalanceResponse } from "types/balanceResponse";
import { ITenantSnapshot } from "types/tenantSnapshot";
import { IPaymentSettings } from "types/paymentSettings";
import { IAccount } from "types/account";
import {
  sendDeleteRequest,
  sendDeleteRequestWithPayload,
  sendGetRequest,
  sendPatchRequest,
  sendPostRequest,
  sendPutRequest,
} from "../lib";
import { AxiosResponse } from "axios";
import { SessionSupportedLanguages } from "types/session";
import { IPaymentIntentResponse } from "types/Api/Payment/paymentIntentReponse";

export const getConfiguration = (
  subDomain: string
): Promise<AxiosResponse<IBillerConfig>> =>
  sendGetRequest(routes.ezPay.getConfiguration(subDomain));

export const getBalance = (
  accountNumber: string,
  instanceId: string
): Promise<AxiosResponse<IBalanceResponse>> =>
  sendGetRequest(routes.ezPay.getBalance(accountNumber, instanceId));

export const postValidation = (
  instanceId: string,
  language: string,
  domain: string,
  fingerprint: string,
  userAgent: string,
  validationRequest: unknown
): Promise<AxiosResponse> => {
  return sendPostRequest(
    validationRequest,
    routes.ezPay.postValidation(instanceId),
    {
      headers: {
        channel: "Web",
        language: language,
        domain: domain,
        deviceFingerprint: fingerprint,
        userAgent: userAgent,
      },
      withCredentials: true,
    }
  );
};

export const postBalance = (
  fingerprint: string,
  userAgent: string
): Promise<AxiosResponse> => {
  return sendPostRequest(
    null,
    routes.ezPay.postBalance(),
    {
      headers: {
        deviceFingerprint: fingerprint,
        userAgent: userAgent,
      },
      withCredentials: true,
    }
  );
};

export const postAccountMessages = (
): Promise<AxiosResponse> => {
  return sendPostRequest(
    null,
    routes.ezPay.postAccountMessages(),
    {
      withCredentials: true,
    }
  );
};

export const postEmail = (
  instanceId: string,
  clientId: string,
  email: string
): Promise<AxiosResponse> => {
  return sendPostRequest({}, routes.ezPay.postEmail(email), {
    headers: { instanceId: instanceId, "Kubra-ClientId": clientId },
    withCredentials: true,
  });
};

export const postSms = (
  instanceId: string,
  clientId: string,
  phoneno: string
): Promise<AxiosResponse> => {
  return sendPostRequest({}, routes.ezPay.postSms(phoneno), {
    headers: { instanceId: instanceId, "Kubra-ClientId": clientId },
    withCredentials: true,
  });
};

export const getBarcode = (
  clientId: string,
  realm: string
): Promise<AxiosResponse<string>> =>
  sendGetRequest(routes.ezPay.getBarcode(), {
    headers: { "Kubra-ClientId": clientId, "Kubra-Realm": realm },
    withCredentials: true,
  });

export const postInitiateIntent = (
  clientId: string,
  realm: string,
  fingerprint: string,
  userAgent: string
): Promise<AxiosResponse> => {
  return sendPostRequest({}, routes.ezPay.postInitiateIntent(), {
    headers: {
      "Kubra-ClientId": clientId,
      "Kubra-Realm": realm,
      deviceFingerPrint: fingerprint,
      userAgent: userAgent,
    },
    withCredentials: true,
  });
};

export const postPrepareIntent = (
  clientId: string,
  paymentIntentId: string,
  prepareRequest: unknown
): Promise<AxiosResponse> => {
  return sendPostRequest(
    prepareRequest,
    routes.ezPay.postPrepareIntent(paymentIntentId),
    { headers: { "Kubra-ClientId": clientId }, withCredentials: true }
  );
};

export const postProcessIntent = (
  clientId: string,
  paymentIntentId: string,
  processRequest: unknown,
  language: string
): Promise<AxiosResponse> => {
  return sendPostRequest(
    processRequest,
    routes.ezPay.postProcessIntent(paymentIntentId),
    {
      headers: { "Kubra-ClientId": clientId, language: language },
      withCredentials: true,
    }
  );
};

export const cancelPaymentIntent = (
  clientId: string,
  paymentIntentId: string,
  cancelRequest: unknown
): Promise<AxiosResponse> => {
  return sendPatchRequest(
    cancelRequest,
    routes.ezPay.patchCancelIntent(paymentIntentId),
    { headers: { "Kubra-ClientId": clientId }, withCredentials: true }
  );
};

export const getPaymentIntent = (
  clientId: string,
  paymentIntentId: string
): Promise<IPaymentIntentResponse> => {
  return sendGetRequest(routes.ezPay.getPaymentIntent(paymentIntentId), {
    headers: { "Kubra-ClientId": clientId },
  });
};

export const getPayment = (
  deviceFingerprint: string,
  userAgent: string,
  domain: string,
  instanceId: string,
  paymentId: string,
  language: string,
  clientId: string,
  paymentRequest: any
): Promise<AxiosResponse> => {
  return sendPostRequest(
    paymentRequest,
    routes.ezPay.getPayment(paymentId, instanceId),
    {
      withCredentials: true,
      headers: {
        "Kubra-ClientId": clientId,
        userAgent: userAgent,
        domain: domain,
        deviceFingerPrint: deviceFingerprint,
        language: language,
      },
    },
  );
};

export const getTenantSnapshot = (
  tenantId: string
): Promise<AxiosResponse<ITenantSnapshot>> =>
  sendGetRequest(routes.ezPay.getTenantSnapshot(tenantId));

export const getPaymentSettings = (
  snapshotId: string
): Promise<AxiosResponse<IPaymentSettings>> =>
  sendGetRequest(routes.ezPay.getPaymentSettings(snapshotId));

export const getAccountFromSession = (domain: string): Promise<IAccount> =>
  sendGetRequest(routes.ezPay.getAccountFromSession(), {
    withCredentials: true,
    headers: {
      domain: domain,
    },
  });

export const checkSession = (): Promise<IAccount> =>
  sendGetRequest(routes.ezPay.checkSession(), { withCredentials: true });

export const logout = (): Promise<AxiosResponse> =>
  sendGetRequest(routes.ezPay.logout(), { withCredentials: true });

export const updateSessionLanguage = (
  language: SessionSupportedLanguages
): Promise<AxiosResponse> =>
  sendPutRequest({}, routes.ezPay.updateSessionLanguage(language), {
    withCredentials: true,
  });

export const validateApplePayMerchant = (
  valiationRequest: unknown
): Promise<AxiosResponse> =>
  sendPostRequest(valiationRequest, routes.ezPay.validateApplePayMerchant());

export const getWalletItems = (clientId: string): Promise<AxiosResponse> =>
  sendGetRequest(routes.ezPay.getWalletItems(), {
    headers: { "Kubra-ClientId": clientId },
    withCredentials: true,
  });

export const deleteWalletItem = (
  clientId: string,
  fundingSourceId: string
): Promise<AxiosResponse> =>
  sendDeleteRequest(routes.ezPay.deleteWalletItem(fundingSourceId), {
    headers: { "Kubra-ClientId": clientId },
    withCredentials: true,
  });

export const getAccountByPaymentIntentId = (
  instanceId: string,
  paymentIntentId: string,
  language: string,
  domain: string
): Promise<IAccount> => {
  return sendGetRequest(
    routes.ezPay.getAccountByPaymentIntentId(instanceId, paymentIntentId),
    {
      headers: { channel: "Web", language: language, domain: domain },
      withCredentials: true,
    }
  );
};

export const deletePaymentDueAlerts = (
  unenrollRequest: unknown,
  clientId: string,
  realm: string
): Promise<AxiosResponse> => {
  return sendDeleteRequestWithPayload(routes.ezPay.deletePaymentDueAlerts(), {
    unenrollRequest,
    headers: { "Kubra-ClientId": clientId, "Kubra-Realm": realm },
  });
};

export const getRecentPayments = (
  count: number,
  clientId: string,
  realm: string
): Promise<unknown> => {
  return sendGetRequest(routes.ezPay.getRecentPayments(count), {
    headers: { "Kubra-ClientId": clientId, "Kubra-Realm": realm },
    withCredentials: true,
  });
};

export const getUpcomingPayment = (
  clientId: string,
  realm: string
): Promise<unknown> => {
  return sendGetRequest(routes.ezPay.getUpcomingPayment(), {
    headers: { "Kubra-ClientId": clientId, "Kubra-Realm": realm },
    withCredentials: true,
  });
};

export const cancelUpcomingPayment = (
  paymentId: string,
  paymentIntentId: string,
  clientId: string,
  realm: string
): Promise<unknown> => {
  return sendPostRequest({ paymentIntentId: paymentIntentId }, routes.ezPay.postCancelPayment(paymentId), {
    headers: { "Kubra-ClientId": clientId, "Kubra-Realm": realm },
    withCredentials: true,
  });
};

export const postDirectLanding = (
  request: unknown,
  instanceId: string,
  language: string,
  domain: string,
  fingerprint: string,
  userAgent: string,
  channel?: string
): Promise<any> => {
  channel = channel ? channel : "Web";
  return sendPostRequest(request, routes.ezPay.postDirectLanding(instanceId), {
    headers: {
      channel: channel,
      language: language,
      domain: domain,
      deviceFingerprint: fingerprint,
      userAgent: userAgent,
    },
    withCredentials: true,
  });
};

export const getCsrDirectLanding = (
  instanceId: string,
  paymentIntentId: string,
  domain: string
): Promise<IAccount> => {
  return sendGetRequest(
    routes.ezPay.getCsrDirectLanding(instanceId, paymentIntentId),
    {
      withCredentials: true,
      headers: {
        domain: domain,
      },
    },
  );
};

export const getExtendedSession = (): Promise<any> => {
  return sendGetRequest(routes.ezPay.getExtendedSession(), {
    withCredentials: true,
  });
};

export default {
  getConfiguration,
  postValidation,
  postBalance,
  postAccountMessages,
  getBalance,
  getPaymentSettings,
  getTenantSnapshot,
  checkSession,
  updateSessionLanguage,
  getAccountFromSession,
  logout,
  getBarcode,
  postInitiateIntent,
  postPrepareIntent,
  postProcessIntent,
  cancelPaymentIntent,
  getPaymentIntent,
  getPayment,
  validateApplePayMerchant,
  getWalletItems,
  deleteWalletItem,
  getAccountByPaymentIntentId,
  getUpcomingPayment,
  getRecentPayments,
  cancelUpcomingPayment,
  postDirectLanding,
  getCsrDirectLanding,
  getExtendedSession
};
